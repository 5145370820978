import { THEME } from '../../../constants';

const iconSize = 16;

export default {
  root: {
    position: 'relative',
    '&:hover': {
      '& $removeBtn': {
        display: 'block',
      },
    },
  },
  wrapper: {
    height: '100%',
    transition: '.2s ease',
    '&:hover': {
      border: `1px solid ${THEME.colors.primary}`,
      '& $removeBtn': {
        display: 'block',
      },
    },
  },
  removeBtn: {
    display: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    // Make it float above other element.
    zIndex: 2,
    fontSize: iconSize,
    width: iconSize * 2,
    height: iconSize * 2,
    transform: `translate(${iconSize}px, -${iconSize}px)`,

    cursor: 'pointer',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FEFEFE',
    boxShadow: '0px 4px 4px rgba(14, 152, 213, 0.25)',
    '& [class^=icon-]': { color: THEME.colors.primary },
    '&.reverse': {
      border: '1px solid #FEFEFE',
      backgroundColor: THEME.colors.primary,
      '& [class^=icon-]': { color: '#FEFEFE' },
    },
  },
};
