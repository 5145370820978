import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { FormattedMessage } from 'react-intl';

import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/images/logo-white.svg';
import appStoreImage from '../../assets/images/app-store.svg';
import googlePlayImage from '../../assets/images/google-play.svg';
import * as configs from '../../configs';
import { scrollToElement, scrollToPosition } from '../../utils';
import Translate from '../../components/Translate';
import history from '../../history';

import styles from './footer.styles';
import {
  createSubscriber,
  subscriberCreatedSelector,
  subscriberCreatingSelector,
} from './footer.duck';

const leftItems = [
  {
    label: 'APP.FOOTER.WHAT_LETSPARKIT',
    href: '#home',
    id: 'introduce',
    toId: 'introduce',
  },
  {
    label: 'APP.FOOTER.BENEFITS',
    href: '#benefits',
    id: 'benefits',
    toId: 'benefits',
  },
  { label: 'APP.FOOTER.ARE_YOU_READY', href: '#ready', id: 'map', toId: 'map' },
];

const rightItems = [
  { label: 'APP.FOOTER.NEWS', href: '#news', id: 'news' },
  { label: 'APP.FOOTER.QA', href: '/faq', id: 'faq' },
  { label: 'APP.FOOTER.TUTORIAL_AND_SUPPORT', href: '/support', id: 'support' },
];

const Footer = ({ classes }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const isLoading = useSelector(subscriberCreatingSelector);
  const isSubmitted = useSelector(subscriberCreatedSelector);

  useEffect(() => {
    if (isSubmitted) {
      setEmail('');
    }
  }, [isSubmitted]);

  const onLeftItemClick = item => {
    let delay = 0;
    if (window.location.pathname !== '/') {
      history.push('/');
      delay = 300;
    }

    if (item.toId) {
      setTimeout(() => scrollToElement(item.toId), delay);
    }
  };

  const onRightItemClick = item => {
    if (item.href) {
      scrollToPosition(0);
      history.push(item.href);
    }
  };

  const renderItem = (key, item, onClick) => (
    <span
      key={key}
      role="presentation"
      onClick={() => onClick(item)}
      className={classes.item}
    >
      <Translate id={item.label} />
    </span>
  );

  const handleEmailChange = ({ target: { value } }) => {
    setEmail(value);
  };

  const renderItems = (list, onClick) =>
    list.map(i => renderItem(i.id, i, onClick));

  const handleSubscribe = () => dispatch(createSubscriber(email));

  return (
    <div id="footer" className={classes.root}>
      <div className={classes.content}>
        <div className={classes.leftFooter}>
          <span
            role="presentation"
            className={classes.brand}
            onClick={() => window.scroll(0, 0)}
          >
            <img src={logo} alt="Logo" />
          </span>
          <div className={classes.listItems}>
            <div className={classes.leftItems}>
              {renderItems(leftItems, onLeftItemClick)}
            </div>
            <div className={classes.rightItems}>
              {renderItems(rightItems, onRightItemClick)}
            </div>
          </div>
        </div>

        <div className={classes.rightFooter}>
          <div className={classes.subscribeBox}>
            <FormattedMessage id="APP.FOOTER.FORM.PLACEHOLDER">
              {msg => (
                <input
                  data-private
                  name="email"
                  type="email"
                  className={classes.input}
                  placeholder={msg}
                  value={email}
                  onChange={handleEmailChange}
                  disabled={isLoading}
                />
              )}
            </FormattedMessage>
            <span
              role="presentation"
              className={isLoading ? 'icon-loading rotating' : 'icon-mail'}
              onClick={handleSubscribe}
            />
          </div>
          <div className={classes.socialsAndStores}>
            <div className={classes.socials}>
              <a
                href={configs.LETS_PARK_IT_FACEBOOK_URL}
                className={classes.socialIcon}
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="icon-facebook" />
              </a>
              <a
                href={configs.LETS_PARK_IT_YOUTUBE_URL}
                className={classes.socialIcon}
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="icon-youtube" />
              </a>
            </div>
            <div className={classes.stores}>
              <a
                href={configs.APP_STORE_URL}
                className={classes.storeImage}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={appStoreImage} alt="App store" />
              </a>
              <a
                href={configs.GOOGLE_PLAY_URL}
                className={classes.storeImage}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={googlePlayImage} alt="Google play" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.copyright}>&copy; 2019 letspark.it</div>
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default injectSheet(styles)(Footer);
