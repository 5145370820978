const iconSize = 15;
const iconMarginRight = 8;
export default {
  itemIcon: {
    fontSize: iconSize,
    marginRight: iconMarginRight,
    verticalAlign: 'middle',
  },
  item: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  noIcon: {
    marginLeft: iconSize + iconMarginRight,
  },
};
