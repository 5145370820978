import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import {
  LETS_PARK_IT_FAQ_CATEGORY_SLUG,
  MAX_NUM_RECENT_VIEWED_ARTICLES,
} from '../../configs';

import {
  getCategoriesGroupedById,
  getCategoriesGroupedBySlug,
  getCategoryBySlug,
} from '../../ducks/categories.duck';
import {
  createTranslationSelector,
  createParameterSelector,
} from '../../utils';

const initialState = {
  isFaqInitializing: true,
  isFaqInitializingError: null,
  rootSlug: LETS_PARK_IT_FAQ_CATEGORY_SLUG,
  menuItems: [],

  articleSlugs: [],
  areArticlesLoading: false,
  areArticlesLoadingError: null,

  areArticlesAsMenuItems: false,
  areMenuItemsChanging: false,
  menuItemsChangeError: null,

  articleDetails: '',

  recentViewedArticleSlugs: [],
  areRecentViewedArticlesLoaded: false,
  areRecentViewedArticlesLoading: false,
  areRecentViewedArticlesLoadingError: null,

  articlesDict: {},
};

const PREFIX = 'FAQ';

export const FAQ_DATA_INITIALIZE = `${PREFIX}/DATA/INITIALIZE`;
export const FAQ_DATA_INITIALIZE_SUCCESS = `${FAQ_DATA_INITIALIZE}/SUCCESS`;
export const FAQ_DATA_INITIALIZE_FAILED = `${FAQ_DATA_INITIALIZE}/FAILED`;
export const FAQ_RESET = `${PREFIX}/RESET`;

export const ARTICLES_FETCH = `${PREFIX}/ARTICLES/FETCH`;
export const ARTICLES_FETCH_SUCCESS = `${ARTICLES_FETCH}/SUCCESS`;
export const ARTICLES_FETCH_FAILED = `${ARTICLES_FETCH}/FAILED`;

export const MENU_ITEMS_CHANGE = `${PREFIX}/MENU_ITEMS/CHANGE`;
export const MENU_ITEMS_CHANGE_SUCCESS = `${MENU_ITEMS_CHANGE}/SUCCESS`;
export const MENU_ITEMS_CHANGE_FAILED = `${MENU_ITEMS_CHANGE}/FAILED`;

export const ARTICLE_DETAILS_SET = `${PREFIX}/ARTICLE_DETAILS/SET`;

// eslint-disable-next-line max-len
export const ARTICLES_RECENT_VIEWED_FETCH = `${PREFIX}/ARTICLES/RECENT_VIEWED/FETCH`;
// eslint-disable-next-line max-len
export const ARTICLES_RECENT_VIEWED_FETCH_SUCCESS = `${ARTICLES_RECENT_VIEWED_FETCH}/SUCCESS`;
// eslint-disable-next-line max-len
export const ARTICLES_RECENT_VIEWED_FETCH_FAILED = `${ARTICLES_RECENT_VIEWED_FETCH}/FAILED`;
// eslint-disable-next-line max-len
export const ARTICLES_RECENT_VIEWED_CHANGE = `${PREFIX}/ARTICLES/RECENT_VIEWED/CHANGE`;
// eslint-disable-next-line max-len
export const ARTICLES_RECENT_VIEWED_CHANGE_SUCCESS = `${ARTICLES_RECENT_VIEWED_CHANGE}/SUCCESS`;
// eslint-disable-next-line max-len
export const ARTICLES_RECENT_VIEWED_CHANGE_FAILED = `${ARTICLES_RECENT_VIEWED_CHANGE}/FAILED`;

export const fetchArticles = createAction(ARTICLES_FETCH);
export const fetchArticlesSuccess = createAction(ARTICLES_FETCH_SUCCESS);
export const fetchArticlesFailed = createAction(ARTICLES_FETCH_FAILED);

export const initializeFaqData = createAction(FAQ_DATA_INITIALIZE);
export const initializeFaqDataSuccess = createAction(
  FAQ_DATA_INITIALIZE_SUCCESS,
);
export const initializeFaqDataFailed = createAction(FAQ_DATA_INITIALIZE_FAILED);

export const changeMenuItems = createAction(MENU_ITEMS_CHANGE);
export const changeMenuItemsSuccess = createAction(MENU_ITEMS_CHANGE_SUCCESS);
export const changeMenuItemsFailed = createAction(MENU_ITEMS_CHANGE_FAILED);
export const setArticleDetails = createAction(ARTICLE_DETAILS_SET);
export const resetFaq = createAction(FAQ_RESET);

export const fetchRecentViewedArticles = createAction(
  ARTICLES_RECENT_VIEWED_FETCH,
);
export const fetchRecentViewedArticlesSuccess = createAction(
  ARTICLES_RECENT_VIEWED_FETCH_SUCCESS,
);
export const fetchRecentViewedArticlesFailed = createAction(
  ARTICLES_RECENT_VIEWED_FETCH_FAILED,
);
export const changeRecentViewedArticles = createAction(
  ARTICLES_RECENT_VIEWED_CHANGE,
);
export const changeRecentViewedArticlesSuccess = createAction(
  ARTICLES_RECENT_VIEWED_CHANGE_SUCCESS,
);
export const changeRecentViewedArticlesFailed = createAction(
  ARTICLES_RECENT_VIEWED_CHANGE_FAILED,
);

export const faqStateSelector = state => state.faq;
export const getRootSlug = createSelector(
  faqStateSelector,
  state => state.rootSlug,
);
export const menuItemsSelector = createSelector(
  faqStateSelector,
  state => state.menuItems,
);
export const translatedMenuItemsSelector = createSelector(
  // createTranslationSelector(faqStateSelector, state => state.menuItems),
  createTranslationSelector(menuItemsSelector, items => items),
  items => items.map(item => ({ ...item, name: item.name || item.title })),
);
export const menuItemBySlugSelector = slug =>
  createSelector(
    getRootSlug,
    getCategoriesGroupedBySlug,
    (rootSlug, categories) => categories[slug] || categories[rootSlug],
  );
export const faqRootCategorySelector = createSelector(
  getRootSlug,
  getCategoriesGroupedBySlug,
  // TODO make this not duplicated
  (rootSlug, categories) => categories[rootSlug],
);

export const articlesDictSelector = createSelector(
  faqStateSelector,
  state => state.articlesDict,
);
export const articlesSelector = createTranslationSelector(
  faqStateSelector,
  articlesDictSelector,
  (state, articlesDict) => state.articleSlugs.map(slug => articlesDict[slug]),
);
const getArticleSlugParam = createParameterSelector(param => param.slug);
export const articleBySlugSelector = createSelector(
  articlesDictSelector,
  getArticleSlugParam,
  (articlesDict, slug) => articlesDict[slug],
);

export const isFaqInitializingSelector = createSelector(
  faqStateSelector,
  state => state.isFaqInitializing,
);
export const areArticlesAsMenuItemsSelector = createSelector(
  faqStateSelector,
  state => state.areArticlesAsMenuItems,
);
export const areArticlesLoadingSelector = createSelector(
  faqStateSelector,
  state => state.areArticlesLoading,
);
export const areMenuItemsChangingSelector = createSelector(
  faqStateSelector,
  state => state.areMenuItemsChanging,
);

export const articleDetailsSlugSelector = createSelector(
  faqStateSelector,
  state => state.articleDetails,
);

export const getArticleDetails = createTranslationSelector(
  articleDetailsSlugSelector,
  articlesDictSelector,
  (slug, articlesDict) => articlesDict[slug],
);

export const getFAQRootCategory = createSelector(
  getRootSlug,
  getCategoriesGroupedBySlug,
  (rootSlug, categories) => categories[rootSlug],
);

const toBreadCrumbItem = item => ({
  label: item.name || item.title,
  url: `/faq/${item.slug}`,
  slug: item.slug,
});

const breadcrumbsSelector = activeSlug =>
  createSelector(
    getFAQRootCategory,
    getCategoryBySlug(activeSlug),
    getCategoriesGroupedById,
    areArticlesAsMenuItemsSelector,
    (
      rootCategory,
      activatingCategory,
      categoriesGroupedById,
      areArticlesAsMenuItems,
    ) => {
      if (!rootCategory) {
        return [];
      }

      if (activeSlug === rootCategory.slug) {
        return rootCategory && areArticlesAsMenuItems ? [rootCategory] : [];
      }

      let runner = activatingCategory;

      let results = [];
      // TODO: optimize this.
      while (runner !== undefined) {
        results = [runner, ...results];

        runner = categoriesGroupedById[runner.parentCategoryId];
        if (!runner || !runner.leftOffset || runner.id === rootCategory.id) {
          // if (results.length) {
          //   results[results.length - 1].disabled = areArticlesAsMenuItems;
          // }

          return [rootCategory, ...results];
        }
      }

      return results;
    },
  );

export const getBreadcrumbs = slug =>
  createSelector(
    createTranslationSelector(breadcrumbsSelector(slug), items => items),
    items => items.map(toBreadCrumbItem),
  );

export const relatedArticlesSelector = createTranslationSelector(
  faqStateSelector,
  articlesDictSelector,
  (state, articlesDict) =>
    state.articleSlugs
      .slice(0, MAX_NUM_RECENT_VIEWED_ARTICLES)
      .map(slug => articlesDict[slug]),
);

export const recentViewedArticlesSelector = createSelector(
  faqStateSelector,
  articlesDictSelector,
  (state, articlesDict) =>
    state.recentViewedArticleSlugs.map(slug => articlesDict[slug]),
);
export const recentViewedTranslatedArticlesSelector = createTranslationSelector(
  recentViewedArticlesSelector,
  articles => articles,
);

export const areRecentViewedArticlesLoadedSelector = createSelector(
  faqStateSelector,
  state => state.areRecentViewedArticlesLoaded,
);

export const dataLoadingSelector = createSelector(
  isFaqInitializingSelector,
  areMenuItemsChangingSelector,
  areArticlesLoadingSelector,
  (initializing, areMenuItemsChanging, areArticlesLoading) =>
    initializing || areMenuItemsChanging || areArticlesLoading,
);

export default handleActions(
  new Map([
    [
      initializeFaqData,
      state => ({
        ...state,
        isFaqInitializing: true,
        isFaqInitializingError: null,
      }),
    ],
    [
      initializeFaqDataSuccess,
      state => ({
        ...state,
        isFaqInitializing: false,
        isFaqInitializingError: null,
      }),
    ],
    [
      initializeFaqDataFailed,
      (state, { payload: { error } }) => ({
        ...state,
        isFaqInitializing: false,
        isFaqInitializingError: error,
      }),
    ],
    [
      fetchArticles,
      state => ({
        ...state,
        areArticlesLoading: true,
        areArticlesLoadingError: false,
      }),
    ],
    [
      fetchArticlesSuccess,
      (state, { payload }) => {
        const result = payload.reduce(
          (acc, article) => ({
            slugs: [...acc.slugs, article.slug],
            articlesDict: { ...acc.articlesDict, [article.slug]: article },
          }),
          {
            slugs: [],
            articlesDict: {},
          },
        );

        return {
          ...state,
          articleSlugs: result.slugs,
          articlesDict: {
            ...state.articlesDict,
            ...result.articlesDict,
          },
          areArticlesLoading: false,
          areArticlesLoadingError: null,
        };
      },
    ],
    [
      fetchArticlesFailed,
      (state, { payload: { error } }) => ({
        ...state,
        areArticlesLoading: false,
        areArticlesLoadingError: error,
      }),
    ],
    [
      changeMenuItems,
      state => ({
        ...state,
        areMenuItemsChanging: true,
        areArticlesAsMenuItems: false,
        menuItemsChangeError: null,
      }),
    ],
    [
      changeMenuItemsSuccess,
      (state, { payload: { menuItems, areArticlesAsMenuItems } }) => ({
        ...state,
        menuItems,
        areArticlesAsMenuItems,
        areMenuItemsChanging: false,
        menuItemsChangeError: null,
      }),
    ],
    [
      changeMenuItemsFailed,
      (state, { payload: { error } }) => ({
        ...state,
        areMenuItemsChanging: false,
        menuItemsChangeError: error,
      }),
    ],
    [
      setArticleDetails,
      (state, { payload }) => ({
        ...state,
        articleDetails: payload,
      }),
    ],
    [
      fetchRecentViewedArticles,
      state => ({
        ...state,
        areRecentViewedArticlesLoading: true,
        areRecentViewedArticlesLoadingError: null,
      }),
    ],
    [
      fetchRecentViewedArticlesSuccess,
      (state, { payload }) => {
        const result = payload.reduce(
          (acc, article) => ({
            slugs: [...acc.slugs, article.slug],
            articlesDict: { ...acc.articlesDict, [article.slug]: article },
          }),
          {
            slugs: [],
            articlesDict: {},
          },
        );

        return {
          ...state,
          recentViewedArticleSlugs: result.slugs,
          articlesDict: {
            ...state.articlesDict,
            ...result.articlesDict,
          },
          areRecentViewedArticlesLoaded: true,
          areRecentViewedArticlesLoading: false,
          areRecentViewedArticlesLoadingError: null,
        };
      },
    ],
    [
      fetchRecentViewedArticlesFailed,
      (state, { payload: { error } }) => ({
        ...state,
        areRecentViewedArticlesLoading: false,
        areRecentViewedArticlesLoadingError: error,
      }),
    ],
    [
      changeRecentViewedArticlesSuccess,
      (state, { payload }) => ({
        ...state,
        recentViewedArticleSlugs: payload,
      }),
    ],
    [
      changeRecentViewedArticlesFailed,
      (state, { payload: { error } }) => ({
        ...state,
        areRecentViewedArticlesLoadingError: error,
      }),
    ],
    [
      resetFaq,
      state => ({
        // TODO: check that we need to reset all or just few fields
        ...state,
        isFaqInitializing: true,
        isFaqInitializingError: null,
        menuItems: [],
        articleDetails: '',
      }),
    ],
  ]),
  { ...initialState },
);
