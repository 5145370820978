import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { useDispatch, useSelector } from 'react-redux';
import Translate from '../../../components/Translate';
import * as configs from '../../../configs';
import { scrollToElement } from '../../../utils';
import CompleteSubmission from '../../CompleteSubmission';

import ContactForm from './ContactForm';
import styles from './home.styles';
import {
  changeContactSubmittedStatus,
  contactSubmittedSelector,
} from '../contact.duck';

const Home = ({ classes }) => {
  const dispatch = useDispatch();

  const isSubmitted = useSelector(contactSubmittedSelector);

  const scrollToMap = () => {
    setTimeout(() => scrollToElement('lets-park-it-address'), 200);
  };

  const onClose = () => dispatch(changeContactSubmittedStatus(false));

  const contactFormRenderer = () => {
    // TODO: add animation.
    if (isSubmitted) {
      return (
        <CompleteSubmission
          message="CONTACT.HOME.COMPLETE_SUBMISSION.MESSAGE"
          completeButtonText="CONTACT.HOME.COMPLETE_SUBMISSION.BUTTON_TEXT"
          onCompleteButtonClick={onClose}
        />
      );
    }

    return <ContactForm className={classes.form} />;
  };

  return (
    <div className={classes.root}>
      <div className={classes.informations}>
        <h2 className={classes.informationTitle}>
          <Translate id="CONTACT.HOME.TITLE" />
        </h2>
        <p className={classes.informationSubTitle}>
          <Translate id="CONTACT.HOME.SUB_TITLE" html />
        </p>
        <p className={classes.label}>
          <Translate id="CONTACT.HOME.ADDRESS.LABEL" />
        </p>
        <p
          role="presentation"
          className={classes.value}
          onClick={() => scrollToMap()}
        >
          <Translate id="CONTACT.HOME.ADDRESS.CONTENT" />
        </p>
        <p className={classes.label}>
          <Translate id="CONTACT.HOME.PHONE_NUMBER.LABEL" />
        </p>
        <p>
          <a
            href={`tel:${configs.LETS_PARK_IT_PHONE_NUMBER}`}
            className={classes.value}
            target="_blank"
            rel="noopener noreferrer"
          >
            {configs.LETS_PARK_IT_PHONE_NUMBER}
          </a>
        </p>
        <p className={classes.label}>
          <Translate id="CONTACT.HOME.EMAIL.LABEL" />
        </p>
        <p>
          <a
            href={`mailto:${configs.LETS_PARK_IT_EMAIL_ADDRESS}`}
            className={classes.value}
            target="_blank"
            rel="noopener noreferrer"
          >
            {configs.LETS_PARK_IT_EMAIL_ADDRESS}
          </a>
        </p>
        <div className={classes.socials}>
          <a
            href={configs.LETS_PARK_IT_FACEBOOK_URL}
            className={classes.socialIcon}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="icon-facebook" />
          </a>
          <a
            href={configs.LETS_PARK_IT_YOUTUBE_URL}
            className={classes.socialIcon}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="icon-youtube" />
          </a>
        </div>
      </div>
      {contactFormRenderer()}
    </div>
  );
};

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(Home);
