import { THEME } from '../../constants';

export default {
  root: {
    padding: 0,
    margin: 0,
    listStyleType: 'none',
    flex: 1,
  },
  item: {
    lineHeight: 1.25,
    '& + $item': { marginTop: 20 },
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: THEME.colors.primary,
    },
  },
};
