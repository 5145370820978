import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Script = ({ id, src }) => {
  useEffect(() => {
    if (!document.querySelector(`#${id}`)) {
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      document.body.append(script);
    }
  });
  return <span data-debug-id={id} />;
};

Script.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

export default Script;
