import { THEME } from '../../constants';

export default {
  ol: {
    margin: 0,
    display: 'flex',
    padding: 0,
    flexWrap: 'wrap',
    listStyle: 'none',
    alignItems: 'center',
  },
  item: {
    lineHeight: 1.125,
    '& > a': {
      color: THEME.colors.primary,
      fontSize: 18,
      '&.disabled': {
        color: '#8d8d8d',
        pointerEvents: 'none',
      },
    },
  },
  separator: {
    marginLeft: 5,
    marginRight: 5,
    fontSize: 13,
    color: '#8d8d8d',
  },
};
