import Home from '../containers/Home';
import Contact from '../containers/Contact';
import Support from '../containers/Support';
import FAQ from '../containers/FAQ';

/**
 * Using generate app routes
 */
export default [
  {
    path: '/',
    title: 'Home',
    component: Home,
    exact: true,
  },
  {
    path: '/contact',
    title: 'Contact',
    component: Contact,
    exact: true,
  },
  {
    path: '/support',
    title: 'Support',
    component: Support,
    exact: true,
  },
  {
    path: '/faq',
    title: 'FAQ',
    component: FAQ,
    exact: true,
  },
  {
    path: '/faq/:filterSlug',
    title: 'FAQ',
    component: FAQ,
    exact: true,
  },
  {
    path: '/faq/:filterSlug/:articleSlug',
    title: 'FAQ',
    component: FAQ,
    exact: true,
  },
  {
    path: '/**',
    title: 'Redirecting',
    redirect: true,
    to: '/',
    exact: true,
  },
];
