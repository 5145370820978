import { combineReducers } from 'redux';

import categories from './categories.duck';
import locale from './locale.duck';

import faq from '../containers/FAQ/faq.duck';
import home from '../containers/Home/home.duck';
import contact from '../containers/Contact/contact.duck';
import footer from '../containers/Footer/footer.duck';

// eslint-disable-next-line max-len
import notificationManager from '../containers/NotificationManager/notificationManager.duck';

// eslint-disable-next-line no-unused-vars
export default history =>
  combineReducers({
    categories,
    faq,
    home,
    notificationManager,
    contact,
    footer,
    locale,
  });
