import { THEME } from '../../../constants';

export default {
  root: {
    listStyleType: 'none',
    marginBottom: 0,
    paddingLeft: 0,
    fontSize: 12,
    lineHeight: '16px',
    whiteSpace: 'nowrap',
  },
  localeWrapper: {
    display: 'inline-block',
  },
  locale: {
    fontSize: 12,
    color: '#16212C',
    cursor: 'pointer',
    lineHeight: '16px',
    whiteSpace: 'nowrap',
    '&.active': {
      textShadow: '1px 0 0 currentColor',
      color: THEME.colors.primary,
    },
    '&:hover': { color: THEME.colors.primary },
  },
  divider: {
    marginLeft: 2,
    marginRight: 2,
  },
};
