/* eslint-disable */
import { THEME } from '../../../constants';

const heightOfNavigation = 100;
const heightOfNavigationLg = 130;
const heightOfNavigationMd = 110;
const heightOfNavigationSm = 80;
const heightOfSearchbar = 125;
const heightOfSearchbarMobile = 69;

export default {
  root: {
    padding: 0,
    overflow: 'hidden',
    position: 'relative',
    '& .leaflet-control-locate': { display: 'none' },
  },
  banner: {
    top: 39,
    left: '50%',
    zIndex: 500,
    width: '100%',
    maxWidth: 985,
    position: 'absolute',
    transform: 'translateX(-50%)',
  },
  searchForm: {
    top: 0,
    left: 0,
    zIndex: 600,
    width: '100%',
    position: 'absolute',
  },
  completeSubmissionCustom: {
    maxWidth: '25rem',
  },
  formWrapper: {
    left: 0,
    zIndex: 500,
    overflow: 'auto',
    paddingRight: 20,
    position: 'absolute',
    top: heightOfSearchbar,
    display: 'inline-block',
    height: `calc(100vh - ${heightOfNavigation}px - ${heightOfSearchbar}px)`,
    '-ms-overflow-style': 'none' /* IE 11 hide scrollbar */,
    scrollbarWidth: 'none' /* Firefox hide scrollbar */,
    '&::-webkit-scrollbar': { display: 'none' },
    '&:not(.open)': {
      width: 0,
      padding: 0,
      overflow: 'visible',
      '& $showFormBtn': { display: 'flex' },
      '& $form': { width: 0, overflow: 'hidden' },
      '& > div': { display: 'none' },
    },
    '&.loading': {
      pointerEvents: 'none',
      '& $form:after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, .1)',
      },
    },
    '&.completed': {
      display: 'flex',
    },
  },
  showFormBtn: {
    top: 0,
    right: -50,
    width: 50,
    height: 50,
    display: 'none',
    cursor: 'pointer',
    alignItems: 'center',
    position: 'absolute',
    justifyContent: 'center',
    backgroundColor: 'rgba(14, 152, 213, 0.8)',
    '& [class^=icon-]': { color: '#FEFEFE' },
  },
  form: {
    height: '100%',
  },

  [`@media (max-width: ${THEME.breakpoints.maxLg}px)`]: {
    formWrapper: {
      height: `calc(100vh - ${heightOfNavigationLg}px - ${heightOfSearchbar}px)`,
    },
  },
  [`@media (max-width: ${THEME.breakpoints.maxMd}px)`]: {
    formWrapper: {
      height: `calc(100vh - ${heightOfNavigationMd}px - ${heightOfSearchbar}px)`,
    },
  },
  [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
    formWrapper: {
      top: heightOfSearchbarMobile,
      height: `calc(100vh - ${heightOfNavigationSm}px - ${heightOfSearchbarMobile}px)`,
    },
  },
};
