import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import styles from './imageAdder.styles';
import { generateId } from '../../../utils';

const ImageAdder = ({ classes, className, onChange, accept, ...rest }) => {
  const id = generateId();
  const ref = useRef();

  const handleInputChange = event => {
    const {
      target: { files },
    } = event;
    const e = { ...event };
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        onChange(e, reader.result);

        // Allow upload the same file.
        e.target.value = '';
      };
      reader.readAsDataURL(files[0]);

      return;
    }
    onChange(event);
  };

  return (
    <label className={classNames(classes.label, className)} htmlFor={id}>
      <input
        ref={ref}
        {...rest}
        id={id}
        type="file"
        accept={accept}
        onChange={handleInputChange}
        className={classes.input}
      />

      <span className={classes.addBtn}>
        <i className="icon-plus" />
      </span>
    </label>
  );
};

ImageAdder.defaultProps = {
  className: '',
  accept: 'image/*',
  name: 'images',
  onChange: () => {},
};

ImageAdder.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  accept: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default injectSheet(styles)(ImageAdder);
