import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';
import { injectIntl } from 'react-intl';
import { get, useFormContext } from 'react-hook-form';

import styles from './errors.styles';

const Errors = ({
  className,
  classes,
  name,
  errors,
  intl,
  showMultipleError,
}) => {
  const methods = useFormContext();
  const errorResult = get(errors || methods.errors, name);

  if (!errorResult) {
    return null;
  }
  const {
    type: typeFromRegister,
    message: messageFromRegister,
    types,
  } = errorResult;

  const singleError = !messageFromRegister
    ? []
    : [messageFromRegister || typeFromRegister];
  const multipleErrors = !types ? [] : Object.values(types);

  let output = [];
  if (
    showMultipleError ||
    showMultipleError === undefined ||
    showMultipleError === null
  ) {
    output = multipleErrors.length ? multipleErrors : singleError;
  } else {
    output = singleError;
  }

  return output.map(item => (
    <p key={item} className={classNames(classes.root, className)}>
      {intl.formatMessage({ id: item })}
    </p>
  ));
};

Errors.defaultProps = {
  className: '',
  errors: {},
  showMultipleError: undefined,
};

Errors.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  errors: PropTypes.object,

  intl: PropTypes.object.isRequired,
  showMultipleError: PropTypes.bool,
};

export default injectIntl(injectSheet(styles)(Errors));
