import {
  takeLatest,
  all,
  put,
  select,
  race,
  take,
  call,
} from 'redux-saga/effects';

import { MAX_NUM_RECENT_VIEWED_ARTICLES } from '../../configs';
import { STORAGES } from '../../constants';
import {
  CATEGORIES_FETCH_FAILED,
  CATEGORIES_FETCH_SUCCESS,
  fetchCategories,
  getCategoryBySlug,
  getCategoriesGroupedBySlug,
} from '../../ducks/categories.duck';
import { api } from '../../utils';
import {
  ARTICLES_FETCH,
  ARTICLES_FETCH_FAILED,
  ARTICLES_FETCH_SUCCESS,
  ARTICLES_RECENT_VIEWED_CHANGE,
  ARTICLES_RECENT_VIEWED_FETCH,
  changeMenuItems,
  changeMenuItemsFailed,
  changeMenuItemsSuccess,
  changeRecentViewedArticlesFailed,
  changeRecentViewedArticlesSuccess,
  FAQ_DATA_INITIALIZE,
  fetchArticles,
  fetchArticlesFailed,
  fetchArticlesSuccess,
  fetchRecentViewedArticles,
  fetchRecentViewedArticlesFailed,
  fetchRecentViewedArticlesSuccess,
  areRecentViewedArticlesLoadedSelector,
  recentViewedArticlesSelector,
  getRootSlug,
  initializeFaqDataFailed,
  initializeFaqDataSuccess,
  MENU_ITEMS_CHANGE,
  articleBySlugSelector,
  menuItemsSelector,
} from './faq.duck';

function* initializeFaqDataFlow() {
  yield takeLatest(FAQ_DATA_INITIALIZE, function* onInitializeFaqData({
    payload,
  }) {
    try {
      yield put(fetchCategories());
      yield put(fetchRecentViewedArticles());

      const { success, failed } = yield race({
        success: take(CATEGORIES_FETCH_SUCCESS),
        failed: take(CATEGORIES_FETCH_FAILED),
      });

      if (success) {
        yield put(initializeFaqDataSuccess());
        yield put(changeMenuItems(payload));
      } else {
        const { error } = failed.payload;
        yield put(initializeFaqDataFailed({ error }));
      }
    } catch (error) {
      yield put(initializeFaqDataFailed({ error }));
    }
  });
}

function* menuItemsChangeFlow() {
  yield takeLatest(MENU_ITEMS_CHANGE, function* onMenuItemsChange({ payload }) {
    try {
      const rootSlug = yield select(getRootSlug);
      const { filterSlug = rootSlug, articleSlug } = payload;
      let menuItems = yield select(menuItemsSelector);

      if (
        articleSlug &&
        menuItems.length &&
        menuItems.find(item => item.slug === articleSlug)
      ) {
        yield put(
          changeMenuItemsSuccess({ menuItems, areArticlesAsMenuItems: true }),
        );
      } else {
        yield put(fetchArticles({ filterSlug, articleSlug }));

        const { success, failed } = yield race({
          success: take(ARTICLES_FETCH_SUCCESS),
          failed: take(ARTICLES_FETCH_FAILED),
        });

        if (success) {
          const articles = success.payload;
          menuItems = [];
          if (!articleSlug) {
            const activatingCategory = yield select(
              getCategoryBySlug(filterSlug),
            );
            const bySlug = yield select(getCategoriesGroupedBySlug);

            menuItems = activatingCategory.children.map(({ slug }) => ({
              ...bySlug[slug],
              url: `/faq/${slug}`,
              slug,
            }));
          }

          const areArticlesAsMenuItems = !menuItems.length || !!articleSlug;

          menuItems = areArticlesAsMenuItems ? articles : menuItems;

          yield put(
            changeMenuItemsSuccess({ menuItems, areArticlesAsMenuItems }),
          );
        } else {
          const { error } = failed.payload;
          yield put(changeMenuItemsFailed({ error }));
        }
      }
    } catch (error) {
      yield put(changeMenuItemsFailed({ error }));
    }
  });
}

function* fetchArticlesFlow() {
  yield takeLatest(ARTICLES_FETCH, function* onFetchArticles({ payload }) {
    try {
      const { filterSlug } = payload;
      const articles = yield call(api.fetchArticlesByCategorySlug, filterSlug);

      yield put(fetchArticlesSuccess(articles));
    } catch (error) {
      yield put(fetchArticlesFailed({ error }));
    }
  });
}

function* fetchRecentViewedArticlesFlow() {
  yield takeLatest(
    ARTICLES_RECENT_VIEWED_FETCH,
    function* onFetchRecentViewedArticles() {
      try {
        const areRecentViewedArticlesLoaded = yield select(
          areRecentViewedArticlesLoadedSelector,
        );
        const articleIds = JSON.parse(
          localStorage.getItem(STORAGES.recentViewedArticles),
        );

        if (areRecentViewedArticlesLoaded) {
          const articles = yield select(recentViewedArticlesSelector);
          yield put(fetchRecentViewedArticlesSuccess(articles));
        } else if (!articleIds || !articleIds.length) {
          yield put(fetchRecentViewedArticlesSuccess([]));
        } else {
          // TODO: Check that we should call one api only or list of api
          const articles = yield all(
            articleIds.map(id => call(api.fetchArticleById, id)),
          );

          yield put(fetchRecentViewedArticlesSuccess(articles));
        }
      } catch (error) {
        yield put(fetchRecentViewedArticlesFailed({ error }));
      }
    },
  );
}

function* changeRecentViewedArticlesFlow() {
  yield takeLatest(
    ARTICLES_RECENT_VIEWED_CHANGE,
    function* onChangeRecentViewedArticles({ payload }) {
      try {
        const articleDetail = yield select(articleBySlugSelector, {
          slug: payload,
        });
        const articles = yield select(recentViewedArticlesSelector);

        const merged = [
          { ...articleDetail },
          ...articles.filter(article => article.slug !== payload),
        ];

        const newArticles =
          MAX_NUM_RECENT_VIEWED_ARTICLES < merged.length
            ? merged.slice(1, MAX_NUM_RECENT_VIEWED_ARTICLES + 1)
            : merged;

        const { slugs, ids } = newArticles.reduce(
          (acc, article) => ({
            ids: [...acc.ids, article.id],
            slugs: [...acc.slugs, article.slug],
          }),
          { slugs: [], ids: [] },
        );

        localStorage.setItem(
          STORAGES.recentViewedArticles,
          JSON.stringify(ids),
        );

        yield put(changeRecentViewedArticlesSuccess(slugs));
      } catch (error) {
        yield put(changeRecentViewedArticlesFailed({ error }));
      }
    },
  );
}

export default function* faqFlow() {
  yield all([
    initializeFaqDataFlow(),
    menuItemsChangeFlow(),
    fetchArticlesFlow(),
    fetchRecentViewedArticlesFlow(),
    changeRecentViewedArticlesFlow(),
  ]);
}
