export default {
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  spinner: {
    maxWidth: '38rem',
    flexGrow: 1,
    flexShrink: 1,
  },
};
