import { THEME } from '../../constants';

export default {
  root: {
    top: 0,
    height: 30,
    zIndex: 1001,
    width: '100%',
    color: '#F7F8FB',
    position: 'fixed',
    padding: [0, THEME.paddingSide],
    backgroundColor: THEME.colors.primary,
  },
  item: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    color: 'currentColor',
    '&:hover': {
      color: '#3c3c3c',
      textDecoration: 'none',
    },
  },
  itemIcon: {
    fontSize: 13,
    marginRight: 8,
    color: 'currentColor',
  },
  itemLabel: { display: 'inline-block' },

  [`@media (min-width: ${THEME.breakpoints.minXl}px)`]: {
    item: {
      float: 'left',
      '& + $item': { marginLeft: 40 },
      '&:nth-last-of-type(-n + 2)': { float: 'right' },
    },
  },
  [`@media (max-width: ${THEME.breakpoints.maxXl}px)`]: {
    root: { display: 'flex', justifyContent: 'space-between' },
  },
  [`@media (max-width: ${THEME.breakpoints.maxLg}px) 
    and (min-width: ${THEME.breakpoints.minSm}px)`]: {
    root: {
      height: 60,
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    item: {
      width: '50%',
      height: 'auto',
      justifyContent: 'center',
      '&:nth-last-of-type(-n + 3)': { width: '33%' },
    },
  },
  [`@media (max-width: ${THEME.breakpoints.maxMd}px)`]: {
    root: { padding: [0, THEME.paddingSideMobile] },
  },
  [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      height: 92,
    },
    item: {
      display: 'inline-flex',
      height: 'auto',
      fontSize: 14,
      lineHeight: '19px',
    },
    //   itemIcon: { marginRight: 0 },
    //   itemLabel: { display: 'none' },
  },
};
