import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import { Link } from 'react-router-dom';
import styles from './breadcrumb.styles';

const Breadcrumb = ({ className, classes, items }) => (
  <ol className={classNames(className, classes.ol)}>
    {items.map((item, itemIndex) => (
      <li key={item.url} className={classes.item}>
        <Link
          key={item.url}
          to={item.url}
          className={classNames(item.disabled && 'disabled')}
        >
          {item.label}
        </Link>
        {itemIndex < items.length - 1 ? (
          <i className={classNames(classes.separator, 'icon-navigate-next')} />
        ) : null}
      </li>
    ))}
  </ol>
);

Breadcrumb.defaultProps = {
  className: '',
  items: [],
};

Breadcrumb.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ),
};

export default injectSheet(styles)(Breadcrumb);
