import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import Translate from '../../../components/Translate';
import SimpleList from '../../../components/SimpleList';

import styles from './moreArticles.styles';

const MoreArticles = ({
  classes,
  recentViewedArticles,
  relatedArticles,
  articleClicked,
}) => (
  <div className={classes.root}>
    <div className={classes.recentView}>
      <p className={classes.item}>
        <Translate id="FAQ.RECENT_POSTS.TITLE" />
      </p>
      <SimpleList
        items={recentViewedArticles}
        valueKey="title"
        itemClicked={articleClicked}
      />
    </div>
    <div className={classes.relatedView}>
      <p className={classes.item}>
        <Translate id="FAQ.RELATED_POSTS.TITLE" />
      </p>
      <SimpleList
        items={relatedArticles}
        valueKey="title"
        itemClicked={articleClicked}
      />
    </div>
  </div>
);

MoreArticles.defaultProps = {
  articleClicked: () => {},
};

MoreArticles.propTypes = {
  classes: PropTypes.object.isRequired,
  recentViewedArticles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ).isRequired,
  relatedArticles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  articleClicked: PropTypes.func,
};

export default injectSheet(styles)(MoreArticles);
