import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import SectionLayout from '../../components/SectionLayout';
import Translate from '../../components/Translate';
import SectionTitle from '../../components/SectionTitle';
import history from '../../history';

import Breadcrumb from '../../components/Breadcrumb';
import LeftMenu from './LeftMenu';
import Useful from './Useful/Useful';
import MoreArticles from './MoreArticles';
import Articles from './Articles';
import Spinner from './Spinner';

import styles from './faq.styles';
import ArticleDetails from './ArticleDetails';

import {
  changeMenuItems,
  articlesSelector,
  faqRootCategorySelector,
  translatedMenuItemsSelector,
  initializeFaqData,
  isFaqInitializingSelector,
  areArticlesAsMenuItemsSelector,
  getBreadcrumbs,
  getArticleDetails,
  setArticleDetails,
  relatedArticlesSelector,
  recentViewedTranslatedArticlesSelector,
  changeRecentViewedArticles,
  resetFaq,
  dataLoadingSelector,
} from './faq.duck';
import { getCategoriesGroupedById } from '../../ducks/categories.duck';

const FAQ = ({ classes }) => {
  const dispatch = useDispatch();
  const { filterSlug, articleSlug } = useParams();

  const menuItems = useSelector(translatedMenuItemsSelector);
  const breadcrumbItems = useSelector(getBreadcrumbs(filterSlug));
  const categories = useSelector(getCategoriesGroupedById);
  const rootCategory = useSelector(faqRootCategorySelector);
  const initializing = useSelector(isFaqInitializingSelector);
  const dataLoading = useSelector(dataLoadingSelector);

  const [showDelayedLoading, setShowDelayedLoading] = useState(false);
  /** Slug of menu item */
  const [activeMenuItem, setActiveMenuItem] = useState(filterSlug);

  /** Slug of menu item */
  const [expandedMenuItem, setExpandedMenuItem] = useState('');
  const articles = useSelector(articlesSelector);
  const articleDetails = useSelector(getArticleDetails);
  const areArticlesAsMenuItems = useSelector(areArticlesAsMenuItemsSelector);
  const relatedArticles = useSelector(relatedArticlesSelector);
  const recentViewedArticles = useSelector(
    recentViewedTranslatedArticlesSelector,
  );

  useEffect(() => {
    dispatch(initializeFaqData({ filterSlug, articleSlug }));

    return () => {
      dispatch(resetFaq());
    };
  }, []);

  useEffect(() => {
    if (initializing) {
      return;
    }

    setActiveMenuItem(articleSlug || filterSlug);
    dispatch(changeMenuItems({ filterSlug, articleSlug }));

    if (articleSlug) {
      dispatch(setArticleDetails(articleSlug));
    }
  }, [initializing, filterSlug, articleSlug]);

  useEffect(() => {
    if (initializing || articleSlug) {
      return;
    }

    dispatch(setArticleDetails(articleSlug));
  }, [initializing, articleSlug]);

  useEffect(() => {
    let timeout = null;
    if (dataLoading) {
      setShowDelayedLoading(true);
    } else {
      timeout = setTimeout(() => {
        setShowDelayedLoading(false);
      }, 300);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [dataLoading]);

  const handleArticleClicked = useCallback(
    article => {
      dispatch(setArticleDetails(article.slug));
      dispatch(changeRecentViewedArticles(article.slug));
      const newFilterSlug = filterSlug || rootCategory.slug;
      history.push(`/faq/${newFilterSlug}/${article.slug}`);
    },
    [filterSlug, rootCategory],
  );

  const handleItemOnMoreArticleClicked = article => {
    const categoryId = article.categories[0].id;
    const { slug } = categories[categoryId];

    dispatch(setArticleDetails(article.slug));
    dispatch(changeRecentViewedArticles(article.slug));

    const link = `/faq/${slug}/${article.slug}`;
    history.push(link);
  };

  const handleExpandCategoryFilter = useCallback(
    item => {
      const selectedSlug = expandedMenuItem === item.slug ? '' : item.slug;
      setExpandedMenuItem(selectedSlug);
    },
    [expandedMenuItem],
  );

  const handleMenuItemClicked = useCallback(
    item => {
      const newFilterSlug = filterSlug || rootCategory.slug;

      let link;
      if (areArticlesAsMenuItems) {
        link = `/faq/${newFilterSlug}/${item.slug}`;
        dispatch(changeRecentViewedArticles(item.slug));
      } else {
        link = `/faq/${item.slug}`;
      }

      setActiveMenuItem(areArticlesAsMenuItems ? item.slug : newFilterSlug);

      history.push(link);
    },
    [areArticlesAsMenuItems, filterSlug, rootCategory],
  );

  const rightPanelRenderer = () => {
    if (showDelayedLoading) {
      return <Spinner className={classes.spinner} />;
    }

    if (articleDetails) {
      return <ArticleDetails article={articleDetails} />;
    }

    return (
      <Articles
        className={classes.articles}
        // With the last category, its articles will be set as menuItems
        // In that case, we do not need to show articles list again.
        articles={areArticlesAsMenuItems ? [] : articles}
        articleClicked={handleArticleClicked}
      />
    );
  };

  return (
    <div className={classes.root}>
      <SectionTitle>
        <Translate id="FAQ.TITLE" />
      </SectionTitle>
      <SectionLayout>
        <Breadcrumb items={breadcrumbItems} />
        <div className={classes.section}>
          <LeftMenu
            data={
              !areArticlesAsMenuItems && showDelayedLoading ? [] : menuItems
            }
            activeSlug={activeMenuItem}
            expandedSlug={expandedMenuItem}
            parentMenuItemClicked={handleMenuItemClicked}
            subMenuItemClicked={handleMenuItemClicked}
            onExpand={handleExpandCategoryFilter}
          />
          <div className={classes.content}>
            {rightPanelRenderer()}

            <Useful />
            <MoreArticles
              relatedArticles={relatedArticles}
              recentViewedArticles={recentViewedArticles}
              articleClicked={handleItemOnMoreArticleClicked}
            />
          </div>
        </div>
      </SectionLayout>
    </div>
  );
};

FAQ.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(FAQ);
