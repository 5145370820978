import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { GA_MEASUREMENT_ID } from './configs';
import { googleAnalytics } from './utils';

const GoogleAnalyticsProvider = ({ children }) => {
  useEffect(() => {
    googleAnalytics.initialize(GA_MEASUREMENT_ID);
  }, []);

  return <>{children}</>;
};

GoogleAnalyticsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GoogleAnalyticsProvider;
