import { THEME } from '../../../../constants';

export default {
  root: {
    color: '#F7F8FB',
    height: 'inherit',
    position: 'relative',
    display: 'inline-flex',
    padding: [78, 47, 20, 105],
    backgroundColor: 'rgba(14, 152, 213, 0.8)',

    '&.more': {
      minWidth: 883,
    },
    '& > $button': { minWidth: 35, margin: [196, 30, 0] },
  },
  mainForm: {
    width: '100%',
    maxWidth: 400,
    overflowY: 'auto',
  },
  subForm: {
    width: '100%',
    maxWidth: 285,
    display: 'flex',
    flexDirection: 'column',
  },
  inputFile: {
    height: 96,
    marginBottom: 18,
  },
  textarea: { flex: 1, maxHeight: 310 },
  input: { '&:not(:last-child)': { marginBottom: 24 } },
  groupInput: {
    display: 'flex',
    '& $input + $input': {
      maxWidth: 123,
      marginLeft: 24,
    },
  },
  groupBtn: {
    top: 166,
    right: 0,
    height: '100%',
    maxHeight: 250,
    position: 'absolute',
    display: 'inline-flex',
    flexDirection: 'column',
    transform: 'translateX(50%)',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
  },
  button: {
    width: 35,
    height: 35,
    display: 'flex',
    cursor: 'pointer',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FEFEFE',
    boxShadow: '0px 4px 4px rgba(14, 152, 213, 0.25)',
    '& [class^=icon-]': { color: THEME.colors.primary },
    '&.reverse': {
      border: '1px solid #FEFEFE',
      backgroundColor: THEME.colors.primary,
      '& [class^=icon-]': { color: '#FEFEFE' },
    },
  },

  [`@media (max-width: ${THEME.breakpoints.maxLg}px)`]: {
    root: { '&.more': { minWidth: 'auto' } },
  },
  [`@media (max-width: ${THEME.breakpoints.maxMd}px)`]: {
    root: { padding: [78, 30, 20, 20] },
  },
  [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: [47, 30, 20, 20],
      '&.more': {
        '& $mainForm': {
          maxHeight: `calc(100% - ${30 * 2}px - 35px - 192px - 18px - 150px)`,
        },
      },
      '& > $button': { margin: [30, 0], alignSelf: 'center' },
    },
    subForm: { maxWidth: 'unset' },
    inputFile: {
      '&.has-image': {
        height: '192px !important',
      },
    },
  },
};
