import { THEME } from '../../constants';

export default {
  root: { overflow: 'hidden' },
  dotsClass: {
    right: 33,
    margin: 0,
    bottom: 21,
    padding: 0,
    zIndex: 100,
    lineGeight: 0,
    display: 'block',
    listStyle: 'none',
    textAlign: 'center',
    position: 'absolute',
    '& li': {
      width: 15,
      height: 15,
      padding: 0,
      cursor: 'pointer',
      overflow: 'hidden',
      borderRadius: '50%',
      position: 'relative',
      display: 'inline-block',
      backgroundColor: '#fff',
      border: `1px solid ${THEME.colors.primary}`,
      '& button': {
        border: 0,
        padding: 5,
        fontSize: 0,
        width: '100%',
        lineHeight: 0,
        height: '100%',
        outline: 'none',
        display: 'block',
        cursor: 'pointer',
        color: 'transparent',
        background: 'transparent',
      },
      '& + li': { marginLeft: 5 },
      '&.slick-active': { backgroundColor: THEME.colors.primary },
    },
  },

  [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
    dotsClass: { right: 5, bottom: 14 },
  },
};
