import L from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { mapsApi } from '../../../../utils';

import markerIcon from './userMarkerIcon';

const provider = new OpenStreetMapProvider();

const defaultOptions = {
  position: 'topleft',
  style: 'button',
  showMarker: true,
  showPopup: true,
  // popupFormat: ({ result }) => `${result.label}`,
  popupFormat: ({ result }) => `${result.address}`,
  marker: {
    icon: markerIcon,
    draggable: true,
  },
  maxMarkers: 1,
  retainZoomLevel: false,
  animateZoom: true,
  searchLabel: 'Enter address',
  notFoundMessage: 'Sorry, that address could not be found.',
  messageHideDelay: 3000,
  zoomLevel: 13,
  classNames: {
    container: 'leaflet-bar leaflet-control leaflet-control-geosearch',
    button: 'leaflet-bar-part leaflet-bar-part-single',
    resetButton: 'reset',
    msgbox: 'leaflet-bar message',
    form: '',
    input: '',
  },
  options: {
    icon: markerIcon,
  },
  autoComplete: true,
  autoCompleteDelay: 250,
  autoClose: false,
  keepResult: false,
};

const markers = new L.FeatureGroup();
let defaultMarker;

const getGeolocations = async query => {
  const results = await provider.search({ query });

  return results;
};

const addMarker = (map, result, query, onDragEnd) => {
  if (defaultMarker) {
    map.removeLayer(defaultMarker);
  }
  const { marker: options, showPopup, popupFormat } = defaultOptions;

  const marker = new L.Marker([result.lat, result.lng], options);
  let popupLabel = result.address;

  if (typeof popupFormat === 'function') {
    popupLabel = popupFormat({ query, result });
  }

  marker.bindPopup(popupLabel);
  markers.addLayer(marker);

  if (showPopup) {
    marker.openPopup();
  }

  if (options && options.draggable) {
    marker.on('dragend', args => {
      const newPosition = marker.getLatLng();
      mapsApi
        .searchCoordinates({
          latitude: newPosition.lat,
          longitude: newPosition.lng,
        })
        .then(rs => {
          marker
            .setLatLng(newPosition, options)
            .bindPopup((rs[0] && rs[0].address) || 'Cannot get this position')
            .update();
          if (onDragEnd) {
            onDragEnd(
              rs[0] && { ...rs[0], lat: newPosition.lat, lng: newPosition.lng },
            );
          }
        });

      map.fireEvent('geosearch/marker/dragend', {
        location: marker.getLatLng(),
        event: args,
      });
    });
  }

  defaultMarker = marker;
  map.addLayer(defaultMarker);

  return marker;
};
const getZoom = map => {
  const { retainZoomLevel, zoomLevel } = defaultOptions;

  return retainZoomLevel ? map.getZoom() : zoomLevel;
};

const centerMap = (map, result) => {
  const { retainZoomLevel, animateZoom } = defaultOptions;
  const { viewport } = result;

  const resultBounds = new L.LatLngBounds(
    viewport.southwest,
    viewport.northeast,
  );
  const bounds = resultBounds.isValid() ? resultBounds : markers.getBounds();

  if (!retainZoomLevel && resultBounds.isValid()) {
    map.fitBounds(bounds, { animate: animateZoom });
  } else {
    map.setView(bounds.getCenter(), getZoom(map), {
      animate: animateZoom,
    });
  }
};

/**
 * Show the search result on map
 * @param {*} map - leaflet map
 * @param {*} result - string | object. Search query or Leaflet geolocation
 * @param {*} query - string. Search query
 */
const showResult = async (map, markerInfo, query, onDragEnd) => {
  if (!markerInfo) {
    return;
  }

  const marker = addMarker(map, markerInfo, query, onDragEnd);
  centerMap(map, markerInfo);
  map.fireEvent('geosearch/showlocation', {
    location: markerInfo,
    marker,
  });
};

export default showResult;
export { provider, getGeolocations };
