import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/vi';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider as Provider } from 'react-intl';
import { useSelector } from 'react-redux';

import messages from './intl';
import { getLocale } from './ducks/locale.duck';
import { DEFAULT_LOCALE } from './configs';

function normalizeLocale(locale) {
  if (locale.startsWith('en')) {
    return 'en';
  }
  if (locale.startsWith('vi')) {
    return 'vi';
  }

  console.warn(
    `The locale '${locale}' is not defined, so the locale english will be used`,
  );

  return 'en';
}

const IntProvider = ({ children }) => {
  const appLocale = useSelector(getLocale);
  const [locale, setLocale] = useState(normalizeLocale(DEFAULT_LOCALE));

  useEffect(() => {
    if (!appLocale) {
      return;
    }

    setLocale(normalizeLocale(appLocale));
  }, [appLocale]);

  return (
    <Provider locale={locale} messages={messages[appLocale]}>
      {children}
    </Provider>
  );
};

IntProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default IntProvider;
