import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import Button from '../../components/Button';
import Translate from '../../components/Translate';

import styles from './completeSubmission.styles';

const CompleteSubmission = ({
  className,
  icon,
  classes,
  message,
  completeButtonText,
  onCompleteButtonClick,
}) => (
  <div className={classNames(classes.root, className)}>
    <div className={classes.contentWrapper}>
      <i className={classNames(classes.completeIcon, icon)} />
      <p className={classes.completeMessage}>
        <Translate id={message} />
      </p>
    </div>
    <Button className={classes.completeButton} onClick={onCompleteButtonClick}>
      <Translate id={completeButtonText} />
    </Button>
  </div>
);

CompleteSubmission.defaultProps = {
  className: '',
  icon: 'icon-check-circle',
  onCompleteButtonClick: () => {},
};

CompleteSubmission.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  message: PropTypes.string.isRequired,
  completeButtonText: PropTypes.string.isRequired,
  onCompleteButtonClick: PropTypes.func,
};

export default injectSheet(styles)(CompleteSubmission);
