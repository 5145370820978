import { THEME } from '../../constants';

const buttonHeight = 49;
const xPadding = 30;

export default {
  root: {
    background: '#fff',
    boxShadow: '-2px 8px 20px rgb(0 0 0 / 20%)',
    borderRadius: 9,
    padding: [45, xPadding, xPadding],

    position: 'relative',
    minHeight: '18.75rem',
  },
  contentWrapper: {
    height: `calc(100% - ${buttonHeight}px)`,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  completeIcon: {
    fontSize: '4rem',
    color: THEME.colors.primary,
  },
  completeMessage: {
    fontSize: '1.5rem',
    textAlign: 'center',
    marginTop: '1rem',
    overflowY: 'auto',
  },
  completeButton: {
    height: buttonHeight,
    maxWidth: `calc(100% - ${xPadding * 2}px)`,
    position: 'absolute',
    bottom: 0,
    transform: 'translate(-50%, -50%)',
    left: '50%',
  },
};
