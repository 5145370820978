/* eslint-disable max-len */
/**
 *
 * Reference
 * https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
 *
 */

// Internet Explorer 6-11
const isIE = false || !!document.documentMode;

export default isIE;
