import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { FormattedMessage } from 'react-intl';

import { useDispatch, useSelector } from 'react-redux';
import { useOnClickOutside, generateId } from '../../../../utils';

import styles from './search.styles';
import {
  addressSuggestionsLoadingSelector,
  addressSuggestionsSelector,
  areShowAllProspectsSelector,
  detectUserPosition,
  fetchAddressSuggestions,
  searchMostFamiliarAddress,
  selectAddress,
  toggleAllProspectsVisible,
  userPositionLoadingSelector,
  userPositionSelector,
} from '../../home.duck';

const Search = ({ classes, className, geoLocation }) => {
  const [searchValue, setSearchValue] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [showSuggestionList, setShowSuggestionList] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState();
  const [
    updateAfterDetectUserLocation,
    setUpdateAfterDetectUserLocation,
  ] = useState(false);
  const suggestionsRef = useRef();
  const dispatch = useDispatch();
  const suggestValues = useSelector(addressSuggestionsSelector);
  const userGeolocation = useSelector(userPositionSelector);
  const userPositionLoading = useSelector(userPositionLoadingSelector);
  const addressSuggestionsLoading = useSelector(
    addressSuggestionsLoadingSelector,
  );
  const areShowAllProspects = useSelector(areShowAllProspectsSelector);

  useEffect(() => {
    if (geoLocation) {
      setSearchValue(geoLocation.address);
    }
  }, [geoLocation]);

  const userLocationFounded = geolocation => {
    setSelectedSuggestion(geolocation.address);
    setSearchValue(geolocation.address);
    dispatch(
      selectAddress({
        id: geolocation.id,
        address: geolocation.address,
      }),
    );
  };

  useEffect(() => {
    if (userGeolocation && updateAfterDetectUserLocation) {
      userLocationFounded(userGeolocation);
    }
  }, [userGeolocation]);

  const search = async () => {
    dispatch(fetchAddressSuggestions(searchValue));
    setSelectedSuggestion('');
    setShowSuggestionList(true);
  };

  const handleSearchButtonClick = () => {
    if (!searchValue || searchValue === selectedSuggestion) {
      return;
    }

    setSelectedSuggestion(searchValue);
    dispatch(searchMostFamiliarAddress(searchValue));
  };

  const handleInputChange = ({ target: { value } }) => {
    setSearchValue(value);
  };

  const handleInputKeyDown = ({ keyCode }) => {
    if (keyCode === 13) {
      if (showSuggestionList && suggestValues && suggestValues.length > 0) {
        const { address, id } = suggestValues[0];
        setSearchValue(address);
        setSelectedSuggestion(address);
        dispatch(selectAddress({ id, address }));
      } else {
        setSelectedSuggestion(searchValue);
        dispatch(searchMostFamiliarAddress(searchValue));
        dispatch(selectAddress({ address: searchValue }));
      }
    }
  };

  const handleInputFocus = () => {
    setShowSuggestionList(true);
  };

  const handleSuggestionClick = geolocation => {
    setSearchValue(geolocation.address);

    setSelectedSuggestion(geolocation.address);
    dispatch(
      selectAddress({ id: geolocation.id, address: geolocation.address }),
    );

    setShowSuggestionList(false);
  };

  const handleGeolocationClick = () => {
    if (userGeolocation) {
      userLocationFounded(userGeolocation);
    } else {
      dispatch(detectUserPosition());
      setUpdateAfterDetectUserLocation(true);
    }
  };

  const handleShowAllProspect = () => {
    dispatch(toggleAllProspectsVisible(!areShowAllProspects));
  };

  useOnClickOutside(suggestionsRef, () => {
    setShowSuggestionList(false);
  });

  useEffect(() => {
    if (!searchValue || searchValue === selectedSuggestion) {
      return;
    }

    setIsTyping(true);
    const timeout = setTimeout(() => {
      search();
      setIsTyping(false);
    }, 1000);

    /* eslint-disable-next-line consistent-return */
    return () => {
      clearTimeout(timeout);
      setIsTyping(false);
    };
  }, [searchValue]);

  const suggestionListRenderer = () => {
    if (addressSuggestionsLoading || isTyping) {
      return (
        <div
          ref={suggestionsRef}
          className={classNames(classes.suggestionsWrapper, 'loading')}
        >
          <i className="icon-loading rotating" />
        </div>
      );
    }

    return (
      showSuggestionList &&
      suggestValues &&
      suggestValues.length > 0 && (
        <div ref={suggestionsRef} className={classes.suggestionsWrapper}>
          {suggestValues.map(item => (
            <p
              key={generateId()}
              role="presentation"
              className={classes.suggestion}
              onClick={() => handleSuggestionClick(item)}
            >
              {item.address}
            </p>
          ))}
        </div>
      )
    );
  };

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.content}>
        <div className={classNames(classes.borderBox, classes.searchBox)}>
          <i className={classNames('icon-location', classes.locationIcon)} />
          <div className={classes.inputWrapper}>
            <FormattedMessage id="HOME.MAP.SEARCH.PLACEHOLDER">
              {msg => (
                <input
                  value={searchValue}
                  className={classes.input}
                  placeholder={msg}
                  onFocus={handleInputFocus}
                  onChange={handleInputChange}
                  onKeyDown={handleInputKeyDown}
                  data-private
                />
              )}
            </FormattedMessage>
            {suggestionListRenderer()}
          </div>
          <FormattedMessage id="HOME.MAP.SEARCH.BUTTON.SEARCH">
            {msg => (
              <button
                title={msg}
                className={classes.button}
                onClick={handleSearchButtonClick}
              >
                <i className="icon-search" />
              </button>
            )}
          </FormattedMessage>
          <FormattedMessage id="HOME.MAP.SEARCH.BUTTON.GEOLOCATION">
            {msg => (
              <button
                title={msg}
                className={classes.button}
                onClick={handleGeolocationClick}
              >
                <i
                  className={classNames(
                    'icon-geolocation',
                    userPositionLoading && 'rotating',
                  )}
                />
              </button>
            )}
          </FormattedMessage>
        </div>

        <div className={classes.delimiter} />
        <div className={classes.borderBox}>
          <FormattedMessage
            id={
              areShowAllProspects
                ? 'HOME.MAP.SEARCH.BUTTON.SHOWING_MINE'
                : 'HOME.MAP.SEARCH.BUTTON.SHOWING_ALL'
            }
          >
            {msg => (
              <button
                title={msg}
                className={classNames(classes.button, 'no-left-border')}
                onClick={handleShowAllProspect}
              >
                <i
                  className={
                    areShowAllProspects
                      ? 'icon-eye-regular'
                      : 'icon-eye-slash-regular'
                  }
                />
              </button>
            )}
          </FormattedMessage>
        </div>
      </div>
    </div>
  );
};

Search.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  geoLocation: PropTypes.object,
};
Search.defaultProps = {
  className: '',
  geoLocation: null,
};

export default injectSheet(styles)(Search);
