import { THEME } from '../../../constants';

export default {
  root: {
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center',
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    '&:before': {
      top: 0,
      left: 0,
      width: '100%',
      content: '""',
      height: '100%',
      position: 'absolute',
      background:
        'linear-gradient(90deg, #21303E 51.31%, rgba(33, 48, 62, 0.9) 70.58%)',
    },
  },

  clockWrapper: {
    left: -32,
    width: '100%',
    maxWidth: '50%',
    position: 'relative',
  },
  content: {
    top: '50%',
    left: '50%',
    zIndex: 2,
    maxHeight: 282,
    height: '100vh',
    display: 'flex',
    width: 'inherit',
    color: '#F7F8FB',
    textAlign: 'center',
    position: 'absolute',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transform: 'translate(-50%, -50%)',
  },
  clock: {
    top: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
    '& > time': {
      zoom: 1.7,
      '& .react-clock__face': { display: 'none' },
      '& .react-clock__hand__body': { backgroundColor: '#979ea4' },
      '& .react-clock__hour-hand__body, & .react-clock__minute-hand__body': {
        bottom: '50% !important',
      },
    },
  },
  title: {
    margin: 0,
    fontSize: 72,
    lineHeight: '98px',
    fontWeight: 'bold',
    color: 'currentColor',
  },
  smallText: {
    margin: 0,
    fontSize: 18,
    lineHeight: '25px',
    color: 'currentColor',
  },
  iphoneImage: {
    zIndex: 1,
    height: 0,
    width: '30%',
    minHeight: 'inherit',
    position: 'relative',
    backgroundColor: '#fff',
    right: -THEME.paddingSide,
    '& > img': {
      top: '50%',
      left: 0,
      position: 'absolute',
      transform: 'translate(-55%, -50%)',
    },
    '&:before': {
      top: '50%',
      left: -269,
      content: '""',
      height: '100%',
      position: 'absolute',
      transform: 'translateY(-50%)',
      borderRight: '270px solid #fff',
      borderTop: '50vh solid transparent',
      borderBottom: '50vh solid transparent',
    },
  },

  [`@media (max-width: ${THEME.breakpoints.maxXXl}px)`]: {
    clock: { '& > time': { zoom: 1.3 } },
  },
  [`@media (max-width: ${THEME.breakpoints.maxXl}px)`]: {
    content: { zoom: 0.8 },
    clock: { '& > time': { zoom: 1 } },
  },
  [`@media (max-width: ${THEME.breakpoints.maxLg}px)`]: {
    root: {
      justifyContent: 'center',
      '&:before': { background: '#21303E' },
    },
    clock: { '& > time': { zoom: 1.6 } },
    clockWrapper: {
      top: '50%',
      left: '50%',
      width: '100%',
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      maxWidth: `calc(100% - ${THEME.paddingSide}px)`,
    },
    content: { zoom: 1 },
    iphoneImage: { display: 'none' },
  },
  [`@media (max-width: ${THEME.breakpoints.maxMd}px)`]: {
    clock: { '& > time': { zoom: 1.5 } },
  },
  [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
    clock: { '& > time': { zoom: 1 } },
    clockWrapper: { maxWidth: `calc(100% - ${THEME.paddingSideMobile}px)` },
    content: { zoom: 0.8, maxHeight: 150 },
    title: { fontSize: 50 },
  },
  [`@media (max-width: ${THEME.breakpoints.maxXs}px)`]: {
    clock: { '& > time': { zoom: 0.8 } },
  },
};
