import breakpoints from './breakpoints';

const paddingSide = 107;
const paddingSideMobile = 20;

const transition = 'all 0.5s ease';
const boxShadow = '0px 10px 10px rgba(0,0,0,0.2);';

export default {
  colors: {
    primary: '#0E98D5',
    secondary: '#a777e3',
  },
  breakpoints,
  paddingSide,
  paddingSideMobile,
  transition,
  boxShadow,
};
