import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import { useDispatch, useSelector } from 'react-redux';
import SectionLayout from '../../../components/SectionLayout';
import CompleteSubmission from '../../CompleteSubmission';

import LeafletMap from './LeafletMap';
import Banner from './Banner';
import Form from './Form';
import styles from './map.styles';
import Search from './Search/Search';

import {
  defaultMarkersSelector,
  isMapBannerVisibleSelector,
  isParkingSlotFormVisibleSelector,
  isParkingSlotSubmittedSelector,
  parkingSlotSubmittingSelector,
  selectAddress,
  selectedAddressSelector,
  setMapBannerVisible,
  setParkingSlotFormSubmitStatus,
  setParkingSlotFormVisible,
  submitParkingSlot,
  uploadedParkingSlotImagesSelector,
  uploadingParkingSlotImagesSelector,
  uploadParkingSlotImage,
} from '../home.duck';

const Map = ({ classes }) => {
  const dispatch = useDispatch();
  const parkingSlotSubmitting = useSelector(parkingSlotSubmittingSelector);
  const isMapBannerVisible = useSelector(isMapBannerVisibleSelector);
  const isParkingSlotFormVisible = useSelector(
    isParkingSlotFormVisibleSelector,
  );
  const isParkingSlotSubmitted = useSelector(isParkingSlotSubmittedSelector);
  const selectedAddress = useSelector(selectedAddressSelector);
  const uploadingImages = useSelector(uploadingParkingSlotImagesSelector);
  const uploadedImages = useSelector(uploadedParkingSlotImagesSelector);
  const markers = useSelector(defaultMarkersSelector);

  const handleFormSubmit = async values =>
    dispatch(
      submitParkingSlot({
        ...values,
        lng: selectedAddress && selectedAddress.lng,
        lat: selectedAddress && selectedAddress.lat,
        address: selectedAddress && selectedAddress.address,
      }),
    );

  const fileWithSizePrefix = file => `${file.size}_${file.name}`;

  const handleImageChange = async images => {
    images.forEach(image => {
      const filename = fileWithSizePrefix(image.file);
      if (
        !uploadingImages.find(name => name === filename) &&
        !uploadedImages.find(name => name === filename)
      ) {
        dispatch(uploadParkingSlotImage(image.file));
      }
    });
  };

  const onMarkerDragEnd = newGeoLocation => {
    if (newGeoLocation) {
      dispatch(selectAddress(newGeoLocation));
    }
  };

  const onOpenForm = () => {
    dispatch(setParkingSlotFormVisible(true));
    dispatch(setParkingSlotFormSubmitStatus(false));
  };

  const formRenderer = () => {
    // TODO: Add animation.
    if (isParkingSlotSubmitted) {
      return (
        <CompleteSubmission
          className={classes.completeSubmissionCustom}
          message="HOME.MAP.FORM.COMPLETE_SUBMISSION.MESSAGE"
          completeButtonText="HOME.MAP.FORM.COMPLETE_SUBMISSION.BUTTON_TEXT"
          onCompleteButtonClick={onOpenForm}
        />
      );
    }

    return (
      <Form
        className={classes.form}
        onCancel={() => dispatch(setParkingSlotFormVisible(false))}
        onSubmit={handleFormSubmit}
        onImageChange={handleImageChange}
        success={isParkingSlotSubmitted}
        isLoading={parkingSlotSubmitting}
      />
    );
  };

  return (
    <SectionLayout id="map" className={classes.root}>
      <LeafletMap
        markers={markers}
        markerInfo={selectedAddress}
        onMarkerDragEnd={onMarkerDragEnd}
      />
      {isMapBannerVisible ? (
        <Banner
          className={classes.banner}
          onClick={() => {
            dispatch(setMapBannerVisible(false));
            dispatch(setParkingSlotFormVisible(true));
          }}
        />
      ) : (
        <>
          <Search
            className={classes.searchForm}
            geoLocation={selectedAddress}
          />
          <div
            className={classNames(
              classes.formWrapper,
              isParkingSlotFormVisible && 'open',
              parkingSlotSubmitting && 'loading',
              isParkingSlotSubmitted && 'completed',
            )}
          >
            <span
              role="presentation"
              className={classes.showFormBtn}
              onClick={onOpenForm}
            >
              <i className="icon-arrow-right" />
            </span>
            {formRenderer()}
          </div>
        </>
      )}
    </SectionLayout>
  );
};

Map.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(Map);
