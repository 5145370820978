export default {
  root: {},
  inputWrapper: {
    width: '100%',
    minHeight: 150,
    borderRadius: 4,
    display: 'flex',
    padding: [9, 12],
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'column',
    backgroundColor: '#FEFEFE',
  },
  label: {
    fontSize: 13,
    color: '#3E3E3E',
    fontWeight: 'bold',
    lineHeight: '18px',
    whiteSpace: 'nowrap',
  },
  textarea: {
    fontSize: 13,
    width: '100%',
    height: '100%',
    border: 'none',
    resize: 'none',
    minHeight: 100,
    color: '#3E3E3E',
    '&:focus': { outline: 'none' },
    '&::placeholder': {
      top: '50%',
      fontSize: 12,
      color: '#979EA4',
      lineHeight: '16px',
      position: 'absolute',
      transform: 'translateY(-50%)',
    },
  },
};
