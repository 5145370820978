import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import { FormProvider } from 'react-hook-form';
import styles from './form.styles';

const Form = ({ methods, onSubmit, className, classes, children }) => {
  return (
    <FormProvider {...methods}>
      <form
        className={classNames(classes.root, className)}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {children}
      </form>
    </FormProvider>
  );
};

Form.defaultProps = {
  className: '',
  children: null,
  onSubmit: () => {},
};

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  methods: PropTypes.shape({
    register: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
  }).isRequired,
};

export default injectSheet(styles)(Form);
