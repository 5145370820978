export default {
  root: {},
  input: {
    '& > input': {
      border: 'none',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  inputWrapper: {},
};
