import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import * as configs from '../../configs';

import styles from './topContacts.styles';

const contacts = [
  {
    label:
      configs.LETS_PARK_IT_EMAIL_ADDRESS_TITLE ||
      configs.LETS_PARK_IT_EMAIL_ADDRESS,
    icon: 'icon-mail',
    href: `mailto:${configs.LETS_PARK_IT_EMAIL_ADDRESS}`,
  },
  {
    label: configs.LETS_PARK_IT_ZALO_URL_TITLE || configs.LETS_PARK_IT_ZALO_URL,
    icon: 'icon-message',
    href: configs.LETS_PARK_IT_ZALO_URL,
  },
  {
    label:
      configs.LETS_PARK_IT_PHONE_NUMBER_TITLE ||
      configs.LETS_PARK_IT_PHONE_NUMBER,
    icon: 'icon-phone',
    href: `tel:${configs.LETS_PARK_IT_PHONE_NUMBER}`,
  },
  {
    label:
      configs.LETS_PARK_IT_FACEBOOK_URL_TITLE ||
      configs.LETS_PARK_IT_FACEBOOK_URL,
    icon: 'icon-facebook',
    href: configs.LETS_PARK_IT_FACEBOOK_URL,
  },
  {
    label:
      configs.LETS_PARK_IT_YOUTUBE_URL_TITLE ||
      configs.LETS_PARK_IT_YOUTUBE_URL,
    icon: 'icon-youtube',
    href: configs.LETS_PARK_IT_YOUTUBE_URL,
  },
];

const TopContacts = ({ classes }) => (
  <div className={classes.root}>
    {contacts.reduce(
      (acc, item) =>
        item.label
          ? [
            ...acc,
            <a
              key={item.label}
              href={item.href}
              className={classes.item}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className={classNames(item.icon, classes.itemIcon)} />
              <span className={classes.itemLabel}>{item.label}</span>
            </a>,
          ]
          : acc,
      [],
    )}
  </div>
);

TopContacts.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default injectSheet(styles)(TopContacts);
