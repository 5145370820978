import { THEME } from '../../../constants';

export default {
  label: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    cursor: 'pointer',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: { display: 'none' },
  addBtn: {
    width: 50,
    height: 50,
    display: 'flex',
    cursor: 'pointer',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px dashed ${THEME.colors.primary}`,
    '& [class^=icon-]': { color: THEME.colors.primary },
  },
};
