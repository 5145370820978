export default {
  root: {
    display: 'flex',
    paddingTop: 60,
    paddingBottom: 60,
    overflow: 'hidden',
    position: 'relative',
    backgroundSize: 'cover',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  title: {
    fontSize: 64,
    color: '#F7F8FB',
    lineHeight: '87px',
    marginBottom: 68,
  },
};
