import { THEME } from '../../constants';

const heightOfNavigation = 100;
const heightOfNavigationLg = 130;
const heightOfNavigationMd = 110;
const heightOfNavigationSm = 80;

export default {
  root: {
    height: 'auto',
    paddingLeft: THEME.paddingSide,
    paddingRight: THEME.paddingSide,
    minHeight: `calc(100vh - ${heightOfNavigation}px)`,

    [`@media (max-width: ${THEME.breakpoints.maxLg}px)`]: {
      minHeight: `calc(100vh - ${heightOfNavigationLg}px)`,
    },
    [`@media (max-width: ${THEME.breakpoints.maxMd}px)`]: {
      paddingLeft: THEME.paddingSideMobile,
      paddingRight: THEME.paddingSideMobile,
      minHeight: `calc(100vh - ${heightOfNavigationMd}px)`,
    },
    [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
      minHeight: `calc(100vh - ${heightOfNavigationSm}px)`,
    },
  },
};
