import { THEME } from '../../../constants';

export default {
  root: {
    padding: 0,
    margin: 0,
    listStyleType: 'none',
    flex: 1,
    maxwidth: 300,
  },
  itemIcon: {
    fontSize: 15,
    marginRight: 8,
    verticalAlign: 'middle',
  },
  item: {
    fontSize: 15,
    lineHeight: '20px',
    cursor: 'pointer',
    color: '#000',
    '&:first-child': {
      cursor: 'default',
      fontWeight: 'bold',
    },
  },
  smallMarginTop: {
    marginTop: 20,
  },
  itemActive: {
    color: THEME.colors.primary,
  },

  subMenuItems: {
    marginTop: 10,
    listStyleType: 'none',
    paddingLeft: 30,
  },

  [`@media (max-width: ${THEME.breakpoints.maxXl}px)`]: {
    root: { maxWidth: 300 },
  },
  [`@media (max-width: ${THEME.breakpoints.maxMd}px)`]: {
    root: { maxWidth: 180 },
  },
};
