import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';
import ReactClock from 'react-clock';

import clockImage from '../../assets/images/coming-soon/clock.svg';

import styles from './clock.styles';

const clockSettings = {
  size: 550,
  hourHandWidth: 5,
  hourHandLength: 75,
  minuteHandWidth: 5,
  minuteHandLength: 90,
  secondHandWidth: 0,
  renderHourMarks: false,
  renderMinuteMarks: false,
};

const Clock = ({ classes, className, ...rest }) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classNames(classes.root, className)}>
      <img alt="clock" src={clockImage} className={classes.image} />
      <ReactClock
        value={date}
        className={classes.clock}
        {...clockSettings}
        {...rest}
      />
    </div>
  );
};

Clock.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};
Clock.defaultProps = {
  className: null,
};
export default injectSheet(styles)(Clock);
