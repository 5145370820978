/* eslint-disable */
import * as L from 'leaflet';

export default new L.divIcon({
  className: 'custom-icon',
  html: `<svg version="1.1" id="Layer_1" width="25" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 385.4 471.8" style="enable-background:new 0 0 385.4 471.8;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#0E98D5;}
	.st1{fill:#FFFFFF;}
</style>
<g id="Layer_10">
	<path class="st0" d="M188.7,50.4h1.3c41.2,0.5,83,19.4,111.6,50.6c24.9,27,37.3,60.5,34.9,94.2c-2.7,38.1-24.4,78.9-64.5,121.3
		c-16,16.9-33.1,32.6-51.3,49.2c-12.5,11.5-25.3,23.2-37.8,35.4c-10.4-11.8-21.1-23.2-31.5-34.4c-15.8-17-30.7-33-44.6-49.9
		C71.2,273.4,52.2,234.5,49,197.7c-2.3-24.9,3.7-51.7,16.7-75.4S98.4,78.8,121,66.7c19.3-10.3,43.8-16.3,67.2-16.3L188.7,50.4
		 M188.2,10.4c-29,0-60.1,7.1-86.1,21c-60.4,32.4-99,101.9-93,169.8c4.7,52.6,33.3,100.2,66.9,141s73,77,105,119.1
		c36.7-42.3,81.6-76.7,120.2-117.4s71.5-90.4,75.3-146c7.1-102.7-86.3-186.3-186-187.6L188.2,10.4L188.2,10.4z"/>
	<path class="st1" d="M190,50.5h-1.3h-0.5c-23.4,0-47.9,6-67.2,16.3c-22.6,12.1-42.3,31.9-55.3,55.6s-19,50.5-16.7,75.4
		c3.2,36.8,22.2,75.7,57.8,119.1c13.9,16.9,28.8,32.9,44.6,49.9c10.4,11.2,21.1,22.6,31.5,34.4c12.5-12.2,25.3-23.9,37.8-35.4
		c18.2-16.6,35.3-32.3,51.3-49.2c40.1-42.4,61.8-83.2,64.5-121.3c2.4-33.7-10-67.2-34.9-94.2C273,69.9,231.2,51,190,50.5z"/>
	<path class="st0" d="M191.2,108.1h-3.1c-1,0-2.1,0.1-3.1,0.4c-1.1,0-2.2,0.1-3.3,0.2c-1,0.1-2.1,0.3-3.3,0.5
		c-2.2,0.2-4.4,0.6-6.5,1.1c-2.2,0.5-4.4,1.1-6.5,1.8c-8,2.4-15.6,6-22.6,10.5c-7.5,4.9-14.4,10.7-20.4,17.3
		c-6.6,7.2-11.6,15.8-14.7,25.1c-3.2,9.8-4.9,20.9-4.9,33.4v165.2h31.4V205c-0.1-7.6,0.8-15.1,2.7-22.4c1.6-6,4.3-11.7,8.1-16.6
		c3.4-4.5,7.2-8.7,11.4-12.5c5.3-4.8,11.5-8.5,18.3-11c1.9-0.7,3.8-1.2,5.8-1.4c1.8-0.5,3.6-0.8,5.4-1.1c1.7-0.2,3.3-0.4,5-0.4
		c6.9,0,13.8,1.4,20.2,4c6.9,2.9,13.2,6.9,18.8,11.9c5.9,5.2,10.6,11.7,13.6,19c3.1,7.9,4.6,16.3,4.5,24.8
		c0.1,7.9-1.1,15.8-3.4,23.3c-2.2,6.9-5.5,13.4-9.9,19.2c-4.3,5.8-10.1,10.2-16.8,12.8c-6.9,2.8-14.9,4.2-24,4.2h-4.7
		c-1.3,0-2.7-0.1-4-0.4c-1.4,0-2.8-0.1-4.1-0.4c-1.3-0.2-2.6-0.5-3.8-0.7c-2.2-0.5-4.4-1.1-6.7-1.8s-4.6-1.6-7-2.5
		c-2.4-1-5.1-2.2-8.1-3.6s-6.3-3.3-9.9-5.4v34.7c5.1,2.7,10.3,5,15.7,6.9c4.9,1.7,9.3,3,13.2,4c4.1,1,7.9,1.7,11.6,2.2
		c3.6,0.5,7.2,0.7,10.8,0.7c10.8-0.7,21.5-2.9,31.8-6.5c8.5-3,16.3-7.8,22.8-14.1c5.9-5.6,11.1-12,15.5-18.9
		c4-6.3,7.3-13.1,9.8-20.2c1-2.9,1.8-5.8,2.5-8.7c0.7-2.8,1.2-5.8,1.5-8.7c0.5-2.9,0.8-5.7,1.1-8.5c0.2-2.8,0.4-5.4,0.4-7.8
		s-0.1-4.9-0.4-7.4s-0.6-5.2-1.1-8.1c-0.2-2.9-0.7-5.8-1.5-8.7c-0.7-2.9-1.6-5.8-2.5-8.7c-2.5-7.4-6.1-14.4-10.7-20.8
		c-4.9-6.9-10.6-13.3-16.8-19c-7-6.4-15.4-11.2-24.6-13.9C215.2,109.6,204,108.1,191.2,108.1L191.2,108.1z"/>
</g>
</svg>
  `,
});
