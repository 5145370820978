import { THEME } from '../../../../constants';

export default {
  root: {
    padding: [38, 105],
    position: 'relative',
    backgroundColor: 'rgba(14, 152, 213, 0.19)',
  },
  content: {
    display: 'flex',
    height: 49,
  },
  borderBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FEFEFE',
    borderRadius: 4,
    border: `1px solid ${THEME.colors.primary}`,
  },
  searchBox: {
    paddingLeft: 9,
    width: '100%',
  },
  locationIcon: { color: '#979EA4' },
  inputWrapper: {
    width: '100%',
    position: 'relative',
  },
  input: {
    fontSize: 12,
    width: '100%',
    border: 'none',
    height: '100%',
    padding: [0, 7],
    color: '#21303E',
    lineHeight: '16px',
    '&:focus': { outline: 'none' },
    '&::placeholder': { color: '#979EA4' },
  },
  suggestionsWrapper: {
    top: 36,
    padding: 10,
    width: '100%',
    maxHeight: 150,
    overflow: 'auto',
    position: 'absolute',
    backgroundColor: '#FEFEFE',
    '&.loading': {
      display: 'flex',
      justifyContent: 'center',
      color: THEME.colors.primary,
    },
  },
  suggestion: {
    fontSize: 12,
    color: '#21303E',
    lineHeight: '16px',
    margin: 0,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(14, 152, 213, 0.19)',
    },
    '& + $suggestion': { marginTop: 8 },
  },
  button: {
    width: 51,
    padding: 0,
    border: 'none',
    height: '100%',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    borderLeft: `1px solid ${THEME.colors.primary}`,
    '&:focus': { outline: 'none' },
    '& [class^=icon-]': { color: THEME.colors.primary },
    '&.no-left-border': {
      borderLeft: 'none',
    },
  },

  delimiter: {
    borderLeft: `2px solid ${THEME.colors.primary}`,
    height: '80%',
    marginLeft: '5px',
    marginRight: '5px',
    alignSelf: 'center',
  },

  [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
    root: { padding: 10 },
  },
};
