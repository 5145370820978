import { toSnakeCase } from './stringUtils';

const dataLayer = window.dataLayer || [];
function gtag() {
  // eslint-disable-next-line prefer-rest-params
  dataLayer.push(arguments);
}

let isAvailable = false;

const gaExcludedParams = Object.freeze([
  'allow_ad_personalization_signals',
  'allow_enhanced_conversions',
  'allow_google_signals',
  'items',
  'restricted_data_processing',
  'cookie_domain',
  'cookie_expires',
  'cookie_flags',
  'cookie_name',
  'cookie_path',
  'cookie_prefix',
  'cookie_update',
  'custom_map',
  'dynamic_event_settings',
  'event_callback',
  'event_settings',
  'event_timeout',
  'ga_restrict_domain',
  'google_signals',
  'google_tld',
  'groups',
  'internal_traffic_results',
  'linker',
  'referral_exclusion_definition',
  'send_to',
  'send_page_view',
  'session_duration',
  'transport_url',
  'update',
  'user_properties',
  'delivery_postal_code',
  '_eu',
]);

const resolveParamKey = (key, gaEvent) => {
  if (gaExcludedParams.includes(key)) {
    // eslint-disable-next-line no-console
    console.warn(
      `Warning: The sending custom event "${gaEvent}" has param: "${key}"
      which will be omitted by Google Analytics.
      Please consider using another param name.`,
    );
  }
  return toSnakeCase(key);
};

const dimensionObjectToString = value => {
  if (Array.isArray(value)) {
    return value.join('; ');
  }

  if (typeof value === 'object') {
    if (value === null) {
      return null;
    }
    // detect date object
    if (Object.prototype.toString.call(value) === '[object Date]') {
      if (!Number.isNaN(value.getTime())) {
        return value.toString();
      }
      // filter out invalid date
      return null;
    }
    // send stringified normal object
    return `{${Object.keys(value).reduce((str, k) => {
      const val = this.dimensionObjectToString(value[k]);
      if (val !== null) {
        return `${str} ${k}: ${val};`;
      }
      return str;
    }, '')}}`;
  }
  // send supported value types
  if (['boolean', 'number', 'string'].includes(typeof value)) {
    return value.toString();
  }
  // filter out unsupported types
  return null;
};

const namingContractResolver = (obj, gaEvent) => {
  if (!obj) {
    return obj;
  }

  return Object.keys(obj).reduce(
    (o, k) => ({
      ...o,
      [resolveParamKey(k, gaEvent)]: dimensionObjectToString(obj[k]),
    }),
    {},
  );
};

/**
 * Initialize GA 4.
 * @param {string} measurementID GA Measurement Id.
 * @param {HashObject<any>} config GA Configs.
 */
const initialize = (measurementID, config) => {
  if (!measurementID || !gtag) {
    // eslint-disable-next-line no-console
    console.error('Please config google analytics measurement ID');
    return;
  }

  isAvailable = true;

  gtag('js', new Date());
  gtag('config', measurementID, config || {});
};

/**
 * Send information to GA server.
 * @param {string} gaEvent GA event name.
 * @param {HashObject<any>} params Parameters.
 */
const dispatch = (gaEvent, params) => {
  if (!isAvailable) {
    return;
  }

  gtag('event', gaEvent, namingContractResolver(params, gaEvent));
};

export default {
  initialize,
  dispatch,
};
