import { THEME } from '../../constants';

export default {
  root: { position: 'relative' },
  coverBackground: {
    width: '100%',
  },
  text: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -60%)',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 72,
    lineHeight: '98px',
    textAlign: 'center',
    color: '#fff',
  },

  [`@media (max-width: ${THEME.breakpoints.maxXl}px)`]: {
    text: { fontSize: 65 },
  },
  [`@media (max-width: ${THEME.breakpoints.maxLg}px)`]: {
    text: { fontSize: 50 },
  },
  [`@media (max-width: ${THEME.breakpoints.maxMd}px)`]: {
    text: { fontSize: 25, transform: 'translate(-50%, -53%)' },
  },
  [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
    text: { fontSize: 20 },
  },
};
