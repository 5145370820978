import { createSelector } from 'reselect';

import { getLocale } from '../ducks/locale.duck';
import objectTranslator from './object-translator';

function createTranslationSelector(...selectors) {
  const dataSelector = createSelector(...selectors);

  return createSelector(
    dataSelector,
    getLocale,
    (data, locale) => objectTranslator(data, locale),
  );
}

export default createTranslationSelector;
