import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import styles from './imageFrame.styles';

const ImageFrame = ({
  className,
  classes,
  children,
  onIconClicked,
  clickableIcon,
}) =>
  children && (
    <div className={classNames(classes.root, className, clickableIcon)}>
      <div className={classNames(classes.wrapper)}>{children}</div>
      {clickableIcon && (
        <button
          className={classNames(classes.removeBtn, 'naked-button')}
          onClick={onIconClicked}
        >
          <i className="icon-close" />
        </button>
      )}
    </div>
  );

ImageFrame.defaultProps = {
  className: '',
  onIconClicked: () => {},
  clickableIcon: false,
  children: null,
};

ImageFrame.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  clickableIcon: PropTypes.bool,
  onIconClicked: PropTypes.func,
  children: PropTypes.node,
};

export default injectSheet(styles)(ImageFrame);
