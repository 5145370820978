import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import styles from './item.styles';

const Item = ({
  className,
  classes,
  item,
  iconClassName,
  onIconClicked,
  onItemClicked,
}) => (
  <>
    {iconClassName && (
      <i
        role="presentation"
        className={classNames(className, iconClassName, classes.itemIcon)}
        onClick={() => onIconClicked(item)}
      />
    )}
    <span
      role="presentation"
      className={classNames(
        className,
        classes.item,
        !iconClassName && classes.noIcon,
      )}
      onClick={() => onItemClicked(item)}
    >
      {item.name}
    </span>
  </>
);

Item.defaultProps = {
  className: undefined,
  iconClassName: undefined,
  onIconClicked: () => {},
  onItemClicked: () => {},
};
Item.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string,
  }).isRequired,
  iconClassName: PropTypes.string,
  onIconClicked: PropTypes.func,
  onItemClicked: PropTypes.func,
};

export default injectSheet(styles)(Item);
