export default {
  root: {},
  inputWrapper: {
    height: 40,
    width: '100%',
    borderRadius: 4,
    display: 'flex',
    padding: [0, 12],
    overflow: 'hidden',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#FEFEFE',
  },
  label: {
    fontSize: 13,
    color: '#3E3E3E',
    fontWeight: 'bold',
    lineHeight: '18px',
    whiteSpace: 'nowrap',
    margin: [0, 10, 0, 0],
    '& ~ $placeholder': { left: 'inherit', right: 18 },
  },
  input: {
    minWidth: 1, // Fix Firefox flexbox not working properly
    fontSize: 13,
    width: '100%',
    height: '100%',
    border: 'none',
    color: '#3E3E3E',
    textAlign: 'right',
    '&:focus': { outline: 'none' },
    '&[type="number"]': {
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    '&:required': { boxShadow: 'none' }, // Fix Firefox red border
    '&:invalid': { boxShadow: 'none' }, // Fix Firefox red border
  },
  placeholder: {
    left: 12,
    top: '50%',
    fontSize: 12,
    color: '#979EA4',
    lineHeight: '16px',
    position: 'absolute',
    pointerEvents: 'none',
    transform: 'translateY(-50%)',
  },
};
