import { THEME } from '../../../constants';

export default {
  root: { minHeight: 400 },
  title: {
    fontWeight: 'bold',
    fontSize: 32,
    lineHeight: '44px',
    color: '#141E26',
  },
  content: {
    fontSize: 15,
    lineHeight: '20px',
    color: '#000',
  },

  [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
    title: { fontSize: 20, lineHeight: '24px' },
  },
};
