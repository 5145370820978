export default {
  root: { position: 'relative' },
  nextSectionBtn: {
    zIndex: 1,
    bottom: 20,
    left: '50%',
    textAlign: 'center',
    position: 'absolute',
    transform: 'translateX(-50%)',
  },
  nextSectionIcons: {
    marginBottom: 5,
    cursor: 'pointer',
    display: 'inline-flex',
    flexDirection: 'column',
    '& [class^=icon]': {
      color: '#A6A6A6',
      '&:last-child': {
        marginTop: -7,
        color: '#BFBDBD',
      },
    },
  },
  nextSectionText: {
    margin: 0,
    fontSize: 12,
    color: '#A3A3A3',
    cursor: 'pointer',
    lineHeight: '16px',
  },
};
