import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import retangleImage from '../../assets/images/retangle.svg';

import styles from './sectionTitle.styles';

const SectionTitle = ({ classes, children }) => (
  <div className={classes.root}>
    <img
      alt="background"
      src={retangleImage}
      className={classes.coverBackground}
    />
    <span className={classes.text}>{children}</span>
  </div>
);

SectionTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default injectSheet(styles)(SectionTitle);
