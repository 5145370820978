import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const Translate = ({ intl, id, custom, html }) => {
  if (html) {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: intl.formatHTMLMessage(custom || { id }),
        }}
      />
    );
  }

  return intl.formatMessage(custom || { id });
};

Translate.defaultProps = {
  custom: null,
  html: false,
};
Translate.propTypes = {
  id: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  custom: PropTypes.object,
  html: PropTypes.bool,
};
export default injectIntl(Translate);
