import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';

import Errors from '../../../../components/Input/components/Errors';
import Input from '../../../../components/Input';
import BaseForm from '../../../../components/Form';
import Translate from '../../../../components/Translate';
import { isEmail } from '../../../../utils';
import { getCountryCodeByLocale } from '../../../../ducks/locale.duck';
import {
  contactSubmittingSelector,
  submitContact,
  contactSubmittedSelector,
} from '../../contact.duck';

import styles from './contactForm.styles';

const initialValues = {
  name: '',
  email: '',
  phoneNumber: '',
  message: '',
};

const ContactForm = ({ intl, classes, className }) => {
  const dispatch = useDispatch();
  const countryCode = useSelector(getCountryCodeByLocale);
  const isLoading = useSelector(contactSubmittingSelector);
  const isSubmitted = useSelector(contactSubmittedSelector);

  const methods = useForm({
    criteriaMode: 'all',
    defaultValues: { ...initialValues },
  });
  const { register, errors, reset } = methods;

  useEffect(() => {
    if (isSubmitted) {
      reset({ ...initialValues });
    }
  }, [isSubmitted]);

  const onSubmit = data => dispatch(submitContact(data));

  return (
    <div className={classNames(classes.root, className)}>
      <h2 className={classes.title}>
        <Translate id="HOME.CONTACT.FORM.DESCRIPTION" />
      </h2>
      <p className={classes.subTitle}>
        <Translate id="CONTACT.HOME.FORM.SUBTITLE" />
      </p>
      <BaseForm
        methods={methods}
        className={classes.content}
        onSubmit={onSubmit}
      >
        <div className={classes.inputWrapper}>
          <input
            name="name"
            className={classes.input}
            placeholder={intl.formatMessage({ id: 'HOME.CONTACT.FORM.NAME' })}
            ref={register({
              required: 'FORM.ERROR.CONTACT_FORM.NAME.REQUIRED',
            })}
          />
          <Errors
            name="name"
            errors={errors}
            showMultipleError
            className={classes.errorInput}
          />
        </div>

        <div className={classes.inputWrapper}>
          <input
            data-private
            name="email"
            className={classes.input}
            type="email"
            placeholder={intl.formatMessage({ id: 'HOME.CONTACT.FORM.EMAIL' })}
            ref={register({
              required: 'FORM.ERROR.CONTACT_FORM.EMAIL.REQUIRED',
              validate: {
                email: value => {
                  if (value && !isEmail(value)) {
                    return 'FORM.ERROR.CONTACT_FORM.EMAIL.INVALID';
                  }

                  return null;
                },
              },
            })}
          />
          <Errors
            name="email"
            errors={errors}
            showMultipleError
            className={classes.errorInput}
          />
        </div>

        <div className={classes.inputWrapper}>
          <Input
            data-private
            className={classes.phoneNumber}
            type="phoneNumber"
            name="phoneNumber"
            defaultCountry={countryCode}
            placeholder={intl.formatMessage({
              id: 'HOME.CONTACT.FORM.PHONE_NUMBER',
            })}
            rules={{
              required: 'FORM.ERROR.CONTACT_FORM.PHONE_NUMBER.REQUIRED',
              validate: {
                invalidPhoneNumber: value => {
                  if (value && !isValidPhoneNumber(value)) {
                    return 'FORM.ERROR.CONTACT_FORM.PHONE_NUMBER.INVALID';
                  }

                  return null;
                },
              },
            }}
          />
        </div>

        <div className={classes.textareaWrapper}>
          <textarea
            name="message"
            className={classes.textarea}
            placeholder={intl.formatMessage({
              id: 'HOME.CONTACT.FORM.MESSAGE',
            })}
            ref={register({
              required: 'FORM.ERROR.CONTACT_FORM.MESSAGE.REQUIRED',
            })}
          />
          <Errors
            name="message"
            errors={errors}
            showMultipleError
            className={classes.errorInput}
          />
        </div>

        <button className={classes.button} type="submit" disabled={isLoading}>
          <Translate id="HOME.CONTACT.FORM.BUTTON.SUBMIT" />
          {isLoading && <i className="icon-loading rotating" />}
        </button>
      </BaseForm>
    </div>
  );
};

ContactForm.defaultProps = {
  className: '',
};
ContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(injectSheet(styles)(ContactForm));
