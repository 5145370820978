import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import { generateId } from '../../../../utils';

import styles from './inputFile.styles';

const InputFile = ({
  classes,
  className,
  onChange,
  chilren,
  value,
  ...rest
}) => {
  const [srcPreview, setSrcPreview] = useState(value);
  const id = generateId();
  const ref = useRef();

  const handleInputChange = event => {
    const { target: { files } } = event;
    const e = { ...event };
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        setSrcPreview(reader.result);
        onChange(e, reader.result);
      };
      reader.readAsDataURL(files[0]);
      return;
    }
    onChange(event);
  };

  useEffect(() => {
    if (!value) {
      setSrcPreview('');
      ref.current.value = '';
    }
  }, [value]);

  return (
    <div
      className={classNames(classes.root, className)}
      style={{ backgroundImage: `url(${srcPreview})` }}
    >
      <label className={classes.label} htmlFor={id}>
        <input
          ref={ref}
          {...rest}
          id={id}
          type="file"
          onChange={handleInputChange}
          className={classes.input}
        />
        {!srcPreview && (
          <span className={classes.addBtn}>
            <i className="icon-plus" />
          </span>
        )}
      </label>
      {chilren}
    </div>
  );
};

InputFile.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  chilren: PropTypes.node,
  value: PropTypes.string,
};
InputFile.defaultProps = {
  className: '',
  onChange: () => {},
  chilren: null,
  value: '',
};

export default injectSheet(styles)(InputFile);
