import { THEME } from '../../../constants';

export default {
  root: {
    display: 'flex',
  },
  recentView: { width: '50%', paddingRight: 15 },
  relatedView: { width: '50%' },
  item: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
    color: '#000',
    cursor: 'pointer',
    '&:first-child': {
      cursor: 'default',
      fontWeight: 'bold',
    },
    '& + $item': { marginTop: 15 },
    '&.active': { color: THEME.colors.primary },
  },
};
