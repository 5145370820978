import { THEME } from '../../../constants';

export default {
  root: {
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
  },
  retangleImage: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  contactImage: {
    position: 'absolute',
    bottom: -60,
    left: 0,
    zIndex: 2,
    width: '100%',
  },
  titleWrapper: {
    paddingTop: 56,
    position: 'relative',
    zIndex: 3,
    maxWidth: 450,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 36,
    lineHeight: '49px',
    color: '#2A9FD3',
    marginBottom: 7,
    textTransform: 'uppercase',
  },
  description: {
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: '25px',
    color: '#16212C',
  },
  formWrapper: {
    maxWidth: 390,
    position: 'relative',
    zIndex: 3,
    width: '100%',
    minHeight: 'inherit',
  },
  formLabel: {
    position: 'absolute',
    left: -26,
    top: '50%',
    transform: ' translate(-50%, -50%) rotate(-90deg)',
    background: '#0E98D5',
    borderRadius: [9, 9, 0, 0],
    textTransform: 'uppercase',
    height: 55,
    width: '100vw',
    maxWidth: 528,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '25px',
    color: '#fff',
  },
  form: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
  },

  [`@media (min-width: ${THEME.breakpoints.minXXl}px)`]: {
    contactImage: { width: '100%', maxWidth: 1200 },
  },
  [`@media (max-width: ${THEME.breakpoints.maxXl}px)`]: {
    titleWrapper: { maxWidth: 265 },
    formWrapper: { zoom: 0.8 },
  },
  [`@media (max-width: ${THEME.breakpoints.maxMd}px)`]: {
    root: {
      display: 'block',
      paddingTop: 25,
      paddingBottom: 25,
    },
    titleWrapper: { maxWidth: '100%', paddingTop: 0 },
    title: { fontSize: 23 },
    formWrapper: { zoom: 1, marginLeft: 'auto' },
    formLabel: { display: 'none' },
    form: { position: 'relative', transform: 'initial' },
  },
  completeSubmissionCustom: {
    height: '70%',
    composes: ['$form'],
  },
};
