import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import styles from './simpleList.styles';

const SimpleList = ({ className, classes, items, valueKey, itemClicked }) => (
  <ol className={classNames(classes.root, className)}>
    {items.map(item => (
      <li
        key={item.id}
        className={classes.item}
        role="presentation"
        onClick={() => itemClicked(item)}
      >
        {item[valueKey]}
      </li>
    ))}
  </ol>
);

SimpleList.defaultProps = {
  className: '',
  itemClicked: () => {},
};

SimpleList.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  valueKey: PropTypes.string.isRequired,
  itemClicked: PropTypes.func,
};

export default injectSheet(styles)(SimpleList);
