import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'leaflet/dist/leaflet.css';
import 'react-phone-number-input/style.css';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import 'react-image-lightbox/style.css';
import { Provider, ReactReduxContext } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import App from './App';
import './assets/font-icons/style.css';
import { LOG_ROCKET_APP, LOG_ROCKET_ID } from './configs';
import NotificationManager from './containers/NotificationManager';
import history from './history';
import './index.css';
import IntlProvider from './IntProvider';
import GoogleAnalyticsProvider from './GoogleAnalyticsProvider';
import * as serviceWorker from './serviceWorker';
import store from './store';

LogRocket.init(`${LOG_ROCKET_ID}/${LOG_ROCKET_APP}`);
setupLogRocketReact(LogRocket);

LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
  name: 'James Morrison',
  email: 'jamesmorrison@example.com',

  // Add your own custom user variables here, ie:
  subscriptionType: 'pro',
});
ReactDOM.render(
  <Provider store={store} context={ReactReduxContext}>
    <GoogleAnalyticsProvider>
      <IntlProvider>
        <ToastProvider>
          <NotificationManager />
          <Router history={history}>
            <App />
          </Router>
        </ToastProvider>
      </IntlProvider>
    </GoogleAnalyticsProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
