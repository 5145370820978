import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import styles from './carousel.styles';

const Carousel = ({
  classes, children, className, ...rest
}) => {
  const defaultSettings = {
    dots: true,
    speed: 500,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    autoplaySpeed: 5000,
    dotsClass: classes.dotsClass,
  };

  return (
    <Slider
      {...defaultSettings}
      {...rest}
      className={classNames(classes.root, className)}
    >
      {children}
    </Slider>
  );
};

Carousel.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  className: PropTypes.object,
};
Carousel.defaultProps = {
  className: null,
};
export default injectSheet(styles)(Carousel);
