export default {
  'APP.NAVBAR.WHAT_LETSPARKIT': 'What is Lets Park It?',
  'APP.NAVBAR.BENEFITS': 'Benefits of joining?',
  'APP.NAVBAR.ARE_YOU_READY': 'Are you ready?',
  'APP.NAVBAR.NEWS': 'News',
  'APP.NAVBAR.QA': 'FAQ',
  'APP.NAVBAR.TUTORIAL_AND_SUPPORT': 'Tutorial and Support',
  'APP.NAVBAR.CONTACT': 'Contact',
  'HOME.INTRODUCE.SCROLL_DOWN': 'SCROLL DOWN TO FIND MORE',
  'HOME.INTRODUCE.SLIDER_ONE.TITLE': 'Make money even when you are resting?',
  'HOME.INTRODUCE.SLIDER_ONE.DESCRIPTION.ONE':
    'With a smart phone, a minimum space of 3x5.5m and cost almost equal to <b>0</b>, all you need to do is rest, relax and check your account when the money is in',
  'HOME.INTRODUCE.SLIDER_ONE.DESCRIPTION.TWO':
    "<i>Don't waste the auto monetization space in your home and start early retirement</i>",
  'HOME.INTRODUCE.SLIDER_ONE.BUTTON': 'RIGHT NOW',
  'HOME.MAP.BANNER.TITLE.ONE': 'NOW WE HAVE',
  'HOME.MAP.BANNER.TITLE.TWO':
    'PEOPLE HAVE SIGNED UP FOR RENTING AND ARE CONSTANTLY INCREASING',
  'HOME.MAP.BANNER.BUTTON': 'REGISTER NOW',
  'HOME.MAP.BANNER.DESCRIPTION.ONE':
    'And you will be on the search priority list.',
  'HOME.MAP.BANNER.DESCRIPTION.TWO':
    "The chance to own first-class privilege. <b>Don't miss out!</b>",
  'HOME.MAP.FORM.EMAIL': 'Email',
  'HOME.MAP.FORM.CAPACITY': 'Capacity',
  'HOME.MAP.FORM.BLANK': 'Blank',
  'HOME.MAP.FORM.SIZE': 'Size',
  'HOME.MAP.FORM.SIZE.WIDTH': 'Width',
  'HOME.MAP.FORM.SIZE.HEIGHT': 'Height',
  'HOME.MAP.FORM.WEIGHT': 'Weight',
  'HOME.MAP.FORM.FROM_DATE': 'From date',
  'HOME.MAP.FORM.TO_DATE': 'To date',
  'HOME.MAP.FORM.DESCRIPTION': 'Description',
  'HOME.MAP.SEARCH.PLACEHOLDER':
    'House number, road, town, ward, district, city',
  'HOME.MAP.FORM.COMPLETE_SUBMISSION.MESSAGE': 'Thank you for your submission',
  'HOME.MAP.FORM.COMPLETE_SUBMISSION.BUTTON_TEXT': 'Done',
  'HOME.MAP.SEARCH.BUTTON.SEARCH': 'Search',
  'HOME.MAP.SEARCH.BUTTON.GEOLOCATION': 'Current address',
  'HOME.MAP.SEARCH.BUTTON.SHOWING_ALL': "Show all markers",
  'HOME.MAP.SEARCH.BUTTON.SHOWING_MINE': "Show my markers only",
  'HOME.BENEFITS.TITLE': 'SMART CAR PARKING RENTAL APPLICATION',
  'HOME.BENEFITS.DESCRIPTION.PARK':
    'LET&apos;S PARK IT is a smart <b>car parking rental</b> application that meets strict criteria of user experience, security, reliability and speed as well as the process is simple and fast.',
  'HOME.BENEFITS.DESCRIPTION.LOCATION.ONE':
    'No more wasting time to managing parking or worrying about monthly income. All you need to do is',
  'HOME.BENEFITS.DESCRIPTION.LOCATION.TWO': 'post a rental',
  'HOME.BENEFITS.DESCRIPTION.LOCATION.THREE':
    'and money will flow into your pocket.',
  'HOME.BENEFITS.DESCRIPTION.CARD':
    '<i>Easily get stable passive income with zero commissions</i>',
  'HOME.COMING_SOON.TEXT.ONE': 'Smart parking application',
  'HOME.COMING_SOON.TEXT.TWO': 'Coming soon',
  'HOME.COMING_SOON.TEXT.THREE': 'Waiting together!',
  'HOME.CONTACT.TITLE': 'CONTACT US',
  'HOME.CONTACT.SUB_TITLE':
    'Please leave us a message about your issues. We will contact you as soon as possible.',
  'HOME.CONTACT.FORM.TITLE': 'CONTACT INFORMATION',
  'HOME.CONTACT.FORM.DESCRIPTION': 'Leave a message for us',
  'HOME.CONTACT.FORM.NAME': 'Full name',
  'HOME.CONTACT.FORM.EMAIL': 'Email',
  'HOME.CONTACT.FORM.PHONE_NUMBER': 'Phone number',
  'HOME.CONTACT.FORM.MESSAGE': 'Message',
  'HOME.CONTACT.FORM.BUTTON.SUBMIT': 'Submit',
  'HOME.LETSPARKIT.TITLE': 'Financial freedom with <br /><b>letsparkit</b>',
  'HOME.LETSPARKIT.BUTTON': 'RIGHT NOW',
  'CONTACT.HOME.FORM.SUBTITLE':
    'Please feel free to contact us if you have any questions!',
  'CONTACT.HOME.TITLE': 'Contact Us',
  'CONTACT.HOME.SUB_TITLE':
    'Talk to business together or just drink coffee together.<br />We would love to hear from you.',
  'CONTACT.HOME.ADDRESS.LABEL': 'ADDRESS',
  'CONTACT.HOME.ADDRESS.CONTENT': '212 Nguyễn Đình Chiểu, Ward 6, District 3, HCM City',
  'CONTACT.HOME.PHONE_NUMBER.LABEL': 'PHONE NUMBER',
  'CONTACT.HOME.EMAIL.LABEL': 'EMAIL',
  'SUPPORT.TITLE': 'TUTORIAL & SUPPORT',
  'CONTACT.HOME.COMPLETE_SUBMISSION.MESSAGE': 'Thank you for your submission',
  'CONTACT.HOME.COMPLETE_SUBMISSION.BUTTON_TEXT': 'Done',
  'SUPPORT.DESCRIPTION':
    'If you have any questions or need support, please contact us at the link: <a href="http://letsparkit.com/lienhe" target="_blank">http://letsparkit.com/lienhe</a> for help',
  'FAQ.TITLE': 'FREQUENTLY ASKED QUESTIONS',
  'FAQ.LEFT_MENU.LABEL': 'Articles in this section',
  'FAQ.USEFUL.HAVE_QUESTION':
    'Have more questions? <a href="/contact">Send request</a>',
  'FAQ.RECENT_POSTS.TITLE': 'Recently viewed article',
  'FAQ.RELATED_POSTS.TITLE': 'Related articles',
  'APP.FOOTER.WHAT_LETSPARKIT': 'What is Lets Park It?',
  'APP.FOOTER.BENEFITS': 'Benefits of joining?',
  'APP.FOOTER.ARE_YOU_READY': 'Are you ready?',
  'APP.FOOTER.NEWS': 'News',
  'APP.FOOTER.QA': 'Frequently asked questions',
  'APP.FOOTER.TUTORIAL_AND_SUPPORT': 'Tutorial and support',
  'APP.FOOTER.FORM.PLACEHOLDER': 'Enter your email and receive new information',
  'API.REQUEST.SUCCESS': 'Success',
  'API.REQUEST.FAILED': 'An error has occurred',

  'FORM.ERROR.NO_VALUES': 'Please enter your information',
  'FORM.ERROR.EMAIL.REQUIRED': 'Please enter your email',
  'FORM.ERROR.EMAIL.INVALID': 'Email is invalid',
  'FORM.VALIDATION.FAILED': 'Invalid information',
  'FORM.ERROR.ADDRESS_REQUIRED': 'Please select a location',
  'FORM.ERROR.CAPACITY.REQUIRED': 'Please enter the capacity',
  'FORM.ERROR.CAPACITY.MUST_BE_POSITIVE_INTEGER':
    'Capacity must be an positive integer',
  'FORM.ERROR.BLANK.REQUIRED': 'Please enter the number of blanks you have',
  'FORM.ERROR.BLANK.MUST_BE_POSITIVE_INTEGER':
    'Blank must be an positive integer',
  'FORM.ERROR.WIDTH.REQUIRED': "Please enter the your parking's width",
  'FORM.ERROR.WIDTH.MUST_BE_POSITIVE_NUMBER':
    "Parking's width must be a positive number",
  'FORM.ERROR.HEIGHT.REQUIRED': "Please enter the your parking's height",
  'FORM.ERROR.HEIGHT.MUST_BE_POSITIVE_NUMBER':
    "Parking's height must be a positive number",
  'FORM.ERROR.WEIGHT.REQUIRED': "Please enter the your parking's weight",
  'FORM.ERROR.WEIGHT.MUST_BE_POSITIVE_NUMBER':
    "Parking's weight must be a positive number",
  'FORM.ERROR.FROM_DATE.REQUIRED': 'Please set the date your parking is free',
  'FORM.ERROR.FROM_DATE.MUST_LARGER_THAN_TO_DATE':
    'Start date must be less than end date',
  'FORM.ERROR.TO_DATE.REQUIRED':
    'Please set the date your parking is unavailable',

  'FORM.ERROR.CONTACT_FORM.NAME.REQUIRED': 'Please enter you name',
  'FORM.ERROR.CONTACT_FORM.EMAIL.REQUIRED': 'Please enter your email',
  'FORM.ERROR.CONTACT_FORM.EMAIL.INVALID': 'Email is invalid',
  'FORM.ERROR.CONTACT_FORM.PHONE_NUMBER.REQUIRED':
    'Please enter your phone number',
  'FORM.ERROR.CONTACT_FORM.PHONE_NUMBER.INVALID': 'Invalid phone number',
  'FORM.ERROR.CONTACT_FORM.MESSAGE.REQUIRED': 'Please enter your message',

  'ZALO.MESSAGE.WELCOME_TEXT': "It's our pleasure to serve you",
};
