import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import SectionLayout from '../../../components/SectionLayout';
import iPhoneImage from '../../../assets/images/coming-soon/iPhoneX.svg';
import Clock from '../../../components/Clock';
import Translate from '../../../components/Translate';

import styles from './comingSoon.styles';

const clockSettings = {
  size: 300,
};

const ComingSoon = ({ classes }) => (
  <SectionLayout id="comming-soon" className={classes.root}>
    <div className={classes.clockWrapper}>
      <Clock className={classes.clock} {...clockSettings} />
      <div className={classes.content}>
        <p className={classes.smallText}>
          <Translate id="HOME.COMING_SOON.TEXT.ONE" />
        </p>
        <h1 className={classes.title}>
          <Translate id="HOME.COMING_SOON.TEXT.TWO" />
        </h1>
        <p className={classes.smallText}>
          <Translate id="HOME.COMING_SOON.TEXT.THREE" />
        </p>
      </div>
    </div>
    <div className={classes.iphoneImage}>
      <img src={iPhoneImage} alt="iPhoneX" />
    </div>
  </SectionLayout>
);

ComingSoon.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(ComingSoon);
