import { THEME } from '../../../../constants';

export default {
  root: {
    color: '#F7F8FB',
    textAlign: 'center',
    padding: [8, 10, 10],
    position: 'relative',
    backgroundColor: 'rgba(14, 152, 213, 0.8)',
  },
  title: {
    fontSize: 20,
    margin: [0, 0, 12],
    lineHeight: '27px',
  },
  count: {
    fontSize: 32,
    fontWeight: 'bold',
  },
  button: {
    height: 41,
    fontSize: 18,
    width: '100%',
    maxWidth: 223,
    border: 'none',
    borderRadius: 4,
    fontWeight: 'bold',
    lineHeight: '25px',
    backgroundColor: '#F7F8FB',
    color: THEME.colors.primary,
    '&:focus': { outline: 'none' },
  },
  description: {
    fontSize: 18,
    margin: [8, 0, 0],
    lineHeight: '25px',
    '& > span': { display: 'block' },
  },

  [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
    description: { '& > span': { display: 'inline' } },
  },
};
