import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

const initialState = {
  data: [],
  areCategoriesLoading: false,
  areCategoriesLoadingError: null,
};

const PREFIX = 'CATEGORIES';
export const CATEGORIES_FETCH = `${PREFIX}/FETCH`;
export const CATEGORIES_FETCH_SUCCESS = `${CATEGORIES_FETCH}/SUCCESS`;
export const CATEGORIES_FETCH_FAILED = `${CATEGORIES_FETCH}/FAILED`;

export const fetchCategories = createAction(CATEGORIES_FETCH);
export const fetchCategoriesSuccess = createAction(CATEGORIES_FETCH_SUCCESS);
export const fetchCategoriesFailed = createAction(CATEGORIES_FETCH_FAILED);

export const getCategoriesState = state => state.categories;
export const getCategories = createSelector(
  getCategoriesState,
  state => state.data,
);
export const getCategoriesGroupedBySlug = createSelector(
  getCategories,
  categories =>
    categories.reduce((acc, item) => ({ ...acc, [item.slug]: item }), {}),
);
export const getCategoriesGroupedById = createSelector(
  getCategories,
  categories =>
    categories.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}),
);
export const getCategoryBySlug = slug =>
  createSelector(
    getCategoriesGroupedBySlug,
    categories => categories[slug],
  );
export const getCategoryById = id =>
  createSelector(
    getCategoriesGroupedById,
    categories => categories[id],
  );

export default handleActions(
  new Map([
    [
      fetchCategories,
      state => ({
        ...state,
        areCategoriesLoading: true,
        areCategoriesLoadingError: null,
      }),
    ],
    [
      fetchCategoriesSuccess,
      (state, { payload: { categories } }) => ({
        ...state,
        data: categories,
        areCategoriesLoading: false,
        areCategoriesLoadingError: null,
      }),
    ],
    [
      fetchCategoriesFailed,
      (state, { payload: { error } }) => ({
        ...state,
        areCategoriesLoading: false,
        areCategoriesLoadingError: error,
      }),
    ],
  ]),
  { ...initialState },
);
