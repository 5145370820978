import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import { Controller, useFormContext } from 'react-hook-form';
import Errors from '../Errors';
import { generateId } from '../../../../utils';

import styles from './textarea.styles';

const Textarea = ({
  classes,
  className,
  label,
  children,
  onChange,
  value,
  rules,
  showSingleError,
  ...rest
}) => {
  const id = generateId();
  const { control, errors } = useFormContext() || {};

  const inputRenderer = () => {
    if (!control) {
      return (
        <textarea
          {...rest}
          id={id}
          className={classes.textarea}
          onChange={onChange}
        />
      );
    }

    return (
      <Controller
        name={rest.name}
        control={control}
        defaultValue={value}
        rules={rules}
        render={props => (
          <textarea
            {...rest}
            id={id}
            className={classes.textarea}
            onChange={e => {
              props.onChange(e);
              onChange(e);
            }}
          />
        )}
      />
    );
  };

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classNames(classes.inputWrapper)}>
        {label && (
          <label htmlFor={id} className={classes.label}>
            {label}
          </label>
        )}
        {inputRenderer()}
        {children}
      </div>
      <Errors
        errors={errors}
        name={rest.name}
        showMultipleError={showSingleError}
      />
    </div>
  );
};

Textarea.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func,
  label: PropTypes.node,
  value: PropTypes.string,
  showSingleError: PropTypes.bool,
  rules: PropTypes.object,
};
Textarea.defaultProps = {
  className: '',
  children: null,
  label: null,
  onChange: () => {},
  value: '',
  showSingleError: undefined,
  rules: {},
};

export default injectSheet(styles)(Textarea);
