import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { useForm } from 'react-hook-form';

import BaseForm from '../../../../components/Form';
import Input from '../../../../components/Input';
import ImagePicker from '../../../../components/ImagePicker';
import Translate from '../../../../components/Translate';
import { isEmail, isPositiveInteger } from '../../../../utils';

import styles from './form.styles';

const initialValues = {
  email: '',
  capacity: '',
  height: '',
  blank: '',
  width: '',
  weight: '',
  fromDate: '',
  toDate: '',
  images: [],
};

const Form = ({
  classes,
  className,
  onCancel,
  onSubmit,
  success,
  isLoading,
  onImageChange,
}) => {
  const [prospectImages, setProspectImages] = useState([]);
  const [openMore, setOpenMore] = useState(false);
  const now = useState(new Date())[0];

  const methods = useForm({
    criteriaMode: 'all',
    defaultValues: { ...initialValues },
  });
  const { handleSubmit, getValues, reset, watch } = methods;

  useEffect(() => {
    if (success) {
      reset({ ...initialValues });
      setProspectImages([]);
    }
  }, [success]);

  const handleInputFileChange = images => {
    setProspectImages(images);
    onImageChange(images);
  };

  const renderActionButton = (children, onClick, reverse = false) => (
    <button
      onClick={onClick}
      className={classNames(
        classes.button,
        'naked-button',
        reverse && 'reverse',
      )}
      type="button"
    >
      {children}
    </button>
  );

  return (
    <BaseForm methods={methods} className={className}>
      <div className={classNames(classes.root, openMore && 'more')}>
        <div className={classNames(classes.mainForm, openMore)}>
          <Input
            data-private
            type="email"
            label={<Translate id="HOME.MAP.FORM.EMAIL" />}
            name="email"
            value={getValues('email')}
            className={classes.input}
            rules={{
              required: 'FORM.ERROR.EMAIL.REQUIRED',

              // Custom validations
              validate: {
                email: value => {
                  if (value && !isEmail(value)) {
                    return 'FORM.ERROR.EMAIL.INVALID';
                  }

                  return null;
                },
              },
            }}
          />
          <Input
            label={<Translate id="HOME.MAP.FORM.CAPACITY" />}
            name="capacity"
            value={getValues('capacity')}
            className={classes.input}
            rules={{
              required: 'FORM.ERROR.CAPACITY.REQUIRED',
              validate: {
                positiveInteger: value => {
                  if (value && !isPositiveInteger(value)) {
                    return 'FORM.ERROR.CAPACITY.MUST_BE_POSITIVE_INTEGER';
                  }

                  return null;
                },
              },
            }}
          />
          <Input
            type="number"
            name="blank"
            label={<Translate id="HOME.MAP.FORM.BLANK" />}
            value={getValues('blank')}
            className={classes.input}
            rules={{
              required: 'FORM.ERROR.BLANK.REQUIRED',
              validate: {
                positiveInteger: value => {
                  if (value && !isPositiveInteger(value)) {
                    return 'FORM.ERROR.BLANK.MUST_BE_POSITIVE_INTEGER';
                  }

                  return null;
                },
              },
            }}
          />

          <div className={classes.groupInput}>
            <Input
              type="number"
              label={<Translate id="HOME.MAP.FORM.SIZE" />}
              name="width"
              placeholder={<Translate id="HOME.MAP.FORM.SIZE.WIDTH" />}
              value={getValues('width')}
              className={classes.input}
              rules={{
                required: 'FORM.ERROR.WIDTH.REQUIRED',
                validate: {
                  positiveNumber: value => {
                    if (value && Number(value) <= 0) {
                      return 'FORM.ERROR.WIDTH.MUST_BE_POSITIVE_NUMBER';
                    }

                    return null;
                  },
                },
              }}
            />
            <Input
              type="number"
              name="height"
              placeholder={<Translate id="HOME.MAP.FORM.SIZE.HEIGHT" />}
              value={getValues('height')}
              className={classes.input}
              rules={{
                required: 'FORM.ERROR.HEIGHT.REQUIRED',
                validate: {
                  positiveNumber: value => {
                    if (value && Number(value) <= 0) {
                      return 'FORM.ERROR.HEIGHT.MUST_BE_POSITIVE_NUMBER';
                    }

                    return null;
                  },
                },
              }}
            />
          </div>
          <Input
            type="number"
            label={<Translate id="HOME.MAP.FORM.WEIGHT" />}
            name="weight"
            value={getValues('weight')}
            className={classes.input}
            rules={{
              required: 'FORM.ERROR.WEIGHT.REQUIRED',
              validate: {
                positiveNumber: value => {
                  if (value && Number(value) <= 0) {
                    return 'FORM.ERROR.WEIGHT.MUST_BE_POSITIVE_NUMBER';
                  }

                  return null;
                },
              },
            }}
          />
          <Input
            type="datepicker"
            label={<Translate id="HOME.MAP.FORM.FROM_DATE" />}
            name="fromDate"
            value={getValues('fromDate')}
            className={classes.input}
            minDate={now}
            rules={{
              required: 'FORM.ERROR.FROM_DATE.REQUIRED',
              validate: {
                mustSmallerThanToDate: () => {
                  const { fromDate, toDate } = getValues([
                    'fromDate',
                    'toDate',
                  ]);

                  if (fromDate && toDate && fromDate > toDate) {
                    return 'FORM.ERROR.FROM_DATE.MUST_LARGER_THAN_TO_DATE';
                  }

                  return null;
                },
              },
            }}
          />
          <Input
            type="datepicker"
            label={<Translate id="HOME.MAP.FORM.TO_DATE" />}
            name="toDate"
            value={getValues('toDate')}
            className={classes.input}
            minDate={watch('fromDate') || now}
            rules={{
              required: 'FORM.ERROR.TO_DATE.REQUIRED',
            }}
          />
        </div>

        {openMore && (
          <>
            {renderActionButton(
              <i className="icon-line" />,
              () => setOpenMore(false),
              true,
            )}
            <div className={classes.subForm}>
              <ImagePicker
                name="image"
                className={classNames(
                  classes.inputFile,
                  prospectImages.length && 'has-image',
                )}
                accept="image/*"
                value={prospectImages || []}
                onChange={handleInputFileChange}
              />
              <Input
                data-private
                type="textarea"
                label={<Translate id="HOME.MAP.FORM.DESCRIPTION" />}
                name="description"
                value={getValues('description') || ''}
                className={classes.textarea}
              />
            </div>
          </>
        )}
        <div className={classes.groupBtn}>
          {renderActionButton(<i className="icon-close" />, onCancel, true)}
          {renderActionButton(
            <i
              className={
                isLoading ? 'icon-loading rotating' : 'icon-check-mark'
              }
            />,
            handleSubmit(onSubmit),
          )}
          {!openMore &&
            renderActionButton(<i className="icon-plus" />, () =>
              setOpenMore(true),
            )}
        </div>
      </div>
    </BaseForm>
  );
};

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  success: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onImageChange: PropTypes.func,
};
Form.defaultProps = {
  className: '',
  success: false,
  isLoading: false,
  onCancel: () => {},
  onSubmit: () => {},
  onImageChange: () => {},
};

export default injectSheet(styles)(Form);
