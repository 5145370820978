import React from 'react';
import PropTypes from 'prop-types';

import InputText from './components/InputText';
import InputFile from './components/InputFile';
import InputDatePicker from './components/InputDatePicker';
import InputPhoneNumber from './components/InputPhoneNumber';
import Textarea from './components/Textarea';

const Input = props => {
  const { type } = props;

  if (type === 'file') return <InputFile {...props} />;
  if (type === 'textarea') return <Textarea {...props} />;
  if (type === 'datepicker') return <InputDatePicker {...props} />;
  if (type === 'phoneNumber') return <InputPhoneNumber {...props} />;

  return <InputText {...props} />;
};

Input.propTypes = { type: PropTypes.string };
Input.defaultProps = { type: 'text' };

export default Input;
