import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

const initialState = {
  contactSubmitting: false,
  contactSubmittingError: null,
  contactSubmitted: false,
};

const PREFIX = 'CONTACT';

export const CONTACT_SUBMIT = `${PREFIX}/SUBMIT`;
export const CONTACT_SUBMIT_SUCCESS = `${CONTACT_SUBMIT}/SUCCESS`;
export const CONTACT_SUBMIT_FAILED = `${CONTACT_SUBMIT}/FAILED`;
// eslint-disable-next-line max-len
export const CONTACT_SUBMITTED_STATUS_CHANGE = `$${PREFIX}/SUBMITTED_STATUS/CHANGE`;

export const submitContact = createAction(CONTACT_SUBMIT);
export const submitContactSuccess = createAction(CONTACT_SUBMIT_SUCCESS);
export const submitContactFailed = createAction(CONTACT_SUBMIT_FAILED);
export const changeContactSubmittedStatus = createAction(
  CONTACT_SUBMITTED_STATUS_CHANGE,
);

export const contactStateSelector = state => state.contact;
export const contactSubmittingSelector = createSelector(
  contactStateSelector,
  state => state.contactSubmitting,
);
export const contactSubmittedSelector = createSelector(
  contactStateSelector,
  state => state.contactSubmitted,
);

export default handleActions(
  new Map([
    [
      submitContact,
      state => ({
        ...state,
        contactSubmitting: true,
        contactSubmitted: false,
        contactSubmittingError: null,
      }),
    ],
    [
      submitContactSuccess,
      state => ({
        ...state,
        contactSubmitting: false,
        contactSubmitted: true,
        contactSubmittingError: null,
      }),
    ],
    [
      submitContactFailed,
      (state, { payload: { error } }) => ({
        ...state,
        contactSubmitting: false,
        contactSubmitted: false,
        contactSubmittingError: error,
      }),
    ],
    [
      changeContactSubmittedStatus,
      (state, { payload }) => ({
        ...state,
        contactSubmitted: !!payload,
      }),
    ],
  ]),
  { ...initialState },
);
