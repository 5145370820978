import { THEME } from '../../../../../constants';
import { hexToRgbA } from '../../../../../utils';

export default {
  root: {
    paddingTop: 60,
    display: 'flex',
    paddingBottom: 80,
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  coverImage: {
    top: 0,
    left: 0,
    height: '100%',
    position: 'absolute',
  },
  content: {
    zIndex: 1,
    width: '100%',
    maxWidth: 765,
    textAlign: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 40,
    color: '#EBEBEB',
    fontWeight: 600,
    marginBottom: 32,
    textAlign: 'left',
    lineHeight: '55px',
  },
  description: {
    fontSize: 18,
    color: '#FFFFFF',
    marginBottom: 0,
    textAlign: 'left',
    lineHeight: '25px',
    '& + $description': { marginTop: 43 },
    '&:nth-of-type(2)': { paddingRight: 65 },
  },
  button: { marginTop: 53 },

  [`@media (max-width: ${THEME.breakpoints.maxXl}px)`]: {
    root: {
      '&:before': {
        top: 0,
        left: 0,
        width: '100%',
        content: '""',
        height: '100%',
        position: 'absolute',
        backgroundColor: hexToRgbA('#21303E', 0.7),
      },
    },
    description: { '&:nth-of-type(2)': { paddingRight: 0 } },
    coverImage: { opacity: 0 },
  },
};
