import { THEME } from '../../constants';

export default {
  root: {
    width: '100%',
    color: '#F7F8FB',
    position: 'relative',
    backgroundColor: THEME.colors.primary,
  },
  content: {
    minHeight: 152,
    display: 'flex',
    color: 'currentColor',
    padding: [44, THEME.paddingSide, 38],
  },
  leftFooter: { display: 'flex', width: '100%' },
  rightFooter: { display: 'flex', width: '100%' },
  brand: {
    height: 56,
    minWidth: 239,
    maxWidth: 239,
    marginTop: -13,
    marginRight: 32,
    display: 'block',
    cursor: 'pointer',
    overflow: 'hidden',
    '& > img': { width: '100%' },
  },
  listItems: {
    width: '100%',
    maxWidth: 330,
    minWidth: 260,
    display: 'flex',
    marginRight: 30,
    justifyContent: 'space-between',
  },
  item: {
    fontSize: 14,
    lineHeight: 1,
    display: 'block',
    cursor: 'pointer',
    '& +$item': { marginTop: 14 },
  },
  leftItems: { textAlign: 'left', marginRight: '0.5rem' },
  rightItems: { textAlign: 'right' },
  subscribeBox: {
    height: 40,
    width: '100%',
    maxWidth: 330,
    display: 'flex',
    marginRight: 30,
    borderRadius: 4,
    overflow: 'hidden',
    alignItems: 'center',
    position: 'relative',
    padding: [0, 24, 0, 10],
    backgroundColor: '#F7F8FB',
    '& [class^=icon-]': {
      cursor: 'pointer',
      marginLeft: [10],
      color: THEME.colors.primary,
    },
  },
  input: {
    flex: 1,
    fontSize: 14,
    border: 'none',
    color: '#21303e',
    overflow: 'hidden',
    lineHeight: '19px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    backgroundColor: 'transparent',
    '&:focus': { outline: 'none' },
    '&::placeholder': { color: '#999', fontStyle: 'italic' },
  },
  socialsAndStores: { flex: 1 },
  socials: { display: 'flex', marginBottom: 10, justifyContent: 'flex-end' },
  socialIcon: {
    maxHeight: 22,
    display: 'block',
    '& [class^=icon-]': { fontSize: 22, color: '#fff' },
    '& + $socialIcon': { marginLeft: 10 },
  },
  stores: { display: 'flex', justifyContent: 'flex-end' },
  storeImage: {
    display: 'block',
    '& > img': { height: 29, maxWidth: 103 },
    '& + $storeImage': { marginLeft: 10 },
  },
  copyright: {
    height: 45,
    display: 'flex',
    cursor: 'default',
    alignItems: 'center',
    color: 'currentColor',
    justifyContent: 'center',
    borderTop: '1px solid #F7F8FB',
  },

  [`@media (max-width: ${THEME.breakpoints.maxXl}px)`]: {
    rightFooter: {
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
    subscribeBox: { marginRight: 0, marginBottom: 10 },
    socialsAndStores: {
      flex: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    socials: { marginBottom: 0, marginRight: 10 },
    listItems: { minWidth: 220 },
  },
  [`@media (max-width: ${THEME.breakpoints.maxLg}px)`]: {
    leftFooter: { flexDirection: 'column' },
    rightFooter: {
      marginLeft: 10,
      marginBottom: -10,
      justifyContent: 'flex-end',
    },
    listItems: { marginTop: 15 },
  },
  [`@media (max-width: ${THEME.breakpoints.maxMd}px)`]: {
    content: { padding: [44, THEME.paddingSideMobile, 38] },
  },
  [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
    content: { flexDirection: 'column' },
    leftFooter: { marginBottom: 20 },
    rightFooter: {
      marginLeft: 0,
      marginBottom: 0,
      maxWidth: '100%',
      alignItems: 'flex-start',
    },
    listItems: { marginRight: 0, maxWidth: '100%' },
  },
};
