import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ZALO_AUTO_POPUP, ZALO_OA_ID } from '../../configs';
import Script from '../../components/Script';

const Zalo = ({ intl }) => (
  <>
    <div
      className="zalo-chat-widget"
      data-oaid={ZALO_OA_ID}
      data-welcome-message={intl.formatHTMLMessage({
        id: 'ZALO.MESSAGE.WELCOME_TEXT',
      })}
      data-autopopup={ZALO_AUTO_POPUP}
      data-width="350"
      data-height="420"
    />
    <Script id="zaloChatPluginScript" src="//sp.zalo.me/plugins/sdk.js" />
  </>
);

Zalo.propTypes = {
  intl: PropTypes.object.isRequired,
};
export default injectIntl(Zalo);
