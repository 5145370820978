import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';
import DatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';

import Errors from '../Errors';
import { generateId } from '../../../../utils';

import styles from './inputDatePicker.styles';

const CustomInput = forwardRef(({ name, onClick, classes, ...rest }, ref) => (
  <div className={classes.datepicker}>
    <input name={name} ref={ref} onClick={onClick} {...rest} />
    <i
      role="presentation"
      className={classNames(classes.datepickerIcon, 'icon-datepicker')}
      onClick={onClick}
    />
  </div>
));

CustomInput.defaultProps = {
  value: '',
  onClick: () => {},
};

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onClick: PropTypes.func,
};

const InputDatePicker = ({
  classes,
  className,
  label,
  placeholder,
  children,
  onChange,
  value,
  showSingleError,
  rules,
  ...rest
}) => {
  const [date, setDate] = useState((value && new Date(value)) || null);
  const id = generateId();
  const { control, errors } = useFormContext() || {};

  const handleInputChange = v => {
    setDate(v);
    onChange(v);
  };

  useEffect(() => {
    if (!value) {
      setDate(null);
    }
  }, [value]);

  const inputRenderer = () => {
    if (!control) {
      return (
        <DatePicker
          {...rest}
          id={id}
          name={rest.name}
          selected={date}
          onChange={handleInputChange}
          showPopperArrow={false}
          customInput={<CustomInput name={rest.name} classes={classes} />}
        />
      );
    }

    return (
      <Controller
        name={rest.name}
        control={control}
        defaultValue={date}
        render={props => (
          <DatePicker
            {...rest}
            id={id}
            name={rest.name}
            selected={date}
            onChange={e => {
              props.onChange(e);
              handleInputChange(e);
            }}
            showPopperArrow={false}
            customInput={<CustomInput name={rest.name} classes={classes} />}
          />
        )}
        rules={rules}
      />
    );
  };

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classNames(classes.inputWrapper)}>
        {label && (
          <label htmlFor={id} className={classes.label}>
            {label}
          </label>
        )}
        {inputRenderer()}
        {placeholder && !date && (
          <span className={classes.placeholder}>{placeholder}</span>
        )}
        {children}
      </div>
      <Errors
        errors={errors}
        name={rest.name}
        showMultipleError={showSingleError}
      />
    </div>
  );
};

InputDatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
  label: PropTypes.node,
  placeholder: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  showSingleError: PropTypes.bool,
  rules: PropTypes.object,
};
InputDatePicker.defaultProps = {
  className: '',
  onChange: () => {},
  children: null,
  label: null,
  placeholder: null,
  value: '',
  showSingleError: undefined,
  rules: {},
};

export default injectSheet(styles)(InputDatePicker);
