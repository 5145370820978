import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Marker, Map, Tooltip, TileLayer } from 'react-leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';

import markerIcon from './markerIcon';

const mapSettings = {
  zoom: 16,
  id: 'leaplet-map',
  zoomControl: false,
  style: { minHeight: 'inherit' },
  center: [10.7787712, 106.6878343],
  maxZoom: 16,
  scrollWheelZoom: false,
  gestureHandling: true,
};

const tileLayerSettings = {
  attribution:
    '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors', // eslint-disable-line
  url:
    'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}', // eslint-disable-line
};

const LeafletMap = () => {
  const leafletRef = useRef();

  useEffect(() => {
    const map = leafletRef.current.leafletElement;
    map.addHandler('gestureHandling', GestureHandling);
  }, []);

  return (
    <Map ref={leafletRef} {...mapSettings}>
      <TileLayer {...tileLayerSettings} />
      <Marker position={[10.7787712, 106.6878343]} icon={markerIcon}>
        <Tooltip permanent direction="top">
          <h4>Letsparkit</h4>
          <p>212 Nguyễn Đình Chiểu, Phường 6, Quận 3, TP. Hồ Chí Minh</p>
        </Tooltip>
      </Marker>
    </Map>
  );
};
LeafletMap.defaultProps = {
  searchValue: null,
  onMarkerDragEnd: () => {},
};
LeafletMap.propTypes = {
  searchValue: PropTypes.shape({
    address: PropTypes.string,
    geolocation: PropTypes.object,
  }),
  onMarkerDragEnd: PropTypes.func,
};

export default LeafletMap;
