import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import LogRocket from 'logrocket';

import saga from './sagas';
import createRootReducer from './ducks';

const BASE_NAME = '/';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory({ basename: BASE_NAME });
const middlewares = [sagaMiddleware];
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  createRootReducer(history),
  composeEnhancers(
    applyMiddleware(...middlewares, LogRocket.reduxMiddleware()),
  ),
);

sagaMiddleware.run(saga);
