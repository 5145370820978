import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';

import SectionLayout from '../../../components/SectionLayout';
import retangleImage from '../../../assets/images/contact/retangle.svg';
import contactImage from '../../../assets/images/contact/contact.svg';
import Translate from '../../../components/Translate';
import {
  changeContactSubmittedStatus,
  contactSubmittedSelector,
} from '../home.duck';
import CompleteSubmission from '../../CompleteSubmission';

import styles from './contact.styles';
import ContactForm from './ContactForm';

const Contact = ({ classes }) => {
  const dispatch = useDispatch();
  const isSubmitted = useSelector(contactSubmittedSelector);
  const onClose = () => dispatch(changeContactSubmittedStatus(false));

  const contactFormRenderer = () => {
    // TODO: add animation.
    if (isSubmitted) {
      return (
        <CompleteSubmission
          className={classes.completeSubmissionCustom}
          message="CONTACT.HOME.COMPLETE_SUBMISSION.MESSAGE"
          completeButtonText="CONTACT.HOME.COMPLETE_SUBMISSION.BUTTON_TEXT"
          onCompleteButtonClick={onClose}
        />
      );
    }

    return <ContactForm className={classes.form} />;
  };

  return (
    <SectionLayout id="contact" className={classes.root}>
      <img
        className={classes.retangleImage}
        src={retangleImage}
        alt="retangle"
      />
      <img
        className={classes.contactImage}
        src={contactImage}
        alt="background"
      />
      <div className={classes.titleWrapper}>
        <h2 className={classes.title}>
          <Translate id="HOME.CONTACT.TITLE" />
        </h2>
        <p className={classes.description}>
          <Translate id="HOME.CONTACT.SUB_TITLE" />
        </p>
      </div>
      <div className={classes.formWrapper}>
        <div className={classes.formLabel}>
          <Translate id="HOME.CONTACT.FORM.TITLE" />
        </div>
        {contactFormRenderer()}
      </div>
    </SectionLayout>
  );
};

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(Contact);
