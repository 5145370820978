import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import cookie from 'cookie';
import { gUuidV4 } from '../../utils';

const initialState = {
  parkingSlotSessionId: gUuidV4(),
  parkingSlotSubmitting: false,
  parkingSlotSubmittingError: null,
  isParkingSlotSubmitted: false,
  isParkingSlotFormVisible: false,
  isMapBannerVisible: true,
  uploadingParkingSlotImages: [],
  uploadedParkingSlotImages: [],
  failedParkingSlotImages: [],

  addressSuggestions: [],
  addressSuggestionsLoading: false,
  addressSuggestionsLoadingError: null,

  addressDetails: {},
  addressDetailsLoading: false,
  addressDetailsLoadingError: null,

  userPosition: null,
  userPositionLoading: false,
  userPositionLoadingError: null,

  mostFamiliarAddressSearching: false,
  mostFamiliarAddressSearchingError: false,

  selectedAddress: null,

  contactSubmitting: false,
  contactSubmittingError: null,
  contactSubmitted: false,

  prospects: [],
  areProspectsLoading: false,
  areProspectsLoadingError: null,

  areShowAllProspects: true,
};

// ACTION TYPE.

const PREFIX = 'HOME';
export const PARKING_SLOT_SUBMIT = `${PREFIX}/SUBMIT`;
export const PARKING_SLOT_SUBMIT_SUCCESS = `${PARKING_SLOT_SUBMIT}/SUCCESS`;
export const PARKING_SLOT_SUBMIT_FAILED = `${PARKING_SLOT_SUBMIT}/FAILED`;

export const PARKING_SLOT_UPLOAD = `${PREFIX}/PARKING_SLOT_IMAGE/SUBMIT`;
// eslint-disable-next-line max-len
export const PARKING_SLOT_UPLOAD_SUCCESS = `${PARKING_SLOT_UPLOAD}/SUCCESS`;
// eslint-disable-next-line max-len
export const PARKING_SLOT_UPLOAD_FAILED = `${PARKING_SLOT_UPLOAD}/FAILED`;
// eslint-disable-next-line max-len
export const PARKING_SLOT_FORM_VISIBLE_SET = `${PREFIX}/PARKING_SLOT_FORM/VISIBLE/SET`;
// eslint-disable-next-line max-len
export const PARKING_SLOT_FORM_SUBMIT_STATUS_SET = `${PREFIX}/PARKING_SLOT_FORM/SUBMIT/STATUS/SET`;
export const MAP_BANNER_VISIBLE_SET = `${PREFIX}/MAP_BANNER/VISIBLE/SET`;

// Map action types.
const MAP_PREFIX = `${PREFIX}/MAP`;

const ADDRESS_SUGGESTIONS_PREFIX = `${MAP_PREFIX}/ADDRESS_SUGGESTIONS`;
export const ADDRESS_SUGGESTIONS_FETCH = `${ADDRESS_SUGGESTIONS_PREFIX}/FETCH`;
// eslint-disable-next-line max-len
export const ADDRESS_SUGGESTIONS_FETCH_SUCCESS = `${ADDRESS_SUGGESTIONS_FETCH}/SUCCESS`;
// eslint-disable-next-line max-len
export const ADDRESS_SUGGESTIONS_FETCH_FAILED = `${ADDRESS_SUGGESTIONS_FETCH}/FAILED`;
// eslint-disable-next-line max-len
export const ADDRESS_SUGGESTIONS_VISIBLE_SET = `${ADDRESS_SUGGESTIONS_PREFIX}/VISIBLE/SET`;

export const ADDRESS_DETAILS_FETCH = `${MAP_PREFIX}/ADDRESS_DETAILS/FETCH`;
export const ADDRESS_DETAILS_FETCH_SUCCESS = `${ADDRESS_DETAILS_FETCH}/SUCCESS`;
export const ADDRESS_DETAILS_FETCH_FAILED = `${ADDRESS_DETAILS_FETCH}/FAILED`;

export const USER_POSITION_DETECT = `${MAP_PREFIX}/USER_POSITION/DETECT`;
export const USER_POSITION_DETECT_SUCCESS = `${USER_POSITION_DETECT}/SUCCESS`;
export const USER_POSITION_DETECT_FAILED = `${USER_POSITION_DETECT}/FAILED`;

// eslint-disable-next-line max-len
export const MOST_FAMILIAR_ADDRESS_SEARCH = `${MAP_PREFIX}/ADDRESS/MOST_FAMILIAR/FETCH`;
// eslint-disable-next-line max-len
export const MOST_FAMILIAR_ADDRESS_SEARCH_SUCCESS = `${MOST_FAMILIAR_ADDRESS_SEARCH}/SUCCESS`;
// eslint-disable-next-line max-len
export const MOST_FAMILIAR_ADDRESS_SEARCH_FAILED = `${MOST_FAMILIAR_ADDRESS_SEARCH}/FAILED`;

export const ADDRESS_SELECT = `${MAP_PREFIX}/ADDRESS/SELECT`;
export const ADDRESS_SELECT_SUCCESS = `${ADDRESS_SELECT}/SUCCESS`;
export const ADDRESS_SELECT_FAILED = `${ADDRESS_SELECT}/FAILED`;

export const CONTACT_PREFIX = `${PREFIX}/CONTACT`;
export const CONTACT_SUBMIT = `${CONTACT_PREFIX}/SUBMIT`;
export const CONTACT_SUBMIT_SUCCESS = `${CONTACT_SUBMIT}/SUCCESS`;
export const CONTACT_SUBMIT_FAILED = `${CONTACT_SUBMIT}/FAILED`;

export const PROSPECTS_PREFIX = `${PREFIX}/PROSPECTS`;

export const ALL_PROSPECTS_FETCH = `${PROSPECTS_PREFIX}/FETCH`;
export const ALL_PROSPECTS_FETCH_SUCCESS = `${ALL_PROSPECTS_FETCH}/SUCCESS`;
export const ALL_PROSPECTS_FETCH_FAILED = `${ALL_PROSPECTS_FETCH}/FAILED`;

// eslint-disable-next-line max-len
export const ALL_PROSPECTS_VISIBLE_TOGGLE = `${PROSPECTS_PREFIX}/VISIBLE/TOGGLE`;

// eslint-disable-next-line max-len
export const CONTACT_SUBMITTED_STATUS_CHANGE = `${CONTACT_PREFIX}/SUBMITTED_STATUS/CHANGE`;

// ACTION.

export const fetchAddressSuggestions = createAction(ADDRESS_SUGGESTIONS_FETCH);
export const fetchAddressSuggestionsSuccess = createAction(
  ADDRESS_SUGGESTIONS_FETCH_SUCCESS,
);
export const fetchAddressSuggestionsFailed = createAction(
  ADDRESS_SUGGESTIONS_FETCH_FAILED,
);

export const submitParkingSlot = createAction(PARKING_SLOT_SUBMIT);
export const submitParkingSlotSuccess = createAction(
  PARKING_SLOT_SUBMIT_SUCCESS,
);
export const submitParkingSlotFailed = createAction(PARKING_SLOT_SUBMIT_FAILED);

export const uploadParkingSlotImage = createAction(PARKING_SLOT_UPLOAD);
export const uploadParkingSlotImageSuccess = createAction(
  PARKING_SLOT_UPLOAD_SUCCESS,
);
export const uploadParkingSlotImageFailed = createAction(
  PARKING_SLOT_UPLOAD_FAILED,
);

export const setParkingSlotFormVisible = createAction(
  PARKING_SLOT_FORM_VISIBLE_SET,
);
export const setMapBannerVisible = createAction(MAP_BANNER_VISIBLE_SET);
export const setParkingSlotFormSubmitStatus = createAction(
  PARKING_SLOT_FORM_SUBMIT_STATUS_SET,
);

export const searchMostFamiliarAddress = createAction(
  MOST_FAMILIAR_ADDRESS_SEARCH,
);
export const searchMostFamiliarAddressSuccess = createAction(
  MOST_FAMILIAR_ADDRESS_SEARCH_SUCCESS,
);
export const searchMostFamiliarAddressFailed = createAction(
  MOST_FAMILIAR_ADDRESS_SEARCH_FAILED,
);

export const detectUserPosition = createAction(USER_POSITION_DETECT);
export const detectUserPositionSuccess = createAction(
  USER_POSITION_DETECT_SUCCESS,
);
export const detectUserPositionFailed = createAction(
  USER_POSITION_DETECT_FAILED,
);

export const fetchAddressDetails = createAction(ADDRESS_DETAILS_FETCH);
export const fetchAddressDetailsSuccess = createAction(
  ADDRESS_DETAILS_FETCH_SUCCESS,
);
export const fetchAddressDetailsFailed = createAction(
  ADDRESS_DETAILS_FETCH_FAILED,
);

export const selectAddress = createAction(ADDRESS_SELECT);
export const selectAddressSuccess = createAction(ADDRESS_SELECT_SUCCESS);
export const selectAddressFailed = createAction(ADDRESS_SELECT_FAILED);

export const submitContact = createAction(CONTACT_SUBMIT);
export const submitContactSuccess = createAction(CONTACT_SUBMIT_SUCCESS);
export const submitContactFailed = createAction(CONTACT_SUBMIT_FAILED);

export const fetchAllProspects = createAction(ALL_PROSPECTS_FETCH);
export const fetchAllProspectsSuccess = createAction(
  ALL_PROSPECTS_FETCH_SUCCESS,
);
export const fetchAllProspectsFailed = createAction(ALL_PROSPECTS_FETCH_FAILED);
export const changeContactSubmittedStatus = createAction(
  CONTACT_SUBMITTED_STATUS_CHANGE,
);

export const toggleAllProspectsVisible = createAction(
  ALL_PROSPECTS_VISIBLE_TOGGLE,
);

// Selectors

export const homeStateSelector = state => state.home;
export const parkingSlotSubmittingSelector = createSelector(
  homeStateSelector,
  state => state.parkingSlotSubmitting,
);
export const isMapBannerVisibleSelector = createSelector(
  homeStateSelector,
  state => state.isMapBannerVisible,
);
export const isParkingSlotFormVisibleSelector = createSelector(
  homeStateSelector,
  state => state.isParkingSlotFormVisible,
);
export const isParkingSlotSubmittedSelector = createSelector(
  homeStateSelector,
  state => state.isParkingSlotSubmitted,
);

export const addressSuggestionsSelector = createSelector(
  homeStateSelector,
  state => state.addressSuggestions,
);

export const addressDetailsSelector = createSelector(
  homeStateSelector,
  state => state.addressDetails,
);

export const selectedAddressSelector = createSelector(
  homeStateSelector,
  state => state.selectedAddress,
);

export const userPositionSelector = createSelector(
  homeStateSelector,
  state => state.userPosition,
);

export const contactSubmittingSelector = createSelector(
  homeStateSelector,
  state => state.contactSubmitting,
);
export const contactSubmittedSelector = createSelector(
  homeStateSelector,
  state => state.contactSubmitted,
);

export const parkingSlotSessionIdSelector = createSelector(
  homeStateSelector,
  state => state.parkingSlotSessionId,
);
export const uploadingParkingSlotImagesSelector = createSelector(
  homeStateSelector,
  state => state.uploadingParkingSlotImages,
);
export const uploadedParkingSlotImagesSelector = createSelector(
  homeStateSelector,
  state => state.uploadedParkingSlotImages,
);
export const userPositionLoadingSelector = createSelector(
  homeStateSelector,
  state => state.userPositionLoading,
);
export const addressSuggestionsLoadingSelector = createSelector(
  homeStateSelector,
  state => state.addressSuggestionsLoading,
);

export const prospectsSelector = createSelector(
  homeStateSelector,
  state => state.prospects,
);
export const areShowAllProspectsSelector = createSelector(
  homeStateSelector,
  state => state.areShowAllProspects,
);
export const defaultMarkersSelector = createSelector(
  prospectsSelector,
  areShowAllProspectsSelector,
  (prospects, areShowAllProspects) =>
    prospects.reduce((acc, prospect) => {
      const isMarkerValid =
        prospect.lat && prospect.lng && prospect.prospectType === 'parking';

      const parsedCookie = cookie.parse(document.cookie);
      const { userId } = parsedCookie;

      if (
        isMarkerValid &&
        (areShowAllProspects || prospect.userId === userId)
      ) {
        const latestDetails =
          (prospect.details && prospect.details[prospect.details.length - 1]) ||
          {};

        return [
          ...acc,
          {
            ...prospect,
            position: [prospect.lat, prospect.lng],
            address: latestDetails.address,
          },
        ];
      }

      return acc;
    }, []),
);

export default handleActions(
  new Map([
    [
      submitParkingSlot,
      state => ({
        ...state,
        parkingSlotSubmitting: true,
        isParkingSlotSubmitted: false,
        parkingSlotSubmittingError: null,
      }),
    ],
    [
      submitParkingSlotSuccess,
      (state, { payload }) => ({
        ...state,
        // Renew the parking slot session.
        parkingSlotSessionId: gUuidV4(),
        parkingSlotSubmitting: false,
        isParkingSlotSubmitted: true,
        parkingSlotSubmittingError: null,
        uploadingParkingSlotImages: [],
        uploadedParkingSlotImages: [],
        failedParkingSlotImages: [],
        prospects: state.prospects.reduce(
          (acc, prospect) =>
            prospect.id === payload.id ? acc : [...acc, prospect],
          [payload],
        ),
      }),
    ],
    [
      submitParkingSlotFailed,
      (state, { payload: { error } }) => ({
        ...state,
        parkingSlotSubmitting: false,
        isParkingSlotSubmitted: false,
        parkingSlotSubmittingError: error,
      }),
    ],
    [
      setParkingSlotFormVisible,
      (state, { payload }) => ({
        ...state,
        isParkingSlotFormVisible: payload,
      }),
    ],
    [
      setMapBannerVisible,
      (state, { payload }) => ({
        ...state,
        isMapBannerVisible: payload,
      }),
    ],
    [
      setParkingSlotFormSubmitStatus,
      (state, { payload }) => ({
        ...state,
        isParkingSlotSubmitted: payload,
      }),
    ],
    [
      fetchAddressSuggestions,
      state => ({
        ...state,
        addressSuggestions: [],
        addressSuggestionsLoading: true,
        addressSuggestionsLoadingError: null,
      }),
    ],
    [
      fetchAddressSuggestionsSuccess,
      (state, { payload }) => ({
        ...state,
        addressSuggestions: payload,
        addressSuggestionsLoading: false,
        addressSuggestionsLoadingError: null,
      }),
    ],
    [
      fetchAddressSuggestionsFailed,
      (state, { payload: { error } }) => ({
        ...state,
        addressSuggestionsLoading: false,
        addressSuggestionsLoadingError: error,
      }),
    ],
    [
      fetchAddressDetails,
      state => ({
        ...state,
        addressDetailsLoading: true,
        addressDetailsLoadingError: null,
      }),
    ],
    [
      fetchAddressDetailsSuccess,
      (state, { payload }) => ({
        ...state,
        addressDetails:
          payload && payload.id
            ? { ...state.addressDetails, [payload.id]: payload }
            : state.addressDetails,
        addressDetailsLoading: false,
        addressDetailsLoadingError: null,
      }),
    ],
    [
      fetchAddressDetailsFailed,
      (state, { payload: { error } }) => ({
        ...state,
        addressDetailsLoading: false,
        addressDetailsLoadingError: error,
      }),
    ],
    [
      detectUserPosition,
      state => ({
        ...state,
        userPositionLoading: true,
        userPositionLoadingError: null,
      }),
    ],
    [
      detectUserPositionSuccess,
      (state, { payload }) => ({
        ...state,
        addressDetails:
          payload && payload.id
            ? { ...state.addressDetails, [payload.id]: payload }
            : state.addressDetails,
        userPosition: payload,
        userPositionLoading: false,
        userPositionLoadingError: null,
      }),
    ],
    [
      detectUserPositionFailed,
      (state, { payload: { error } }) => ({
        ...state,
        userPositionLoading: false,
        userPositionLoadingError: error,
      }),
    ],
    [
      searchMostFamiliarAddress,
      state => ({
        ...state,
        addressSuggestions: [],
        mostFamiliarAddressSearching: true,
        mostFamiliarAddressSearchingError: null,
      }),
    ],
    [
      searchMostFamiliarAddressSuccess,
      (state, { payload }) => ({
        ...state,
        addressDetails:
          payload && payload.id
            ? { ...state.addressDetails, [payload.id]: payload }
            : state.addressDetails,
        selectedAddress: payload,
        mostFamiliarAddressSearching: false,
        mostFamiliarAddressSearchingError: null,
      }),
    ],
    [
      searchMostFamiliarAddressFailed,
      (state, { payload: { error } }) => ({
        ...state,
        mostFamiliarAddressSearching: true,
        mostFamiliarAddressSearchingError: error,
      }),
    ],

    [
      selectAddress,
      state => ({
        ...state,
        addressSelecting: true,
        addressSelectingError: null,
      }),
    ],
    [
      selectAddressSuccess,
      (state, { payload }) => ({
        ...state,
        selectedAddress: payload,
        addressSelecting: false,
        addressSelectingError: null,
      }),
    ],
    [
      selectAddressFailed,
      (state, { payload: { error } }) => ({
        ...state,
        addressSelecting: false,
        addressSelectingError: error,
      }),
    ],
    [
      submitContact,
      state => ({
        ...state,
        contactSubmitting: true,
        contactSubmitted: false,
        contactSubmittingError: null,
      }),
    ],
    [
      submitContactSuccess,
      state => ({
        ...state,
        contactSubmitting: false,
        contactSubmitted: true,
        contactSubmittingError: null,
      }),
    ],
    [
      submitContactFailed,
      (state, { payload: { error } }) => ({
        ...state,
        contactSubmitting: false,
        contactSubmitted: false,
        contactSubmittingError: error,
      }),
    ],
    [
      uploadParkingSlotImage,
      (state, { payload }) => ({
        ...state,
        uploadingParkingSlotImages: [
          ...state.uploadingParkingSlotImages,
          `${payload.size}_${payload.name}`,
        ],
        failedParkingSlotImages: state.failedParkingSlotImages.filter(
          name => name !== payload.name,
        ),
      }),
    ],
    [
      uploadParkingSlotImageSuccess,
      (state, { payload }) => ({
        ...state,
        uploadingParkingSlotImages: state.uploadingParkingSlotImages.filter(
          name => name !== payload,
        ),
        uploadedParkingSlotImages: [
          ...state.uploadedParkingSlotImages,
          payload,
        ],
      }),
    ],
    [
      uploadParkingSlotImageFailed,
      (state, { payload }) => ({
        ...state,
        uploadingParkingSlotImages: state.uploadingParkingSlotImages.filter(
          name => name !== payload.name,
        ),
        failedParkingSlotImages: [
          ...state.failedParkingSlotImages,
          payload.name,
        ],
      }),
    ],
    [
      fetchAllProspects,
      state => ({
        ...state,
        areProspectsLoading: true,
        areProspectsLoadingError: null,
      }),
    ],
    [
      fetchAllProspectsSuccess,
      (state, { payload }) => ({
        ...state,
        prospects: payload,
        areProspectsLoading: false,
        areProspectsLoadingError: null,
      }),
    ],
    [
      fetchAllProspectsFailed,
      (state, { payload: { error } }) => ({
        ...state,
        areProspectsLoading: false,
        areProspectsLoadingError: error,
      }),
    ],
    [
      changeContactSubmittedStatus,
      (state, { payload }) => ({
        ...state,
        contactSubmitted: !!payload,
      }),
    ],
    [
      toggleAllProspectsVisible,
      (state, { payload }) => ({
        ...state,
        areShowAllProspects: !!payload,
      }),
    ],
  ]),
  { ...initialState },
);
