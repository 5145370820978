/* eslint-disable */
import * as L from 'leaflet';

export default new L.divIcon({
  className: 'custom-icon',
  html: `<svg width="30" height="30" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="17.0261" cy="17.3091" r="17" fill="#9ADEFF" fill-opacity="0.7"/>
  <g filter="url(#filter0_f)">
  <circle cx="17.0262" cy="17.3091" r="12.5652" fill="#0E98D5"/>
  </g>
  <defs>
  <filter id="filter0_f" x="3.46094" y="3.7439" width="27.1304" height="27.1304" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
  <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur"/>
  </filter>
  </defs>
  </svg>
  `,
});
