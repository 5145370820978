import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import SectionLayout from '../../components/SectionLayout';
import Translate from '../../components/Translate';
import SectionTitle from '../../components/SectionTitle';

import styles from './support.styles';

const Contact = ({ classes }) => (
  <div className={classes.root}>
    <SectionTitle>
      <Translate id="SUPPORT.TITLE" />
    </SectionTitle>
    <SectionLayout>
      <p className={classes.description}>
        <Translate id="SUPPORT.DESCRIPTION" html />
      </p>
    </SectionLayout>
  </div>
);

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(Contact);
