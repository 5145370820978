import {
  takeLatest,
  put,
  call,
  all,
  take,
  select,
  race,
  takeEvery,
} from 'redux-saga/effects';
import { mapsApi, prospectApi } from '../../utils';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../NotificationManager/notificationManager.duck';

import {
  addressDetailsSelector,
  ADDRESS_DETAILS_FETCH,
  ADDRESS_DETAILS_FETCH_FAILED,
  ADDRESS_DETAILS_FETCH_SUCCESS,
  ADDRESS_SELECT,
  ADDRESS_SUGGESTIONS_FETCH,
  ALL_PROSPECTS_FETCH,
  CONTACT_SUBMIT,
  detectUserPositionFailed,
  detectUserPositionSuccess,
  fetchAddressDetails,
  fetchAddressDetailsFailed,
  fetchAddressDetailsSuccess,
  fetchAddressSuggestionsFailed,
  fetchAddressSuggestionsSuccess,
  fetchAllProspectsFailed,
  fetchAllProspectsSuccess,
  MOST_FAMILIAR_ADDRESS_SEARCH,
  parkingSlotSessionIdSelector,
  PARKING_SLOT_SUBMIT,
  PARKING_SLOT_UPLOAD,
  prospectsSelector,
  searchMostFamiliarAddressFailed,
  searchMostFamiliarAddressSuccess,
  selectAddressFailed,
  selectAddressSuccess,
  selectedAddressSelector,
  setParkingSlotFormVisible,
  submitContactFailed,
  submitContactSuccess,
  submitParkingSlotFailed,
  submitParkingSlotSuccess,
  uploadParkingSlotImageFailed,
  uploadParkingSlotImageSuccess,
  USER_POSITION_DETECT,
} from './home.duck';

const getUserLocation = () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      location => resolve(location),
      error => reject(error),
    );
  });

function* uploadParkingSlotImageFlow() {
  yield takeEvery(PARKING_SLOT_UPLOAD, function* onUploadParkingSlotImageSlot({
    payload,
  }) {
    try {
      const folder = yield select(parkingSlotSessionIdSelector);
      yield call(prospectApi.uploadImage, { file: payload, folder });

      yield put(
        uploadParkingSlotImageSuccess(`${payload.size}_${payload.name}`),
      );
    } catch (error) {
      yield put(uploadParkingSlotImageFailed({ error, name: payload.name }));
    }
  });
}

function* submitParkingSlotFlow() {
  yield takeLatest(PARKING_SLOT_SUBMIT, function* onSubmitParkingSlot({
    payload,
  }) {
    try {
      const selectedAddress = yield select(selectedAddressSelector);
      let isValidationSuccess = true;
      if (!selectedAddress || !selectedAddress.address) {
        yield put(showErrorNotification('FORM.ERROR.ADDRESS_REQUIRED'));
        isValidationSuccess = false;
      }

      if (!isValidationSuccess) {
        yield put(submitParkingSlotFailed({ error: 'FORM.VALIDATION.FAILED' }));
        return;
      }

      const { email, lng, lat, images, ...details } = payload;
      const imageFolder = yield select(parkingSlotSessionIdSelector);

      const prospect = yield call(prospectApi.create, {
        email,
        lat,
        lng,
        details: {
          ...details,
          imageFolder,
        },
      });

      yield put(showSuccessNotification('API.REQUEST.SUCCESS'));
      yield put(setParkingSlotFormVisible(false));
      yield put(submitParkingSlotSuccess(prospect));
    } catch (error) {
      yield put(showErrorNotification('API.REQUEST.FAILED'));
      yield put(submitParkingSlotFailed({ error }));
    }
  });
}

function* fetchAddressSuggestionsFlow() {
  yield takeLatest(
    ADDRESS_SUGGESTIONS_FETCH,
    function* onFetchAddressSuggestions({ payload }) {
      try {
        const suggestions = yield call(mapsApi.searchAddress, {
          input: payload,
        });

        yield put(fetchAddressSuggestionsSuccess(suggestions));
      } catch (error) {
        yield put(fetchAddressSuggestionsFailed({ error }));
      }
    },
  );
}

function* fetchAddressDetailsFlow() {
  yield takeLatest(ADDRESS_DETAILS_FETCH, function* onFetchAddressDetails({
    payload,
  }) {
    try {
      const matches = yield call(mapsApi.geocoding, payload);
      const firstMatch = matches[0];

      yield put(fetchAddressDetailsSuccess(firstMatch));
    } catch (error) {
      yield put(fetchAddressDetailsFailed({ error }));
    }
  });
}

function* detectUserPositionFlow() {
  yield takeLatest(USER_POSITION_DETECT, function* onDetectUserPosition() {
    try {
      const position = yield call(getUserLocation);

      const { coords } = position;
      const results = yield call(mapsApi.searchCoordinates, {
        latitude: coords.latitude,
        longitude: coords.longitude,
      });
      if (results && results[0]) {
        yield put(detectUserPositionSuccess(results[0]));
      } else {
        yield put(detectUserPositionFailed({ error: 'TODO: DEFINE error' }));
      }
    } catch (error) {
      yield put(detectUserPositionFailed({ error }));
    }
  });
}

function* searchMostFamiliarAddressFlow() {
  yield takeLatest(
    MOST_FAMILIAR_ADDRESS_SEARCH,
    function* onSearchMostFamiliarAddress({ payload }) {
      try {
        const suggestions = yield call(mapsApi.searchAddress, {
          input: payload,
        });
        const firstSuggestion = suggestions[0];

        if (firstSuggestion) {
          const matches = yield call(mapsApi.geocoding, firstSuggestion.id);
          const firstMatch = matches[0];

          yield put(searchMostFamiliarAddressSuccess(firstMatch));
        } else {
          yield put(searchMostFamiliarAddressFailed({ error: 'Cannot find' }));
        }
      } catch (error) {
        yield put(searchMostFamiliarAddressFailed({ error }));
      }
    },
  );
}

function* selectAddressFlow() {
  yield takeLatest(ADDRESS_SELECT, function* onSelectAddress({ payload }) {
    try {
      const { id, address, lat, lng, viewport } = payload;
      if (!id && !address) {
        yield put(
          selectAddressFailed({
            error: 'Need at least address id or address statement',
          }),
        );
        return;
      }

      if ((address, lat, lng, viewport)) {
        yield put(selectAddressSuccess({ address, lat, lng, viewport }));
        return;
      }

      if (id) {
        const cachedDetails = yield select(addressDetailsSelector);

        let details = cachedDetails[id];
        if (!details) {
          yield put(fetchAddressDetails(id));

          const { success, failed } = yield race({
            success: take(ADDRESS_DETAILS_FETCH_SUCCESS),
            failed: take(ADDRESS_DETAILS_FETCH_FAILED),
          });

          if (success) {
            details = success.payload;
          } else {
            const error = failed.payload;
            yield put(selectAddressFailed({ error }));
          }
        }

        yield put(
          selectAddressSuccess({
            address: address || details.address,
            lat: details.lat,
            lng: details.lng,
            viewport: details.viewport,
          }),
        );
      } else {
        const suggestions = yield call(mapsApi.searchAddress, {
          input: address,
        });
        const firstSuggestion = suggestions[0];

        if (firstSuggestion) {
          const matches = yield call(mapsApi.geocoding, firstSuggestion.id);
          const firstMatch = matches[0];

          yield put(
            selectAddressSuccess({
              address: address || firstMatch.address,
              lat: firstMatch.lat,
              lng: firstMatch.lng,
              viewport: firstMatch.viewport,
            }),
          );
        } else {
          yield put(selectAddressFailed({ error: 'Cannot find' }));
        }
      }
    } catch (error) {
      yield put(selectAddressFailed({ error }));
    }
  });
}

function* submitContactFlow() {
  yield takeLatest(CONTACT_SUBMIT, function* onSubmitContact({ payload }) {
    try {
      const { email, ...details } = payload;

      yield call(prospectApi.create, {
        email,
        details,
      });
      yield put(submitContactSuccess());
    } catch (error) {
      yield put(showErrorNotification('API.REQUEST.FAILED'));
      yield put(submitContactFailed({ error }));
    }
  });
}

function* fetchAllProspectsFlow() {
  yield takeLatest(ALL_PROSPECTS_FETCH, function* onFetchAllProspects() {
    try {
      const prospects = yield select(prospectsSelector);

      if (prospects && prospects.length) {
        yield put(fetchAllProspectsSuccess(prospects));

        return;
      }

      const results = yield call(prospectApi.fetchAll);
      yield put(fetchAllProspectsSuccess(results));
    } catch (error) {
      yield put(fetchAllProspectsFailed({ error }));
    }
  });
}

export default function* homeSaga() {
  yield all([
    submitParkingSlotFlow(),
    fetchAddressSuggestionsFlow(),
    fetchAddressDetailsFlow(),
    detectUserPositionFlow(),
    searchMostFamiliarAddressFlow(),
    selectAddressFlow(),
    submitContactFlow(),
    uploadParkingSlotImageFlow(),
    fetchAllProspectsFlow(),
  ]);
}
