import { apiGet, paramsBuilder } from './api';

const endpoint = 'maps';

/**
 * Search all possible address.
 * @param {{input: string}} params Search Params.
 * @returns {Promise<Array<{ id: string, address: string }>>}
 */
const searchAddress = params =>
  apiGet(`${endpoint}/address/${paramsBuilder(params)}`);

/**
 * Search address by coordinates
 * @param {{latitude: number, longitude: number}} params Coordinates.
 * @returns {Promise<Array<GeocodeResult>>}
 */
const searchCoordinates = params =>
  apiGet(`${endpoint}/reverse-geocoding/${paramsBuilder(params)}`);

/**
 * Get geographic coordinates
 * @param {string} params Place Id.
 * @returns {Promise<Array<GeocodeResult>>}
 */
const geocoding = placeId => apiGet(`${endpoint}/geocoding/${placeId}`);

export default {
  searchAddress,
  searchCoordinates,
  geocoding,
};

/**
 * @typedef LatLngLiteral
 * @type {object}
 * @property {number} lag Latitude.
 * @property {number} lng Longitude.
 */

/**
 * @typedef LatitudeLongitudeBounds
 * @type {object}
 * @property {LatLngLiteral} northeast North-East coordinates.
 * @property {LatLngLiteral} southwest South-West coordinates.
 */

/**
 * @typedef GeocodeResult
 * @type {object}
 * @property {string} id Id.
 * @property {string} address Address.
 * @property {number} lat Longitude.
 * @property {number} lng Longitude.
 * @property {LatitudeLongitudeBounds} viewport Viewport.
 */
