import * as L from 'leaflet';
import { useEffect } from 'react';
import { useLeaflet } from 'react-leaflet';
import 'leaflet.markercluster';

// L.markerClusterGroup = markerClusterGroup;
const mcg = L.markerClusterGroup();

const MarkerCluster = ({
  markers,
  popupContentKey = 'popupContent',
  icon: defaultIcon,
}) => {
  const { map } = useLeaflet();

  useEffect(() => {
    mcg.clearLayers();
    markers.forEach(({ position, [popupContentKey]: popupContent, icon }) =>
      L.marker(new L.LatLng(...position), {
        icon: icon || defaultIcon,
      })
        .addTo(mcg)
        .bindPopup(popupContent),
    );
    // add the marker cluster group to the map
    map.addLayer(mcg);
  }, [markers, map]);

  return null;
};

export default MarkerCluster;
