import { THEME } from '../../constants';
import { hexToRgbA } from '../../utils';

export default {
  root: {
    flex: 'none',
    fontSize: 18,
    width: '100%',
    maxWidth: 254,
    lineHeight: 1,
    border: 'none',
    borderRadius: 4,
    padding: [26, 0],
    cursor: 'pointer',
    userSelect: 'none',
    textAlign: 'center',
    alignItems: 'center',
    position: 'relative',
    display: 'inline-flex',
    textDecoration: 'none',
    justifyContent: 'center',
    transition: THEME.transition,
    '&:focus': { outline: 'none' },
    '&:hover': { backgroundColor: hexToRgbA(THEME.colors.primary, 0.7) },
  },
  default: {
    color: '#FEFEFE',
    backgroundColor: THEME.colors.primary,
  },
};
