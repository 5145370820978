import { THEME } from '../../../../constants';

export default {
  root: {
    background: '#fff',
    boxShadow: '5px 10px 9px rgba(0, 0, 0, 0.21)',
    borderRadius: 9,
    padding: [58, 30, 64],
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '25px',
    color: '#16212C',
    marginBottom: 29,
  },
  inputWrapper: {
    marginBottom: 32,
  },
  content: {
    margin: '0 auto',
    maxWidth: 390,
  },
  input: {
    fontWeight: 'normal',
    fontSize: 14,
    display: 'block',
    lineHeight: '19px',
    color: '#3E3E3E',
    border: 'none',
    width: '100%',
    borderBottom: '1px solid #A6A6A6',
    padding: [8, 0],
    '&::placeholder': {
      fontStyle: 'italic',
      color: '#A6A6A6',
    },
    '&:focus': { outline: 'none' },
  },
  textareaWrapper: {
    marginBottom: 82,
  },
  textarea: {
    extend: 'input',
    height: 136,
    resize: 'none',
  },
  phoneNumber: {
    '& > div': {
      borderBottom: '1px solid #A6A6A6',
      padding: [8, 0],
      '& > div': {
        '& > input': {
          color: '#3E3E3E',
          fontSize: 14,
          lineHeight: '19px',
        },
      },
    },
    '& > p': {
      color: 'red',
    },
  },
  button: {
    position: 'relative',
    background: '#0E98D5',
    borderRadius: 4,
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '25px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 150,
    height: 49,
    border: 'none',
    margin: [30, 'auto', 0],
    '&:focus': { outline: 'none' },
    '& [class^=icon-]': {
      position: 'absolute',
      right: 20,
    },
  },

  [`@media (max-width: ${THEME.breakpoints.maxLg}px)`]: {
    contactImage: { bottom: 0 },
  },

  errorInput: {
    color: 'red',
  },
};
