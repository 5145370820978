import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import SimpleList from '../../../components/SimpleList';
import styles from './articles.styles';

const Articles = ({ classes, className, articles, articleClicked }) => (
  <SimpleList
    className={classNames(className, classes.root)}
    items={articles}
    valueKey="title"
    itemClicked={articleClicked}
  />
);

Articles.defaultProps = {
  className: '',
  articles: [],
  articleClicked: () => {},
};

Articles.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ),
  articleClicked: PropTypes.func,
};

export default injectSheet(styles)(Articles);
