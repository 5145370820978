import { THEME } from '../../../constants';

export default {
  useful: {
    padding: [28, 0],
    marginBottom: 20,
    borderBottom: '1.5px solid #DDDDDD',
  },
  question: {
    textAlign: 'center',
    fontSize: 12,
    lineHeight: '16px',
    color: '#000',
    marginBottom: 0,
    '& a': { cursor: 'pointer', color: THEME.colors.primary },
  },
};
