import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';
import Lightbox from 'react-image-lightbox';

import ImageAdder from './ImageAdder';
import ImageDisplayer from './ImageDisplayer';
import ImageControlButton from './ImageControlButton';
import Frame from './ImageFrame';

import styles from './imagePicker.styles';

const ImagePicker = ({
  value,
  className,
  classes,
  onChange,
  accept,
  name,
  maxImages,
}) => {
  const [images, setImages] = useState(value && value.length ? value : []);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    setImages(value && value.length ? value : []);
  }, [value]);

  const addNewImage = (event, newImage) => {
    if (!event.target.files[0] || images.length >= maxImages) {
      return;
    }

    const { target } = event;
    const newImages = [
      {
        name: target.name,
        file: target.files[0],
        base64: newImage,
      },
      ...images,
    ];

    setImages(newImages);
    setActiveIndex(0);

    onChange(newImages);
  };

  const moveToImageIndex = index => {
    if (index < 0 || index > images.length - 1) {
      return;
    }
    setActiveIndex(index);
  };

  const removeAtImageIndex = index => {
    if (images.length - 1) {
      setActiveIndex(
        activeIndex !== 0 && index <= activeIndex
          ? activeIndex - 1
          : activeIndex,
      );
    } else {
      setActiveIndex(-1);
    }

    const newImages = images.filter((_, imageIndex) => imageIndex !== index);
    setImages(newImages);
    onChange(newImages);
  };

  const handleOpenModal = isOpen => {
    setOpenModal(isOpen);
    if (imageUrl) {
      URL.revokeObjectURL(imageUrl);
    }

    if (isOpen) {
      const lightBoxImage = images[activeIndex];
      setImageUrl(URL.createObjectURL(lightBoxImage.file));
    }
  };

  return (
    <>
      <div
        className={classNames(
          classes.root,
          className,
          images.length && 'has-image',
        )}
      >
        <Frame
          className={classNames(
            classes.imageControlButton,

            images.length < 2 && 'hide',
          )}
          clickableIcon={!!images[activeIndex - 1]}
          onIconClicked={() => removeAtImageIndex(activeIndex - 1)}
        >
          <ImageControlButton
            icon={<i className="icon-chevron-left-solid" />}
            className={classes.prevBtn}
            base64={images[activeIndex - 1] && images[activeIndex - 1].base64}
            onClick={() => moveToImageIndex(activeIndex - 1)}
          />
        </Frame>
        {activeIndex > -1 && (
          <Frame
            className={classNames(
              classes.imageDisplayer,
              images === 1 ? 'one-image' : 'more-than-one-image',
            )}
            clickableIcon={!!images[activeIndex]}
            onIconClicked={() => removeAtImageIndex(activeIndex)}
          >
            {images[activeIndex] && (
              <ImageDisplayer
                base64={images[activeIndex] && images[activeIndex].base64}
                onClick={() => handleOpenModal(true)}
              />
            )}
          </Frame>
        )}
        <Frame
          className={classNames(
            classes.imageControlButton,
            images.length < 2 && 'hide',
          )}
          clickableIcon={!!images[activeIndex + 1]}
          onIconClicked={() => removeAtImageIndex(activeIndex + 1)}
        >
          <ImageControlButton
            className={classes.prevBtn}
            icon={<i className="icon-chevron-right-solid" />}
            base64={images[activeIndex + 1] && images[activeIndex + 1].base64}
            onClick={() => moveToImageIndex(activeIndex + 1)}
          />
        </Frame>
        {images.length < maxImages && (
          <ImageAdder
            name={name}
            className={classNames(
              classes.imageAdder,
              images.length > 0 && 'has-image',
            )}
            accept={accept}
            onChange={addNewImage}
          />
        )}
      </div>

      {openModal && images[activeIndex] && (
        <Lightbox
          mainSrc={imageUrl}
          mainSrcThumbnail={imageUrl}
          onCloseRequest={() => handleOpenModal(false)}
        />
      )}
    </>
  );
};

ImagePicker.defaultProps = {
  className: '',
  maxImages: 5,
  value: [],
  accept: 'image/*',
  name: 'images',
  onChange: () => {},
};

ImagePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  maxImages: PropTypes.number,
  value: PropTypes.array,
  accept: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default injectSheet(styles)(ImagePicker);
