let cache = {};
let previousLocale = '';

function saveToCache(key, value) {
  cache[key] = value;
}

function getFromCache(key) {
  return cache[key];
}

function clearCache() {
  cache = {};
}

function translate(obj, locale) {
  const translation = obj.translations.find(item => item.locale === locale);

  if (translation) {
    return translation.propTranslations.reduce(
      (previous, item) => ({ ...previous, [item.prop]: item.value }),
      { ...obj },
    );
  }

  return obj;
}

function objectTranslator(obj, locale) {
  if (!obj || !locale) {
    return obj;
  }

  if (previousLocale !== locale) {
    previousLocale = locale;
    clearCache();
  }

  if (Array.isArray(obj)) {
    const output = obj.map(item => {
      const cachedItem = getFromCache(item.id);

      if (cachedItem) {
        return cachedItem;
      }

      const result = item.translations ? translate(item, locale) : item;

      // Side effect: cached data
      saveToCache(item.id, result);

      return result;
    });

    return output;
  }

  const result = translate(obj, locale);
  saveToCache(obj.id, result);

  return result;
}

export default objectTranslator;
