import { THEME } from '../../../constants';

export default {
  root: {
    paddingTop: 60,
    display: 'flex',
    paddingBottom: 60,
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#E5E5E5',
  },
  coverImage: {
    top: 0,
    left: 0,
    height: '100%',
    position: 'absolute',
  },
  content: {
    zIndex: 1,
    width: '100%',
    maxWidth: 930,
    textAlign: 'right',
    alignItems: 'center',
    color: THEME.colors.primary,
  },
  title: {
    fontSize: 36,
    marginBottom: 35,
    fontWeight: 'bold',
    lineHeight: '49px',
    color: 'currentColor',
  },
  descriptionWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&:nth-of-type(2)': { paddingLeft: 100 },
    '& + $descriptionWrapper': { marginTop: 60 },
  },
  descriptionImage: {
    height: 80,
    minWidth: 80,
    maxWidth: 80,
    marginLeft: 20,
    overflow: 'hidden',
  },
  descriptionText: {
    margin: 0,
    fontSize: 18,
    maxWidth: '100%',
    lineHeight: '25px',
    color: 'currentColor',
  },

  [`@media (max-width: ${THEME.breakpoints.maxXl}px)`]: {
    coverImage: { transform: 'translateX(-70%)' },
    descriptionWrapper: { '&:nth-of-type(2)': { paddingLeft: 0 } },
  },
  [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
    coverImage: { display: 'none' },
    descriptionWrapper: {
      alignItems: 'flex-end',
      flexDirection: 'column-reverse',
    },
    descriptionImage: { marginBottom: 10 },
  },
};
