import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import SectionLayout from '../../../../../components/SectionLayout';
import Button from '../../../../../components/Button';
import Translate from '../../../../../components/Translate';
import clipPathCover from '../../../../../assets/images/clip-path-cover.svg';
import background from
  '../../../../../assets/images/introduce/slide-backgound-01.svg';
import { scrollToElement } from '../../../../../utils';

import styles from './slideOne.styles';

const SlideOne = ({ classes }) => (
  <SectionLayout
    className={classes.root}
    style={{ backgroundImage: `url(${background})` }}
  >
    <img src={clipPathCover} alt="Cover" className={classes.coverImage} />
    <div className={classes.content}>
      <h1 className={classes.title}>
        <Translate id="HOME.INTRODUCE.SLIDER_ONE.TITLE" />
      </h1>
      <p className={classes.description}>
        <Translate id="HOME.INTRODUCE.SLIDER_ONE.DESCRIPTION.ONE" html />
      </p>
      <p className={classes.description}>
        <Translate id="HOME.INTRODUCE.SLIDER_ONE.DESCRIPTION.TWO" html />
      </p>
      <Button className={classes.button} onClick={() => scrollToElement('map')}>
        <Translate id="HOME.INTRODUCE.SLIDER_ONE.BUTTON" />
      </Button>
    </div>
  </SectionLayout>
);

SlideOne.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(SlideOne);
