function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export function gUuidV4() {
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export function toSnakeCase(inputString) {
  return inputString
    .split('')
    .map(character =>
      character === character.toUpperCase()
        ? `_${character.toLowerCase()}`
        : character,
    )
    .join('');
}

export function isEmail(value) {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
}
