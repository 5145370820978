import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import Carousel from '../../../components/Carousel/Carousel';
import Translate from '../../../components/Translate';
import { scrollToElement } from '../../../utils';

import SlideOne from './slides/SlideOne';
// import SlideTwo from './slides/SlideTwo';
// import SlideThree from './slides/SlideThree';
import styles from './introduce.styles';

const Introduce = ({ classes }) => (
  <div id="introduce" className={classes.root}>
    <Carousel>
      <SlideOne />
      <SlideOne />
      <SlideOne />
    </Carousel>
    <div
      role="presentation"
      className={classes.nextSectionBtn}
      onClick={() => scrollToElement('benefits')}
    >
      <div className={classes.nextSectionIcons}>
        <i className="icon-caret-down" />
        <i className="icon-caret-down" />
      </div>
      <p className={classes.nextSectionText}>
        <Translate id="HOME.INTRODUCE.SCROLL_DOWN" />
      </p>
    </div>
  </div>
);

Introduce.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(Introduce);
