import { HOST, FETCHING_TIMEOUT } from '../configs';

const buildURL = (path, host = HOST) => (host ? `${host}/${path}` : path);

const generateOptions = customOptions => {
  const defaultOptions = {
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return { ...defaultOptions, ...customOptions };
};

const fetchWithTimeout = (args, timeout = FETCHING_TIMEOUT) =>
  Promise.race([
    fetch(...args),
    new Promise((_, rj) => setTimeout(() => rj(new Error('Timeout')), timeout)),
  ]);

const handleResponse = response => {
  if (!response.ok) {
    return Promise.reject(response);
  }

  return response.json();
};

/**
 * @function apiGet
 * @description Make a GET request.
 * @param {string} path
 * @param {object} body
 * @param {object} options
 * @returns {promise}
 */
export const apiGet = (path, body, options = {}) =>
  fetchWithTimeout(
    [
      buildURL(path),
      generateOptions({
        ...options,
        method: 'GET',
        body: !options.keepBody ? JSON.stringify(body) : body,
      }),
    ],
    options.timeout,
  )
    .then(handleResponse)
    .catch(handleResponse);

/**
 * @function apiPost
 * @description Make a POST request.
 * @param {string} path
 * @param {object} body
 * @param {object} options
 * @returns {promise}
 */
export const apiPost = (
  path,
  body,
  options = { keepBody: false, timeout: undefined },
  keepRawResponse = false,
) =>
  fetchWithTimeout(
    [
      buildURL(path),
      generateOptions({
        ...options,
        method: 'POST',
        body: !options.keepBody ? JSON.stringify(body) : body,
      }),
    ],
    options.timeout,
  )
    .then(response => (keepRawResponse ? response : handleResponse(response)))
    .catch(error => (keepRawResponse ? error : handleResponse(error)));

/**
 * @function apiPut
 * @description Make a PUT request.
 * @param {string} path
 * @param {object} body
 * @param {object} options
 * @returns {promise}
 */
export const apiPut = (path, body, options = {}, host = HOST) =>
  fetchWithTimeout(
    [
      buildURL(path, host),
      generateOptions({
        ...options,
        method: 'PUT',
        body: JSON.stringify(body),
      }),
    ],
    options.timeout,
  )
    .then(handleResponse)
    .catch(handleResponse);

export const paramsBuilder = (paramsObject = {}) =>
  Object.keys(paramsObject).reduce(
    (paramsString, key) =>
      `${(!paramsString && '?') || `${paramsString}&`}${encodeURIComponent(
        key,
      )}=${encodeURIComponent(
        typeof paramsObject[key] !== 'object'
          ? paramsObject[key]
          : JSON.stringify(paramsObject[key]),
      )}`,
    '',
  );

const fetchCategories = () => apiGet('faq-categories');
const fetchArticlesByCategorySlug = slug =>
  apiGet(`faq-categories/find-by-slug/${slug}/faqs`);
const fetchArticleById = id => apiGet(`faqs/${id}`);

export default {
  get: apiGet,
  post: apiPost,
  put: apiPut,
  // Export APIs.
  fetchCategories,
  fetchArticlesByCategorySlug,
  fetchArticleById,
};
