import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import logo from '../../../assets/images/letsparkit-spinner.svg';
import styles from './spinner.styles';

const Spinner = ({ classes, className }) => (
  <div className={classes.root}>
    <object
      type="image/svg+xml"
      data={logo}
      className={classNames(classes.spinner, className)}
      aria-label="Lets Park It spinner"
    />
  </div>
);

Spinner.defaultProps = {
  className: '',
};

Spinner.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default injectSheet(styles)(Spinner);
