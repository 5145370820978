import { THEME } from '../../../constants';

export default {
  root: {
    background: '#fff',
    padding: [72, 27],
    boxShadow: '0px 4px 9px rgba(15, 152, 213, 0.1)',
    display: 'flex',
    justifyContent: 'space-between',
    margin: [35, 0, 40],
  },
  informations: {
    paddingRight: 20,
  },
  informationTitle: {
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '33px',
    color: '#000',
    margin: [43, 0, 29],
  },
  informationSubTitle: {
    fontSize: 18,
    lineHeight: '25px',
    color: '#0F98D5',
    marginBottom: 67,
  },
  label: {
    fontSize: 18,
    lineHeight: '25px',
    color: '#0F98D5',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  value: {
    fontSize: 18,
    lineHeight: '25px',
    color: '#000',
    marginBottom: 30,
    '&:hover': {
      color: '#3c3c3c',
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
  form: {
    maxWidth: 572,
  },
  socials: { display: 'flex', marginBottom: 30 },
  socialIcon: {
    maxHeight: 22,
    display: 'block',
    '& [class^=icon-]': { fontSize: 22, color: '#0F98D5' },
    '& + $socialIcon': { marginLeft: 10 },
  },

  [`@media (max-width: ${THEME.breakpoints.maxMd}px)`]: {
    root: {
      padding: 27,
      flexDirection: 'column',
    },
    informations: { marginBottom: 25, padding: 0 },
    form: { margin: 'auto' },
  },
  [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
    root: {
      backgroundColor: 'transparent',
      padding: 0,
      boxShadow: 'none',
      marginTop: 0,
    },
    informationTitle: { margin: [20, 0, 10] },
    informationSubTitle: { marginBottom: 30 },
    value: { marginBottom: 15 },
  },
};
