import { apiGet, apiPost } from './api';

const endpoint = 'prospects';

/**
 * Create a prospect
 * @param {CreateProspectCommand} params Create prospect object.
 * @returns {Promise<{ id: string }>}
 */
const create = params =>
  apiPost(endpoint, params, {
    credentials: 'include',
  });

const fetchAll = () => apiGet(endpoint);

/**
 * Upload image.
 * @param {{ file: File, bucket?: string }} params Image params
 * @returns {Promise<void>}
 */
const uploadImage = async params => {
  const { file, folder } = params;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('filename', file.name);
  formData.append('folder', folder);

  const result = await apiPost(
    `${endpoint}/image`,
    formData,
    {
      headers: {
        Accept: 'application/json',
      },
      keepBody: true,
      credentials: 'include',
    },
    true,
  );

  return result;
};

export default {
  create,
  uploadImage,
  fetchAll,
};

/**
 * @typedef CreateProspectCommand
 * @type {object}
 * @property {string} email Email.
 * @property {number} [lat] Latitude.
 * @property {number} [lng] Longitude.
 * @property {object} [details] Prospect details.
 */
