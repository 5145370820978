import { takeLatest, put, all, call } from 'redux-saga/effects';

import { prospectApi } from '../../utils';
import {
  showErrorNotification,
} from '../NotificationManager/notificationManager.duck';
import {
  CONTACT_SUBMIT,
  submitContactFailed,
  submitContactSuccess,
} from './contact.duck';

function* submitContactFlow() {
  yield takeLatest(CONTACT_SUBMIT, function* onSubmitContact({ payload }) {
    try {
      const { email, ...details } = payload;

      yield call(prospectApi.create, {
        email,
        details,
      });
      yield put(submitContactSuccess());
    } catch (error) {
      yield put(showErrorNotification('API.REQUEST.FAILED'));
      yield put(submitContactFailed({ error }));
    }
  });
}

export default function* contactSaga() {
  yield all([submitContactFlow()]);
}
