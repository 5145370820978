import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import Translate from '../../../../components/Translate';

import styles from './banner.styles';

const Banner = ({ classes, className, onClick }) => (
  <div className={classNames(classes.root, className)}>
    <p className={classes.title}>
      <Translate id="HOME.MAP.BANNER.TITLE.ONE" />
      &nbsp;
      <span className={classes.count}>365K</span>
      &nbsp;
      <Translate id="HOME.MAP.BANNER.TITLE.TWO" />
    </p>
    <button className={classes.button} onClick={onClick}>
      <Translate id="HOME.MAP.BANNER.BUTTON" />
    </button>
    <p className={classes.description}>
      <span><Translate id="HOME.MAP.BANNER.DESCRIPTION.ONE" /></span>
      <span><Translate id="HOME.MAP.BANNER.DESCRIPTION.TWO" html /></span>
    </p>
  </div>
);

Banner.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
Banner.defaultProps = {
  className: '',
  onClick: () => {},
};

export default injectSheet(styles)(Banner);
