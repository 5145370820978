import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import styles from './imageDisplayer.styles';

const ImageDisplayer = ({ className, classes, base64, onClick }) => (
  <div
    className={classNames(classes.root, className)}
    role="presentation"
    onClick={onClick}
    style={{ backgroundImage: `url(${base64})` }}
  />
);

ImageDisplayer.defaultProps = {
  className: '',
  onClick: () => {},
};

ImageDisplayer.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  base64: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default injectSheet(styles)(ImageDisplayer);
