import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import styles from './sectionLayout.styles';

/**
 * This component is used for sections following
 * general layout includes padding and min-height full screen style
 */
const SectionLayout = ({ classes, className, children, ...rest }) => (
  <div className={classNames(classes.root, className)} {...rest}>
    {children}
  </div>
);

SectionLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
SectionLayout.defaultProps = { className: '' };
export default injectSheet(styles)(SectionLayout);
