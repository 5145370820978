import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import SectionLayout from '../../components/SectionLayout';
import Translate from '../../components/Translate';
import SectionTitle from '../../components/SectionTitle';

import Home from './Home';
import LeafletMap from './LeafletMap';
import styles from './contact.styles';

const Contact = ({ classes }) => (
  <div className={classes.root}>
    <SectionTitle>
      <Translate id="APP.NAVBAR.CONTACT" />
    </SectionTitle>
    <SectionLayout>
      <Home />
    </SectionLayout>
    <SectionLayout id="lets-park-it-address" className={classes.map}>
      <LeafletMap />
    </SectionLayout>
  </div>
);

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(Contact);
