import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import Translate from '../../../components/Translate';

import Item from './Item';
import styles from './leftMenu.styles';

const LeftMenu = ({
  classes,
  className,
  data,
  activeSlug,
  expandedSlug,
  onExpand,
  parentMenuItemClicked,
  subMenuItemClicked,
}) => (
  <ul className={classNames(classes.root, className)}>
    <li className={classes.item}>
      <Translate id="FAQ.LEFT_MENU.LABEL" />
    </li>
    {data.map(item => (
      <li
        role="presentation"
        key={`faq-${item.slug}`}
        className={classNames(classes.item, classes.smallMarginTop)}
      >
        <Item
          className={classNames(activeSlug === item.slug && classes.itemActive)}
          iconClassName={classNames(
            item.children &&
              item.children.length &&
              ((expandedSlug === item.slug && 'icon-line') || 'icon-plus'),
          )}
          item={item}
          onIconClicked={() => onExpand(item)}
          onItemClicked={() => parentMenuItemClicked(item)}
        />
        {expandedSlug === item.slug ? (
          <ul className={classes.subMenuItems}>
            {item.children.map(child => (
              <li className={classes.smallMarginTop} key={child.id}>
                <Item
                  item={child}
                  onItemClicked={() => subMenuItemClicked(child)}
                />
              </li>
            ))}
          </ul>
        ) : null}
      </li>
    ))}
  </ul>
);

LeftMenu.defaultProps = {
  className: '',
  activeSlug: undefined,
  expandedSlug: undefined,
  onExpand: () => {},
  parentMenuItemClicked: () => {},
  subMenuItemClicked: () => {},
};
LeftMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string,
    }),
  ).isRequired,
  activeSlug: PropTypes.string,
  expandedSlug: PropTypes.string,
  onExpand: PropTypes.func,
  parentMenuItemClicked: PropTypes.func,
  subMenuItemClicked: PropTypes.func,
};

export default injectSheet(styles)(LeftMenu);
