import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import SectionLayout from '../../../components/SectionLayout';
import Translate from '../../../components/Translate';
import generateId from '../../../utils/generateId';
import backgroundCover from
  '../../../assets/images/benefits/background-cover.png';
import parkImage from '../../../assets/images/benefits/park.svg';
import locationImage from '../../../assets/images/benefits/location.svg';
import cardImage from '../../../assets/images/benefits/card.svg';

import styles from './benefits.styles';
import { scrollToElement } from '../../../utils';

const benefits = [
  {
    image: { alt: 'Park', src: parkImage },
    description: <Translate id="HOME.BENEFITS.DESCRIPTION.PARK" html />,
  },
  {
    image: { alt: 'Location', src: locationImage },
    description: (
      <i>
        <Translate id="HOME.BENEFITS.DESCRIPTION.LOCATION.ONE" />
        &nbsp;
        <span
          style={{ cursor: 'pointer' }}
          role="presentation"
          onClick={() => scrollToElement('map')}
        >
          <u>
            <b>
              <i>
                <Translate id="HOME.BENEFITS.DESCRIPTION.LOCATION.TWO" />
              </i>
            </b>
          </u>
        </span>
        &nbsp;
        <Translate id="HOME.BENEFITS.DESCRIPTION.LOCATION.THREE" />
      </i>
    ),
  },
  {
    image: { alt: 'Card', src: cardImage },
    description: <Translate id="HOME.BENEFITS.DESCRIPTION.CARD" html />,
  },
];

const Benefits = ({ classes }) => (
  <SectionLayout id="benefits" className={classes.root}>
    <img src={backgroundCover} alt="Cover" className={classes.coverImage} />
    <div className={classes.content}>
      <h1 className={classes.title}>
        <Translate id="HOME.BENEFITS.TITLE" />
      </h1>
      {benefits.map(item => (
        <div key={generateId()} className={classes.descriptionWrapper}>
          <p className={classes.descriptionText}>{item.description}</p>
          <img
            alt="cover"
            {...item.image}
            className={classes.descriptionImage}
          />
        </div>
      ))}
    </div>
  </SectionLayout>
);

Benefits.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default injectSheet(styles)(Benefits);
