import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import SectionLayout from '../../../components/SectionLayout';
import Button from '../../../components/Button';
import background from '../../../assets/images/letsparkit/background.svg';
import { scrollToElement } from '../../../utils';

import styles from './letsparkit.styles';
import Translate from '../../../components/Translate';

const Letsparkit = ({ classes }) => (
  <SectionLayout
    id="letsparkit"
    className={classes.root}
    style={{ backgroundImage: `url(${background})` }}
  >
    <p className={classes.title}>
      <Translate id="HOME.LETSPARKIT.TITLE" html />
    </p>
    <Button onClick={() => scrollToElement('map')}>
      <Translate id="HOME.LETSPARKIT.BUTTON" />
    </Button>
  </SectionLayout>
);

Letsparkit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(Letsparkit);
