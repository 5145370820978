import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import carImage from '../../assets/images/car.svg';
import { scrollToPosition } from '../../utils';

import styles from './scrollTopButton.styles';

const ScrollTopButton = ({ classes }) => (
  <span
    role="presentation"
    className={classes.scrollTopBtn}
    onClick={() => scrollToPosition(0)}
  >
    <img alt="To top" src={carImage} />
  </span>
);

ScrollTopButton.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default injectSheet(styles)(ScrollTopButton);
