import { THEME } from './constants';

export default {
  root: { paddingTop: 100 },

  [`@media (max-width: ${THEME.breakpoints.maxLg}px)`]: {
    root: { paddingTop: 130 },
  },
  [`@media (max-width: ${THEME.breakpoints.maxMd}px)`]: {
    root: { paddingTop: 110 },
  },
  [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
    root: { paddingTop: 80 },
  },
};
