import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import Translate from '../../../components/Translate';

import styles from './useful.styles';

const Useful = ({ classes }) => (
  <div className={classes.useful}>
    <p className={classes.question}>
      <Translate id="FAQ.USEFUL.HAVE_QUESTION" html />
    </p>
  </div>
);

Useful.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(Useful);
