import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import styles from './articleDetails.styles';

const ArticleDetails = ({ classes, className, article }) => (
  <article className={classNames(classes.root, className)}>
    <h1 className={classes.title}>{article.title}</h1>
    <div
      className={classes.content}
      dangerouslySetInnerHTML={{
        __html: article.content,
      }}
    />
  </article>
);

ArticleDetails.defaultProps = {
  className: '',
};

ArticleDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  article: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default injectSheet(styles)(ArticleDetails);
