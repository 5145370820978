import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { DEFAULT_LOCALE } from '../configs';

const initialState = {
  // TODO: We should init this value from saga, but seem we need to render some
  // items on UI first, so locale need to be provided at the beginning.
  locale: DEFAULT_LOCALE,

  isLocaleChanging: false,
  isLocaleChangingError: null,
};

const PREFIX = 'LOCALE';

export const LOCALE_INITIALIZE = `${PREFIX}/INITIALIZE`;

export const LOCALE_CHANGE = `${PREFIX}/CHANGE`;
export const LOCALE_CHANGE_SUCCESS = `${LOCALE_CHANGE}/SUCCESS`;
export const LOCALE_CHANGE_FAILED = `${LOCALE_CHANGE}/FAILED`;

export const initializeLocale = createAction(LOCALE_INITIALIZE);
export const changeLocale = createAction(LOCALE_CHANGE);
export const changeLocaleSuccess = createAction(LOCALE_CHANGE_SUCCESS);
export const changeLocaleFailed = createAction(LOCALE_CHANGE_FAILED);

export const getLocaleState = state => state.locale;

export const getLocale = createSelector(
  getLocaleState,
  state => state.locale,
);

export const getLanguageByLocale = createSelector(
  getLocale,
  locale => locale.split('_')[0],
);

export const getCountryCodeByLocale = createSelector(
  getLocale,
  locale => locale.split('_')[1],
);

export default handleActions(
  new Map([
    [
      initialState,
      state => ({
        ...state,
        isLocaleChanging: true,
        isLocaleChangingError: null,
      }),
    ],
    [
      changeLocaleSuccess,
      (state, { payload }) => ({
        ...state,
        locale: payload,
        isLocaleChanging: false,
        isLocaleChangingError: null,
      }),
    ],
    [
      changeLocaleFailed,
      (state, { payload: { error } }) => ({
        ...state,
        isLocaleChanging: false,
        isLocaleChangingError: error,
      }),
    ],
  ]),
  { ...initialState },
);
