import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Switch } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import TopContacts from './components/TopContacts';
import NavigationBar from './components/NavigationBar';
import Footer from './containers/Footer';
import { ROUTES } from './constants';
import generateRoutes from './utils/generateRoutes';
import styles from './app.styles';
import { changeLocale, getLocale, initializeLocale } from './ducks/locale.duck';

const App = ({ classes }) => {
  const dispatch = useDispatch();
  const currentLocale = useSelector(getLocale);

  useEffect(() => {
    dispatch(initializeLocale());
  }, []);

  return (
    <div className={classes.root}>
      <TopContacts />
      <NavigationBar
        activeLocale={currentLocale}
        localeChanged={locale => dispatch(changeLocale(locale))}
      />
      <Switch>{generateRoutes(ROUTES)}</Switch>
      <Footer />
    </div>
  );
};

App.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default injectSheet(styles)(App);
