import { THEME } from '../../constants';

const imagePickerMaxWidth = 500;

/**
 * When there is at least 1 image, the big image in middle will be scaled down
 * base on other elements that are displaying on its two-side.
 */
const sideElementSize = 30;

export default {
  root: {
    width: '100%',
    maxWidth: imagePickerMaxWidth,
    height: '100%',
    maxHeight: 300,
    position: 'relative',
    display: 'flex',
    backgroundColor: '#FEFEFE',

    '&.has-image': {
      position: 'static',
    },
  },

  imageDisplayer: {
    height: '100%',
    flexGrow: 1,
    '&.one-image': {
      flexBasis: `calc(100% - ${sideElementSize}px)`,
    },
    '&.more-than-one-image': {
      flexBasis: `calc(100% - ${3 * sideElementSize}px)`,
    },
  },
  imageControlButton: {
    '&.hide': {
      display: 'none',
    },
    height: '100%',
    width: sideElementSize,
    fontSize: '2rem',
  },
  prevBtn: {
    color: THEME.colors.primary,
  },
  nextBtn: {
    color: THEME.colors.primary,
  },
  imageAdder: {
    '&.has-image': {
      width: sideElementSize,
      position: 'static',
      '& > span': {
        width: sideElementSize,
        height: sideElementSize,
      },
    },
  },

  [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
    root: {
      // display: 'block',
      flexDirection: 'column',
    },
    imageDisplayer: {
      // height: `cacl(100% - ${  })`,
      flexGrow: 1,
      '&.one-image': {
        height: `calc(100% - ${sideElementSize}px)`,
      },
      '&.more-than-one-image': {
        height: `calc(100% - ${3 * sideElementSize}px)`,
      },
    },
    imageControlButton: {
      width: '100%',
      height: sideElementSize,

      '& i': { display: 'block', transform: 'rotate(90deg)' },
    },
    imageAdder: {
      '&.has-image': {
        marginBottom: 0,
        width: '100%',
        height: sideElementSize,
      },
    },
  },
};
