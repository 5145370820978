export default {
  root: {
    position: 'relative',
    height: '100%',
    '&:hover': {
      '& $overlay': {
        opacity: 1,
      },
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    width: '100%',
    opacity: 0.6,
    transition: '.2s ease',
    backgroundColor: '#E5E5E5',
  },
  controlBtn: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundSize: 'cover',
    // backgroundColor: '#FEFEFE',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',


  },
};
