import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// notification is an object of
//  - message: Content of the notification.
//  - type: based on supporting types of react-toast-notifications package.
const initialState = {
  notification: null,
};

const PREFIX = 'NOTIFICATION_MANAGER';

const NOTIFICATION_SHOW = `${PREFIX}/SHOW`;
const NOTIFICATION_ERROR_SHOW = `${PREFIX}/ERROR/SHOW`;
const NOTIFICATION_SUCCESS_SHOW = `${PREFIX}/SUCCESS/SHOW`;

export const showNotification = createAction(NOTIFICATION_SHOW);
// Helpers action.
export const showErrorNotification = createAction(NOTIFICATION_ERROR_SHOW);
export const showSuccessNotification = createAction(NOTIFICATION_SUCCESS_SHOW);

export const getNotificationManagerState = state => state.notificationManager;
export const getNotification = createSelector(
  getNotificationManagerState,
  state => state.notification,
);

export default handleActions(
  new Map([
    [
      showNotification,
      (state, { payload }) => ({
        ...state,
        notification: payload,
      }),
    ],
    [
      showSuccessNotification,
      (state, { payload }) => ({
        ...state,
        notification: {
          message: payload,
          type: 'success',
        },
      }),
    ],
    [
      showErrorNotification,
      (state, { payload }) => ({
        ...state,
        notification: {
          message: payload,
          type: 'error',
        },
      }),
    ],
  ]),
  { ...initialState },
);
