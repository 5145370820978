export default {
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundColor: '#FEFEFE',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    '&:hover': {
      cursor: 'pointer',
    },
  },
};
