import { THEME } from '../../../../constants';

export default {
  root: {},
  inputWrapper: {
    height: 40,
    width: '100%',
    borderRadius: 4,
    display: 'flex',
    padding: [0, 12],
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#FEFEFE',
    '& .react-datepicker-wrapper': {
      width: '100%',
      cursor: 'pointer',
      '& input': {
        fontSize: 13,
        width: '100%',
        height: '100%',
        border: 'none',
        color: '#3E3E3E',
        textAlign: 'right',
        '&:focus': { outline: 'none' },
      },
    },
  },
  label: {
    fontSize: 13,
    color: '#3E3E3E',
    fontWeight: 'bold',
    lineHeight: '18px',
    whiteSpace: 'nowrap',
    margin: [0, 10, 0, 0],
    '& ~ $placeholder': { right: 18 },
  },
  placeholder: {
    top: '50%',
    fontSize: 12,
    color: '#979EA4',
    lineHeight: '16px',
    position: 'absolute',
    pointerEvents: 'none',
    transform: 'translateY(-50%)',
  },

  datepicker: {
    display: 'flex',
    alignItems: 'center',
  },

  datepickerIcon: {
    marginLeft: '0.5rem',
    color: THEME.colors.primary,
  },
};
