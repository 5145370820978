import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router';
import {
  scrollToElement,
  isElementInViewport,
  useEventListener,
} from '../../utils';
import ScrollTopButton from '../../components/ScrollTopButton';

import Introduce from './Introduce';
import Benefits from './Benefits';
import Map from './Map';
import ComingSoon from './ComingSoon';
import Contact from './Contact';
import Letsparkit from './Letsparkit';
import { fetchAllProspects } from './home.duck';
import Zalo from '../Zalo';

const ids = [
  'introduce',
  'benefits',
  'map',
  'comming-soon',
  'contact',
  'letsparkit',
];

const Home = () => {
  const [isShownScrollTopButton, showScrollTopButton] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllProspects());
  }, []);

  useEffect(() => {
    const eventListener = debounce(() => {
      if (isElementInViewport('footer')) {
        return;
      }
      let min = null;
      let nextId = null;
      ids.forEach(id => {
        const element = document.getElementById(id);
        if (!element) return;
        const rectTop = element.getBoundingClientRect().top;
        if (!min) {
          min = Math.abs(rectTop);
          nextId = id;
        } else if (Math.abs(rectTop) < min) {
          // Hacking: by default behavior of browser,
          // when user click on links such as : "/#benefits", "/#coming-soon"
          // The browser will say rectTop=0 (it ignores the header)
          // so we need to check that case and override it with header height.
          min =
            location.hash.replace('#', '') === id && rectTop === 0
              ? 130
              : Math.abs(rectTop);

          nextId = id;
        }
      });
      if (nextId) {
        scrollToElement(nextId);
      }
    }, 400);

    if (window.innerWidth > 576) {
      window.addEventListener('scroll', eventListener);
    }

    return () => {
      window.removeEventListener('scroll', eventListener);
    };
  }, []);

  useEventListener('scroll', () => {
    const top = window.pageYOffset || document.documentElement.scrollTop;
    const element = document.getElementById(ids[0]);
    if (!element) return;
    const elRect = element.getBoundingClientRect();
    const firstSectionTop = elRect.top + elRect.height;
    showScrollTopButton(top >= firstSectionTop);
  });

  return (
    <>
      <Introduce />
      <Benefits />
      <Map />
      <ComingSoon />
      <Contact />
      <Letsparkit />
      {isShownScrollTopButton && <ScrollTopButton />}
      <Zalo />
    </>
  );
};

export default Home;
