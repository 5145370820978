import { takeLatest, put, call, all } from 'redux-saga/effects';
import { prospectApi } from '../../utils';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../NotificationManager/notificationManager.duck';

import {
  createSubscriberFailed,
  createSubscriberSuccess,
  SUBSCRIBER_CREATE,
} from './footer.duck';

function* createSubscriberFlow() {
  yield takeLatest(SUBSCRIBER_CREATE, function* onCreateSubscriber({
    payload,
  }) {
    try {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(payload)) {
        yield put(showErrorNotification('FORM.ERROR.EMAIL.INVALID'));
        yield put(createSubscriberFailed({ error: 'FORM.VALIDATION.FAILED' }));

        return;
      }

      yield call(prospectApi.create, { email: payload });
      yield put(showSuccessNotification('API.REQUEST.SUCCESS'));
      yield put(createSubscriberSuccess());
    } catch (error) {
      yield put(showErrorNotification('API.REQUEST.FAILED'));
      yield put(createSubscriberFailed({ error }));
    }
  });
}

export default function* footerSaga() {
  yield all([createSubscriberFlow()]);
}
