import { takeLatest, put, call, all } from 'redux-saga/effects';

import {
  CATEGORIES_FETCH,
  fetchCategoriesFailed,
  fetchCategoriesSuccess,
} from '../ducks/categories.duck';

import api from '../utils/api';

function* fetchCategoriesFlow() {
  yield takeLatest(CATEGORIES_FETCH, function* onFetchCategories() {
    try {
      const categories = yield call(api.fetchCategories);

      yield put(fetchCategoriesSuccess({ categories }));
    } catch (error) {
      yield put(fetchCategoriesFailed({ error }));
    }
  });
}

export default function* categoriesFlow() {
  yield all([fetchCategoriesFlow()]);
}
