import React, { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';

import Translate from '../../components/Translate';
import { getNotification } from './notificationManager.duck';

export const NotificationManager = () => {
  const { addToast } = useToasts();
  const notification = useSelector(getNotification);

  useEffect(() => {
    if (notification) {
      const { message, type } = notification;
      addToast(<Translate id={message} />, {
        appearance: type,
        autoDismiss: true,
      });
    }
  }, [notification]);

  return null;
};

export default NotificationManager;
