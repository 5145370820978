import { THEME } from '../../constants';

export default {
  scrollTopBtn: {
    bottom: 150,
    zIndex: 999,
    right: 49,
    width: 36,
    height: 78,
    cursor: 'pointer',
    position: 'fixed',
  },

  [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
    scrollTopBtn: { right: 10, bottom: 80 },
  },
};
