import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

const initialState = {
  subscriberCreating: false,
  subscriberCreatingError: null,
  subscriberCreated: false,
};

const PREFIX = 'FOOTER';

export const SUBSCRIBER_CREATE = `${PREFIX}/CREATE`;
export const SUBSCRIBER_CREATE_SUCCESS = `${SUBSCRIBER_CREATE}/SUCCESS`;
export const SUBSCRIBER_CREATE_FAILED = `${SUBSCRIBER_CREATE}/FAILED`;

export const createSubscriber = createAction(SUBSCRIBER_CREATE);
export const createSubscriberSuccess = createAction(SUBSCRIBER_CREATE_SUCCESS);
export const createSubscriberFailed = createAction(SUBSCRIBER_CREATE_FAILED);

export const footerStateSelector = state => state.footer;
export const subscriberCreatingSelector = createSelector(
  footerStateSelector,
  state => state.subscriberCreating,
);
export const subscriberCreatedSelector = createSelector(
  footerStateSelector,
  state => state.subscriberCreated,
);

export default handleActions(
  new Map([
    [
      createSubscriber,
      state => ({
        ...state,
        subscriberCreating: true,
        subscriberCreated: false,
        subscriberCreatingError: null,
      }),
    ],
    [
      createSubscriberSuccess,
      state => ({
        ...state,
        subscriberCreating: false,
        subscriberCreated: true,
        subscriberCreatingError: null,
      }),
    ],
    [
      createSubscriberFailed,
      (state, { payload: { error } }) => ({
        ...state,
        subscriberCreating: false,
        subscriberCreated: false,
        subscriberCreatingError: error,
      }),
    ],
  ]),
  { ...initialState },
);
