import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import styles from './localeSwitcher.styles';

const LocaleSwitcher = ({
  className,
  classes,
  items,
  activeLocale,
  localeChanged,
}) => {
  const handleLocaleChanged = locale => {
    if (activeLocale !== locale) {
      localeChanged(locale);
    }
  };

  return (
    <ol className={classNames(classes.root, className)}>
      {items.map((item, index) => (
        <li
          className={classNames(classes.localeWrapper)}
          key={item.locale}
          role="presentation"
          onClick={() => handleLocaleChanged(item.locale)}
        >
          <span
            className={classNames(
              classes.locale,
              item.locale === activeLocale && 'active',
            )}
          >
            {item.name}
          </span>
          {index < items.length - 1 && (
            <span className={classes.divider}>/</span>
          )}
        </li>
      ))}
    </ol>
  );
};

LocaleSwitcher.defaultProps = {
  className: '',
  items: [
    {
      name: 'EN',
      locale: 'en_US',
    },
    {
      name: 'VI',
      locale: 'vi_VN',
    },
  ],
};

LocaleSwitcher.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      locale: PropTypes.string.isRequired,
    }),
  ),
  activeLocale: PropTypes.string.isRequired,
  localeChanged: PropTypes.func.isRequired,
};

export default injectSheet(styles)(LocaleSwitcher);
