export default {
  'APP.NAVBAR.WHAT_LETSPARKIT': 'Let’s Park It là gì?',
  'APP.NAVBAR.BENEFITS': 'Lợi ích khi tham gia?',
  'APP.NAVBAR.ARE_YOU_READY': 'Bạn đã sẵn sàng?',
  'APP.NAVBAR.NEWS': 'Tin tức',
  'APP.NAVBAR.QA': 'Câu hỏi thường gặp',
  'APP.NAVBAR.TUTORIAL_AND_SUPPORT': 'Hướng dẫn và hỗ trợ',
  'APP.NAVBAR.CONTACT': 'Liên hệ',
  'HOME.INTRODUCE.SCROLL_DOWN': 'CUỘN XUỐNG ĐỂ TÌM HIỂU THÊM',
  'HOME.INTRODUCE.SLIDER_ONE.TITLE':
    'Kiếm tiền ngay cả khi bạn đang nghỉ ngơi?',
  'HOME.INTRODUCE.SLIDER_ONE.DESCRIPTION.ONE':
    'Với một chiếc smart phone, một khoảng trống tối thiểu 3x5.5m và chi phí gần như bằng <b>0</b>, những gì bạn cần làm là nghỉ ngơi, thư giãn và kiểm tra khi tiền vào tài khoản.',
  'HOME.INTRODUCE.SLIDER_ONE.DESCRIPTION.TWO':
    '<i>Ngừng phung phí các khoảng trống có khả năng <b>kiếm tiền tự động</b> trong nhà bạn và bắt tay vào việc nghỉ hưu sớm</i>',
  'HOME.INTRODUCE.SLIDER_ONE.BUTTON': 'NGAY BÂY GIỜ',
  'HOME.MAP.BANNER.TITLE.ONE': 'HIỆN ĐÃ CÓ',
  'HOME.MAP.BANNER.TITLE.TWO':
    'NGƯỜI ĐÃ ĐĂNG KÝ CHO THUÊ VÀ ĐANG KHÔNG NGỪNG TĂNG LÊN',
  'HOME.MAP.BANNER.BUTTON': 'ĐĂNG KÝ NGAY',
  'HOME.MAP.BANNER.DESCRIPTION.ONE':
    'Và bạn sẽ nằm trong danh sách ưu tiên tìm kiếm.',
  'HOME.MAP.BANNER.DESCRIPTION.TWO':
    'Cơ hội để được sở hữu đặc quyền ưu tiên hạng nhất. <b>Đừng bỏ lỡ!</b>',
  'HOME.MAP.FORM.EMAIL': 'Email',
  'HOME.MAP.FORM.CAPACITY': 'Sức chứa',
  'HOME.MAP.FORM.BLANK': 'Chỗ trống',
  'HOME.MAP.FORM.SIZE': 'Kích cỡ',
  'HOME.MAP.FORM.SIZE.WIDTH': 'Rộng',
  'HOME.MAP.FORM.SIZE.HEIGHT': 'Dài',
  'HOME.MAP.FORM.WEIGHT': 'Tải trọng',
  'HOME.MAP.FORM.FROM_DATE': 'Trống từ ngày',
  'HOME.MAP.FORM.TO_DATE': 'Đến ngày',
  'HOME.MAP.FORM.DESCRIPTION': 'Mô tả',
  'HOME.MAP.SEARCH.PLACEHOLDER':
    'Số nhà đường, khu phố, phường, quận, thành phố',
  'HOME.MAP.SEARCH.BUTTON.SEARCH': 'Tìm kiếm',
  'HOME.MAP.SEARCH.BUTTON.GEOLOCATION': 'Địa chỉ hiện tại',
  'HOME.MAP.SEARCH.BUTTON.SHOWING_ALL': 'Hiển thị mọi địa điểm',
  'HOME.MAP.SEARCH.BUTTON.SHOWING_MINE': 'Chỉ hiển thị các địa điểm của tôi',
  'HOME.MAP.FORM.COMPLETE_SUBMISSION.MESSAGE':
    'Cám ơn bạn đã đăng kí với chúng tôi',
  'HOME.MAP.FORM.COMPLETE_SUBMISSION.BUTTON_TEXT': 'Hoàn tất',
  'HOME.BENEFITS.TITLE': 'ỨNG DỤNG CHO THUÊ BÃI ĐỖ XE THÔNG MINH',
  'HOME.BENEFITS.DESCRIPTION.PARK':
    'LET&apos;S PARK IT là một ứng dụng cho thuê <b>bãi đỗ xe</b> thông minh đáp ứng được các tiêu chí khắc khe về trải nghiệm người dùng, độ bảo mật, tin cậy và tốc độ xử lý cũng như quy trình đơn giản, nhanh chóng.',
  'HOME.BENEFITS.DESCRIPTION.LOCATION.ONE':
    'Không còn tốn thời gian để quản lý bãi đỗ xe hay lo lắng về thu nhập hàng tháng. Tất cả những gì bạn cần làm là',
  'HOME.BENEFITS.DESCRIPTION.LOCATION.TWO': 'đăng tin cho thuê',
  'HOME.BENEFITS.DESCRIPTION.LOCATION.THREE': 'và tiền sẽ tự chảy vào túi bạn.',
  'HOME.BENEFITS.DESCRIPTION.CARD':
    '<i>Dễ dàng có được khoản thu nhập thụ động ổn định với mức hoa hồng bằng 0</i>',
  'HOME.COMING_SOON.TEXT.ONE': 'Ứng dụng bãi đỗ xe thông minh',
  'HOME.COMING_SOON.TEXT.TWO': 'Sắp ra mắt',
  'HOME.COMING_SOON.TEXT.THREE': 'Cùng chờ đợi!',
  'HOME.CONTACT.TITLE': 'LIÊN HỆ VỚI CHÚNG TÔI',
  'HOME.CONTACT.SUB_TITLE':
    ' Hãy để lại tin nhắn cho chúng tôi về các vấn đề của bạn. Chúng tôi sẽ liên hệ lại sớm nhất có thể.',
  'HOME.CONTACT.FORM.TITLE': 'THÔNG TIN LIÊN HỆ',
  'HOME.CONTACT.FORM.DESCRIPTION': 'Để lại lời nhắn cho chúng tôi',
  'HOME.CONTACT.FORM.NAME': 'Họ tên',
  'HOME.CONTACT.FORM.EMAIL': 'Email',
  'HOME.CONTACT.FORM.PHONE_NUMBER': 'Số điện thoại',
  'HOME.CONTACT.FORM.MESSAGE': 'Lời nhắn',
  'HOME.CONTACT.FORM.BUTTON.SUBMIT': 'GỬI',
  'HOME.LETSPARKIT.TITLE': 'Tự do tài chính với <br /><b>letsparkit</b>',
  'HOME.LETSPARKIT.BUTTON': 'NGAY BÂY GIỜ',
  'CONTACT.HOME.FORM.SUBTITLE':
    'Xin vui lòng liên hệ với chúng tôi nếu bạn có bất kỳ câu hỏi nào!',
  'CONTACT.HOME.TITLE': 'Liên Hệ Với Chúng Tôi',
  'CONTACT.HOME.SUB_TITLE':
    'Cùng nhau nói chuyện kinh doanh hoặc chỉ uống cà phê với nhau.<br />Chúng tôi rất mong nhận được hồi âm từ bạn.',
  'CONTACT.HOME.ADDRESS.LABEL': 'ĐỊA CHỈ',
  'CONTACT.HOME.ADDRESS.CONTENT': '212 Nguyễn Đình Chiểu, Phường 6, Quận 3, TP. HCM',
  'CONTACT.HOME.PHONE_NUMBER.LABEL': 'SỐ ĐIỆN THOẠI',
  'CONTACT.HOME.EMAIL.LABEL': 'E-mail',
  'SUPPORT.TITLE': 'HƯỚNG DẪN & HỖ TRỢ',
  'CONTACT.HOME.COMPLETE_SUBMISSION.MESSAGE':
    'Cám ơn bạn đã để lại lời nhắn cho chúng tôi',
  'CONTACT.HOME.COMPLETE_SUBMISSION.BUTTON_TEXT': 'Hoàn tất',
  'SUPPORT.DESCRIPTION':
    'Nếu có bất kỳ thắc mắc hoặc cần hỗ trợ hãy liên hệ với chúng tôi theo đường link: <a href="http://letsparkit.com/lienhe" target="_blank">http://letsparkit.com/lienhe</a> để được trợ giúp',
  'FAQ.TITLE': 'CÂU HỎI THƯỜNG GẶP',
  'FAQ.LEFT_MENU.LABEL': 'Các bài viết trong mục này',
  'FAQ.USEFUL.HAVE_QUESTION':
    'Có thêm câu hỏi? <a href="/contact">Gửi yêu cầu</a>',
  'FAQ.RECENT_POSTS.TITLE': 'Bài viết được xem gần đây',
  'FAQ.RELATED_POSTS.TITLE': 'Bài viết liên quan',
  'APP.FOOTER.WHAT_LETSPARKIT': 'Let’s Park It là gì?',
  'APP.FOOTER.BENEFITS': 'Lợi ích khi tham gia?',
  'APP.FOOTER.ARE_YOU_READY': 'Bạn đã sẵn sàng?',
  'APP.FOOTER.NEWS': 'Tin tức',
  'APP.FOOTER.QA': 'Câu hỏi thường gặp',
  'APP.FOOTER.TUTORIAL_AND_SUPPORT': 'Hướng dẫn và hỗ trợ',
  'APP.FOOTER.FORM.PLACEHOLDER': 'Nhập e-mail và nhận thông tin mới',
  'API.REQUEST.SUCCESS': 'Thành công',
  'API.REQUEST.FAILED': 'Đã có lỗi xảy ra.',

  'FORM.ERROR.NO_VALUES': 'Vui lòng nhập thông tin',
  'FORM.ERROR.EMAIL.REQUIRED': 'Vui lòng điền email',
  'FORM.ERROR.EMAIL.INVALID': 'Email không đúng',
  'FORM.VALIDATION.FAILED': 'Thông tin không hợp lệ',
  'FORM.ERROR.ADDRESS_REQUIRED': 'Vui lòng chọn địa điểm',
  'FORM.ERROR.CAPACITY.REQUIRED': 'Vui lòng điền sức chứa của bãi đỗ',
  'FORM.ERROR.CAPACITY.MUST_BE_POSITIVE_INTEGER':
    'Sức chứa của bãi đỗ phải là số nguyên dương',
  'FORM.ERROR.BLANK.REQUIRED': 'Vui lòng điền số chỗ trống của bãi',
  'FORM.ERROR.BLANK.MUST_BE_POSITIVE_INTEGER':
    'Chỗ trống phải là số nguyên dương',
  'FORM.ERROR.WIDTH.REQUIRED': 'Vui lòng điền chiều rộng của bãi',
  'FORM.ERROR.WIDTH.MUST_BE_POSITIVE_NUMBER':
    'Chiều rộng của bãi phải là số dương',
  'FORM.ERROR.HEIGHT.REQUIRED': 'Vui lòng điền chiều dài của bãi',
  'FORM.ERROR.HEIGHT.MUST_BE_POSITIVE_NUMBER':
    'Chiều dài của bãi phải là số dương',
  'FORM.ERROR.WEIGHT.REQUIRED': 'Vui lòng điền tải trọng của bãi',
  'FORM.ERROR.WEIGHT.MUST_BE_POSITIVE_NUMBER':
    'Tải trọng bãi xe phải là số dương',
  'FORM.ERROR.FROM_DATE.REQUIRED':
    'Vui lòng cho biết ngày có thể bắt đầu dùng bãi',
  'FORM.ERROR.FROM_DATE.MUST_LARGER_THAN_TO_DATE':
    'Ngày bắt đầu phải nhỏ hơn ngày kết thúc',
  'FORM.ERROR.TO_DATE.REQUIRED':
    'Vui lòng cho biết ngày kết thúc lịch trống của bãi xe',

  'FORM.ERROR.CONTACT_FORM.NAME.REQUIRED': 'Vui lòng điền tên',
  'FORM.ERROR.CONTACT_FORM.EMAIL.REQUIRED': 'Vui lòng điền email',
  'FORM.ERROR.CONTACT_FORM.EMAIL.INVALID': 'Email không đúng',
  'FORM.ERROR.CONTACT_FORM.PHONE_NUMBER.REQUIRED':
    'Vui lòng điền số điện thoại',
  'FORM.ERROR.CONTACT_FORM.PHONE_NUMBER.INVALID': 'Số điện thoại không hợp lệ',
  'FORM.ERROR.CONTACT_FORM.MESSAGE.REQUIRED': 'Vui lòng nhập nội dung',

  'ZALO.MESSAGE.WELCOME_TEXT': 'Chúng tôi rất vui được hỗ trợ bạn!',
};
