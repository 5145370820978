import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { useLocation } from 'react-router';
import logo from '../../assets/images/logo.svg';
import {
  useOnClickOutside,
  scrollToElement,
  useEventListener,
} from '../../utils';
import {
  getElementOffsetTop,
  scrollToPosition,
} from '../../utils/scrollToElement';
import Translate from '../Translate';
import history from '../../history';
import { THEME } from '../../constants';
import LocaleSwitcher from './LocaleSwitcher';

import styles from './navigationBar.styles';
import { LETS_PARK_IT_NEWS_PAGE_URL } from '../../configs';

const leftItems = [
  {
    label: 'APP.NAVBAR.WHAT_LETSPARKIT',
    id: 'introduce',
    toId: 'introduce',
  },
  {
    label: 'APP.NAVBAR.BENEFITS',
    id: 'benefits',
    toId: 'benefits',
  },
  {
    label: 'APP.NAVBAR.ARE_YOU_READY',
    id: 'map',
    toId: 'map',
  },
];

const rightItems = [
  { label: 'APP.NAVBAR.NEWS', id: 'news', href: LETS_PARK_IT_NEWS_PAGE_URL },
  { label: 'APP.NAVBAR.QA', href: '/faq', id: 'faq' },
  {
    label: 'APP.NAVBAR.TUTORIAL_AND_SUPPORT',
    href: '/support',
    id: 'support',
  },
  { label: 'APP.NAVBAR.CONTACT', href: '/contact', id: 'contact' },
];

const screenType = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop',
};

const NavigationBar = ({ classes, activeLocale, localeChanged }) => {
  const [navItemActive, setNavItemActive] = useState('home');
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [screen, setScreen] = useState(screenType.desktop);
  const menuRef = useRef();
  const location = useLocation();

  useEffect(() => {
    const navbarItem = rightItems.find(
      item => item.href && window.location.pathname.includes(item.href),
    );

    if (navbarItem) {
      setNavItemActive(navbarItem.id);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      return;
    }

    const matchedItem = rightItems.find(
      item => item.href && item.href.startsWith(location.pathname),
    );
    if (matchedItem) {
      setNavItemActive(matchedItem.id);
    }
  }, [location]);

  useOnClickOutside(menuRef, () => setOpenMenu(false));
  useEventListener(
    'scroll',
    () => {
      if (window.location.pathname !== '/') return;

      leftItems.forEach(item => {
        if (getElementOffsetTop(item.id) < window.pageYOffset + 200) {
          setNavItemActive(item.id);
        }
      });
    },
    true,
  );

  useEventListener(
    'resize',
    () => {
      if (window.innerWidth <= THEME.breakpoints.maxMd) {
        setScreen(screenType.mobile);
      } else if (window.innerWidth <= THEME.breakpoints.maxXl) {
        setScreen(screenType.tablet);
      } else {
        setScreen(screenType.desktop);
      }
    },
    true,
  );

  const onLeftItemClick = item => {
    let delay = 0;
    if (window.location.pathname !== '/') {
      history.push('/');
      delay = 300;
    }
    setNavItemActive(item.id);
    setOpenMenu(false);
    if (item.toId) {
      setTimeout(() => scrollToElement(item.toId), delay);
    }
  };

  const onRightItemClick = item => {
    if (item.href) {
      setNavItemActive(item.id);
      setOpenMenu(false);
      scrollToPosition(0);
      if (/http(s|):\/\//.test(item.href)) {
        window.location.href = item.href;
      } else {
        history.push(item.href);
      }
    }
  };

  const onLogoClick = () => {
    if (window.location.pathname !== '/') {
      scrollToPosition(0);
      history.push('/');
      setNavItemActive('introduce');
    } else {
      scrollToPosition(0);
    }
  };

  const renderItem = (key, item, onClick) => (
    <span
      key={key}
      role="presentation"
      onClick={() => onClick(item)}
      className={classNames(
        classes.item,
        navItemActive === item.id ? 'active' : '',
      )}
    >
      <Translate id={item.label} />
    </span>
  );

  const renderItems = (list, onClick, hasLocaleButton) => (
    <>
      {list.map(i => renderItem(i.id, i, onClick))}
      {hasLocaleButton && (
        <LocaleSwitcher
          className={classes.item}
          activeLocale={activeLocale}
          localeChanged={locale => localeChanged(locale)}
        />
      )}
    </>
  );

  return (
    <nav className={classNames(classes.root)}>
      <span role="presentation" className={classes.brand} onClick={onLogoClick}>
        <img src={logo} alt="Logo" />
      </span>
      <span
        role="presentation"
        className={classes.hambergerBtn}
        onClick={() => setOpenMenu(!isOpenMenu)}
        style={{ pointerEvents: isOpenMenu ? 'none' : 'auto' }}
      >
        <i className="icon-hamberger-menu" />
      </span>
      <div className={classNames(classes.navigationItems)}>
        <div className={classes.leftNavbar}>
          {renderItems(
            leftItems,
            onLeftItemClick,
            screen === screenType.tablet,
          )}
        </div>
        <div className={classes.rightNavbar}>
          {renderItems(
            rightItems,
            onRightItemClick,
            screen === screenType.desktop,
          )}
        </div>
      </div>
      <div
        ref={menuRef}
        className={classNames(classes.dropdownMenu, isOpenMenu ? 'show' : '')}
      >
        <div
          className={classNames(
            classes.dropdownItemsWrapper,
            screen !== screenType.mobile && 'hide',
          )}
        >
          {renderItems(leftItems, onLeftItemClick)}
        </div>
        <div className={classes.dropdownItemsWrapper}>
          {renderItems(rightItems, onRightItemClick)}
        </div>
        {screen === screenType.mobile && (
          <LocaleSwitcher
            className={classes.localeSwitcherCustom}
            activeLocale={activeLocale}
            localeChanged={locale => localeChanged(locale)}
          />
        )}
      </div>
    </nav>
  );
};

NavigationBar.propTypes = {
  classes: PropTypes.object.isRequired,
  activeLocale: PropTypes.string.isRequired,
  localeChanged: PropTypes.func.isRequired,
};
export default injectSheet(styles)(NavigationBar);
