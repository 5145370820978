import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Map, TileLayer } from 'react-leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import MarkerCluster from './MarkerCluster';
import markerIcon from './markerIcon';
import showResult from './utils';

const mapSettings = {
  zoom: 13,
  id: 'leaplet-map',
  zoomControl: false,
  style: { minHeight: 'inherit' },
  center: [10.7850378, 106.7067704],
  maxZoom: 16,
  scrollWheelZoom: false,
  gestureHandling: true,
};

const tileLayerSettings = {
  attribution:
    '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors', // eslint-disable-line
  url:
    'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}', // eslint-disable-line
};

const LeafletMap = ({ markers, markerInfo, onMarkerDragEnd }) => {
  const leafletRef = useRef();

  useEffect(() => {
    const map = leafletRef.current.leafletElement;
    map.addHandler('gestureHandling', GestureHandling);
  }, []);

  useEffect(() => {
    if (!markerInfo) {
      return;
    }

    const map = leafletRef.current.leafletElement;
    showResult(map, markerInfo, markerInfo.address, onMarkerDragEnd);
  }, [markerInfo]);

  return (
    <Map ref={leafletRef} {...mapSettings}>
      <TileLayer {...tileLayerSettings} />
      <MarkerCluster
        markers={markers}
        popupContentKey="address"
        icon={markerIcon}
      />
    </Map>
  );
};
LeafletMap.defaultProps = {
  markerInfo: null,
  onMarkerDragEnd: () => {},
  markers: [],
};
LeafletMap.propTypes = {
  markerInfo: PropTypes.object,
  onMarkerDragEnd: PropTypes.func,
  markers: PropTypes.array,
};

export default LeafletMap;
