import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

import styles from './imageControlButton.styles';

const ImageControlButton = ({ className, classes, icon, onClick, base64 }) => (
  <div className={classes.root}>
    <div className={classes.overlay}>
      <button
        className={classNames(classes.controlBtn, 'naked-button', className)}
        onClick={onClick}
        style={{ backgroundImage: `url(${base64})` }}
      >
        {icon}
      </button>
    </div>
  </div>
);

ImageControlButton.defaultProps = {
  className: '',
  icon: null,
  base64: '',
  onClick: () => {},
};

ImageControlButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  base64: PropTypes.string,
};

export default injectSheet(styles)(ImageControlButton);
