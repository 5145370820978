/* eslint-disable max-len */
/* eslint-disable quotes */
// export const HOST=`https://us-central1-letsparkit-c50c2.cloudfunctions.net`;
export const HOST = `https://letspark.it/api`;
export const FETCHING_TIMEOUT = 10000;

export const LETS_PARK_IT_FACEBOOK_URL_TITLE = `Let's Facebook`;

export const LETS_PARK_IT_FACEBOOK_URL = `https://www.facebook.com/letsparkitnow`;
export const LETS_PARK_IT_YOUTUBE_URL_TITLE = `Let's Youtube`;
export const LETS_PARK_IT_YOUTUBE_URL = `https://www.youtube.com/channel/UCDckDwnffHOJXTI2u7mT_Sw`;
export const LETS_PARK_IT_ZALO_URL_TITLE = `Let's Zalo`;
export const LETS_PARK_IT_ZALO_URL = `https://zalo.me/2084230725904720930`;
export const LETS_PARK_IT_PHONE_NUMBER_TITLE = `Let's Talk`;
export const LETS_PARK_IT_PHONE_NUMBER = `+84284848484`;
export const LETS_PARK_IT_EMAIL_ADDRESS_TITLE = `Let's Email`;
export const LETS_PARK_IT_EMAIL_ADDRESS = `contact@letspark.it`;
export const APP_STORE_URL = ``;
export const GOOGLE_PLAY_URL = ``;

export const DEFAULT_LOCALE = `en_US`;
// TODO: check that put the following variable is correct.
export const MAX_NUM_RECENT_VIEWED_ARTICLES = 5;

// Log rocket
export const LOG_ROCKET_ID = `darrkj`;
export const LOG_ROCKET_APP = `letsparkit`;

// Google Analytics
export const GA_MEASUREMENT_ID = `G-CNV19H572D`;
export const ZALO_OA_ID = `2084230725904720930`;
export const ZALO_AUTO_POPUP = 10;
export const LETS_PARK_IT_NEWS_PAGE_URL = 'https://news.letspark.it/';

export const LETS_PARK_IT_FAQ_CATEGORY_SLUG = 'faq';
