import { fork } from 'redux-saga/effects';

import categoriesSaga from './categories.saga';
import localeSaga from './locale.saga';
import faqSaga from '../containers/FAQ/faq.saga';
import homeSaga from '../containers/Home/home.saga';
import contactSaga from '../containers/Contact/contact.saga';
import footerSaga from '../containers/Footer/footer.saga';

export default function* root() {
  yield fork(categoriesSaga);
  yield fork(localeSaga);
  yield fork(faqSaga);
  yield fork(homeSaga);
  yield fork(contactSaga);
  yield fork(footerSaga);
}
