export default {
  root: {
    height: 0,
    width: '100%',
    paddingTop: '100%',
    position: 'relative',
  },
  image: {
    top: '50%',
    left: '50%',
    width: '100%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  clock: {
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    '& .react-clock__face': { display: 'none' },
    '& .react-clock__hand__body': { backgroundColor: '#979ea4' },
    '& .react-clock__hour-hand__body, & .react-clock__minute-hand__body': {
      bottom: '50% !important',
    },
    '&:before': {
      width: 10,
      height: 10,
      top: '50%',
      left: '50%',
      content: '""',
      borderRadius: '50%',
      position: 'absolute',
      backgroundColor: '#979ea4',
      transform: 'translate(-50%, -50%)',
    },
  },
};
