import { takeLatest, put, all } from 'redux-saga/effects';

import { DEFAULT_LOCALE } from '../configs';
import {
  changeLocaleFailed,
  changeLocaleSuccess,
  LOCALE_CHANGE,
  LOCALE_INITIALIZE,
} from '../ducks/locale.duck';
import { STORAGES } from '../constants';
import messages from '../intl';

const browserLanguageToLocale = Object.freeze({
  en: 'en_US',
  'en-us': 'en_US',

  vi: 'vi_VN',
  'vi-vn': 'vi_VN',
});

function* initializeLocaleFlow() {
  yield takeLatest(LOCALE_INITIALIZE, function* onInitializeLocale() {
    try {
      const rememberedLocale = localStorage.getItem(STORAGES.locale);
      const browserLocale =
        browserLanguageToLocale[navigator.language.toLowerCase()];
      const isLocaleSupported =
        rememberedLocale && !!messages[rememberedLocale];

      let locale = '';

      if (isLocaleSupported) {
        locale = rememberedLocale;
      } else if (browserLocale) {
        locale = browserLocale;
      } else if (DEFAULT_LOCALE) {
        // eslint-disable-next-line no-console
        console.warn(`Default locale '${DEFAULT_LOCALE}' will be used`);
        locale = DEFAULT_LOCALE;
      } else {
        // eslint-disable-next-line no-console
        console.warn('English will be used');
        locale = 'en_US';
      }

      localStorage.setItem(STORAGES.locale, locale);
      yield put(changeLocaleSuccess(locale));
    } catch (error) {
      yield put(changeLocaleFailed({ error }));
    }
  });
}

function* changeLocaleFlow() {
  yield takeLatest(LOCALE_CHANGE, function* onChangeLocale({ payload }) {
    try {
      const locale = messages[payload] ? payload : DEFAULT_LOCALE;

      if (!messages[payload]) {
        // eslint-disable-next-line no-console
        console.warn(`default locale '${DEFAULT_LOCALE}' will be used`);
      }

      localStorage.setItem(STORAGES.locale, locale);
      yield put(changeLocaleSuccess(locale));
    } catch (error) {
      yield put(changeLocaleFailed({ error }));
    }
  });
}

export default function* localeFlow() {
  yield all([initializeLocaleFlow(), changeLocaleFlow()]);
}
