import { THEME } from '../../constants';

export default {
  root: {
    top: 30,
    height: 70,
    zIndex: 1001,
    width: '100%',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    padding: [0, THEME.paddingSide],
  },
  brand: {
    minWidth: 205,
    maxWidth: 205,
    height: 48,
    display: 'block',
    cursor: 'pointer',
    overflow: 'hidden',
    '& > img': { width: '100%' },
  },
  dropdownMenu: {
    display: 'none',
  },
  navigationItems: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftNavbar: { display: 'flex', alignItems: 'center' },
  rightNavbar: { extend: 'leftNavbar' },
  item: {
    fontSize: 12,
    color: '#16212C',
    cursor: 'pointer',
    lineHeight: '16px',
    whiteSpace: 'nowrap',
    '&.active': {
      textShadow: '1px 0 0 currentColor',
      color: THEME.colors.primary,
    },
    '&:hover': { color: THEME.colors.primary },
  },
  hambergerBtn: {
    top: '50%',
    fontSize: 25,
    display: 'none',
    cursor: 'pointer',
    position: 'absolute',
    alignItems: 'center',
    transform: 'translateY(-50%)',
    right: THEME.paddingSideMobile,
    '& [class^=icon-]': { color: '#21303e' },
  },
  dropdownItemsWrapper: {
    margin: 0,
    maxWidth: '100%',
    display: 'inline-flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    '&.hide': {
      display: 'none',
    },
  },
  localeSwitcherCustom: { textAlign: 'right' },

  [`@media (min-width: ${THEME.breakpoints.minXl}px)`]: {
    brand: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    leftNavbar: { '& $item + $item': { marginLeft: 35 } },
    rightNavbar: { '& $item + $item': { marginLeft: 35 } },
  },
  [`@media (max-width: ${THEME.breakpoints.maxXl}px)`]: {
    rightNavbar: { display: 'none' },
    navigationItems: { justifyContent: 'flex-end' },
    leftNavbar: {
      width: '100%',
      maxWidth: 420,
      marginLeft: 15,
      justifyContent: 'space-between',
    },
    hambergerBtn: { display: 'flex' },

    dropdownMenu: {
      justifyContent: 'space-between',
      width: 'auto',
      position: 'absolute',

      '&.show': {
        top: 50,
        minWidth: 220,
        padding: [25, 25, 20],
        display: 'inline-flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        right: THEME.paddingSideMobile,
        boxShadow: '0px 5px 10px 1px #8c8c8c',
        '& $item': {
          fontSize: 14,
          display: 'block',
          marginBottom: 10,
        },
      },
    },
  },
  [`@media (max-width: ${THEME.breakpoints.maxLg}px) 
    and (min-width: ${THEME.breakpoints.minSm}px)`]: {
    root: { top: 60 },
  },
  [`@media (max-width: ${THEME.breakpoints.maxMd}px)`]: {
    root: {
      height: 50,
      padding: [0, THEME.paddingSideMobile],
    },
    brand: {
      height: 'auto',
      maxWidth: 165,
      minWidth: 165,
    },
    hambergerBtn: { display: 'flex' },
    navigationItems: {
      display: 'none',
    },
    dropdownMenu: {
      '& $leftNavbar': {
        display: 'none',
      },
    },
    [`@media (max-width: ${THEME.breakpoints.maxSm}px)`]: {
      root: {
        top: 92,
      },
    },
  },
  [`@media (max-width: ${THEME.breakpoints.maxXXl}px) 
    and (min-width: ${THEME.breakpoints.minXl}px)`]: {
    leftNavbar: { '& $item + $item': { marginLeft: 15 } },
    rightNavbar: { '& $item + $item': { marginLeft: 15 } },
  },
};
